import Home from "../pages/Home/Home";
import AccountList from "./../pages/Account/AccountPage/AccountList";
import AccountAdd from "../pages/Account/AccountPage/AccountAdd";
import RoleList from "../pages/Role/RolePage/RoleList";
import LicenseKeyList from "./../pages/LicenseKey/LicenseKeyPage/LicenseKeyList";
import LicenseKeyCategoryList from "../pages/LicenseKeyCategory/LicenseKeyCategoryPage/LicenseKeyCategoryList";
import AccountEdit from "../pages/Account/AccountPage/AccountEdit";
import RoleAdd from "./../pages/Role/RolePage/RoleAdd";
import LicenseKeyCategoryAdd from "./../pages/LicenseKeyCategory/LicenseKeyCategoryPage/LicenseKeyCategoryAdd";
import RoleEdit from "./../pages/Role/RolePage/RoleEdit";
import LicenseKeyAdd from "./../pages/LicenseKey/LicenseKeyPage/LicenseKeyAdd";
import LicenseKeyEdit from "../pages/LicenseKey/LicenseKeyPage/LicenseKeyEdit";
import OrganizationList from "./../pages/Organization/OrganizationPage/OrganizationList";
import OrganizationAdd from "./../pages/Organization/OrganizationPage/OrganizationAdd";
import CustomerList from "./../pages/Customer/CustomerPage/CustomerList";
import ProductList from "./../pages/Product/ProductPage/ProductList";
import DepartmentList from "./../pages/Department/DepartmentPage/DepartmentList";
import DepartmentAdd from "./../pages/Department/DepartmentPage/DepartmentAdd";
import CustomerAdd from "./../pages/Customer/CustomerPage/CustomerAdd";
import ROUTER from "./routerLink";
import PriorityList from "./../pages/Priority/PriorityPage/PriorityList";
import OrganizationEdit from "./../pages/Organization/OrganizationPage/OrganizationEdit";
import DepartmentEdit from "./../pages/Department/DepartmentPage/DepartmentEdit";
import PriorityEdit from "./../pages/Priority/PriorityPage/PriorityEdit";
import PriorityAdd from "./../pages/Priority/PriorityPage/PriorityAdd";
import ProductAdd from "./../pages/Product/ProductPage/ProductAdd";
import ProductEdit from "./../pages/Product/ProductPage/ProductEdit";
import VersionList from "./../pages/Version/VersionPage/VersionList";
import VersionAdd from "./../pages/Version/VersionPage/VersionAdd";
import VersionEdit from "./../pages/Version/VersionPage/VersionEdit";
import CustomerEdit from "./../pages/Customer/CustomerPage/CustomerEdit";
import LicenseKeyCategoryEdit from "./../pages/LicenseKeyCategory/LicenseKeyCategoryPage/LicenseKeyCategoryEdit";
import ChangePassword from "./../pages/Auth/ChangePassword/ChangePassword";
import AuthDetail from "./../pages/Auth/AccountDetail/index";
import LicenseKeyInChartList from "./../pages/LicenseKeyInChart/LicenseKeyInChartPage/LicenseKeyInChartList";
import LicenseKeyCategoryChart from "../pages/LicenseKeyCategoryChart/LicenseKeyCategoryChart";
// import Organization from "../pages/Organization/OrganizationPage/Organization";
import LicenseKeyExpirationDate from "./../pages/LicenseKeyExpirationDate/LicenseKeyExpirationDate";
import LicenseKeySendMailExpirationDate from "../pages/LicenseKeySendMailExpirationDate/LicenseKeySendMailExpirationDate";
import LicenseKeySendMailIntro from "./../pages/LicenseKeySendMailIntro/LicenseKeySendMailIntroPage/LicenseKeySendMailIntro";
import LicenseKeySendMailIntroDetail from "./../pages/LicenseKeySendMailIntro/LicenseKeySendMailIntroPage/LicenseKeySendMailIntroDetail";
import EditionList from "./../pages/Edition/EditionPage/EditionList";
import EditionAdd from "../pages/Edition/EditionPage/EditionAdd";
import EditionEdit from "./../pages/Edition/EditionPage/EditionEdit";
import LicenseKeyHistorySendMailExpires from "../pages/LicenseKeyHistorySendMailExpires/LicenseKeyHistorySendMailExpiresPage/LicenseKeyHistorySendMailExpires";
import LicenseKeyHistorySendMailExpiresDetail from "./../pages/LicenseKeyHistorySendMailExpires/LicenseKeyHistorySendMailExpiresPage/LicenseKeyHistorySendMailExpiresDetail";
import GroupCustomer from "./../pages/GroupCustomer/GroupCustomerPage/GroupCustomer";
import MailActiveList from "../pages/MailActive/MailActiveList";

const routes = [
  {
    path: ROUTER.HOME.HOME,
    exact: true,
    component: Home,
    name: "Home",
  },
  {
    path: ROUTER.AUTH.CHANGEPASSWORD,
    name: "Change Password",
    component: ChangePassword,
  },
  {
    path: ROUTER.AUTH.AUTHDETAIL,
    name: "Update Account",
    component: AuthDetail,
  },
  {
    path: `${ROUTER.LICENSEKEYINCHART.LIST}/:id`,
    name: "List of expired product management",
    component: LicenseKeyInChartList,
  },
  {
    path: ROUTER.LICENSEKEY.LIST,
    exact: true,
    name: "Product Management",
    component: LicenseKeyList,
  },
  {
    path: ROUTER.LICENSEKEY.ADD,
    name: "Add new product management",
    component: LicenseKeyAdd,
  },
  {
    path: `${ROUTER.LICENSEKEY.EDIT}/:id`,
    name: "Update product management",
    component: LicenseKeyEdit,
  },
  {
    path: ROUTER.LICENSEKEY_CATEGORY.LIST,
    exact: true,
    name: "Manage License Catalog",
    component: LicenseKeyCategoryList,
  },
  {
    path: ROUTER.LICENSEKEY_CATEGORY.ADD,
    name: "Add category",
    component: LicenseKeyCategoryAdd,
  },
  {
    path: `${ROUTER.LICENSEKEY_CATEGORY.EDIT}/:id`,
    name: "Update category",
    component: LicenseKeyCategoryEdit,
  },

  {
    path: ROUTER.ROLE.LIST,
    exact: true,
    name: "Management role",
    component: RoleList,
  },
  { path: ROUTER.ROLE.ADD, name: "Add new role", component: RoleAdd },
  {
    path: `${ROUTER.ROLE.EDIT}/:id`,
    name: "Add new role",
    component: RoleEdit,
  },
  {
    path: ROUTER.CUSTOMER.LIST,
    exact: true,
    name: "Customer management",
    component: CustomerList,
  },
  {
    path: ROUTER.CUSTOMER.ADD,
    name: "Add new customer",
    component: CustomerAdd,
  },
  {
    path: `${ROUTER.CUSTOMER.EDIT}/:id`,
    name: "Customer Update",
    component: CustomerEdit,
  },
  // {
  //   path: ROUTER.ORGANIZATION.LIST,
  //   exact: true,
  //   name: "Quản lý Organization",
  //   component: Organization,
  // },
  {
    path: ROUTER.ORGANIZATION.LIST,
    exact: true,
    name: "Organization management",
    component: OrganizationList,
  },
  {
    path: ROUTER.ORGANIZATION.ADD,
    name: "Add new organization",
    component: OrganizationAdd,
  },
  {
    path: `${ROUTER.ORGANIZATION.EDIT}/:id`,
    name: "Organization update",
    component: OrganizationEdit,
  },
  {
    path: ROUTER.DEPARTMENT.LIST,
    exact: true,
    name: "Department manager",
    component: DepartmentList,
  },
  {
    path: ROUTER.DEPARTMENT.ADD,
    name: "Add new departments",
    component: DepartmentAdd,
  },
  {
    path: `${ROUTER.DEPARTMENT.EDIT}/:id`,
    name: "Update department",
    component: DepartmentEdit,
  },
  {
    path: ROUTER.PRIORITY.LIST,
    exact: true,
    name: "Priority management",
    component: PriorityList,
  },
  {
    path: ROUTER.PRIORITY.ADD,
    name: "Add new priority",
    component: PriorityAdd,
  },
  {
    path: `${ROUTER.PRIORITY.EDIT}/:id`,
    name: "Priority update",
    component: PriorityEdit,
  },
  {
    path: ROUTER.PRODUCT.LIST,
    exact: true,
    name: "Product Management",
    component: ProductList,
  },
  {
    path: ROUTER.PRODUCT.ADD,
    name: "Add new products",
    component: ProductAdd,
  },
  {
    path: `${ROUTER.PRODUCT.EDIT}/:id`,
    name: "Product Update",
    component: ProductEdit,
  },

  {
    path: `${ROUTER.PRODUCT.EDITION.LIST}/:idproduct`,
    exact: true,
    name: "Management edition",
    component: EditionList,
  },
  {
    path: `${ROUTER.PRODUCT.EDITION.LIST}/:idproduct/add`,
    name: "Add new edition",
    component: EditionAdd,
  },
  {
    path: `${ROUTER.PRODUCT.EDITION.LIST}/:idproduct/edit/:id`,
    name: "Update edition",
    component: EditionEdit,
  },

  {
    path: `${ROUTER.PRODUCT.EDITION.VERSION.LIST}/:idEdition`,
    exact: true,
    name: "Version Management",
    component: VersionList,
  },
  {
    path: `${ROUTER.PRODUCT.EDITION.VERSION.LIST}/:idEdition/add`,
    name: "Add new version",
    component: VersionAdd,
  },
  {
    path: `${ROUTER.PRODUCT.EDITION.VERSION.LIST}/:idEdition/edit/:id`,
    name: "Update version",
    component: VersionEdit,
  },

  {
    path: ROUTER.ACCOUNT.LIST,
    exact: true,
    name: "Account Management",
    component: AccountList,
  },
  {
    path: ROUTER.ACCOUNT.ADD,
    name: "Add new account",
    component: AccountAdd,
  },
  {
    path: `${ROUTER.ACCOUNT.EDIT}/:id`,
    name: "Update account",
    component: AccountEdit,
  },
  {
    path: `${ROUTER.LICENSEKEY_CATEGORY_CHART.LIST}/:id`,
    exact: true,
    name: "List of LicenseKey categories",
    component: LicenseKeyCategoryChart,
  },
  {
    path: ROUTER.LICENSEKEY_EXPIRATION_DATE.LIST,
    exact: true,
    name: "LicenseKey is about to expire",
    component: LicenseKeyExpirationDate,
  },
  {
    path: `${ROUTER.LICENSEKEY_SEND_MAIL_EXPIRATIONDATE.LICENSEKEY_SEND_MAIL_EXPIRATIONDATE}/:id`,
    exact: true,
    name: "Email expired licensekey",
    component: LicenseKeySendMailExpirationDate,
  },
  // {
  //   path: ROUTER.LICENSEKEY_SEND_MAIL_INTRO.LICENSEKEY_SEND_MAIL_INTRO,
  //   exact: true,
  //   name: "Gửi email giới thiệu",
  //   component: LicenseKeySendMailIntro,
  // },
  {
    path: `${ROUTER.LICENSEKEY_SEND_MAIL_INTRO.LICENSEKEY_SEND_MAIL_INTRO}/:id`,
    exact: true,
    name: "Email details",
    component: LicenseKeySendMailIntroDetail,
  },
  {
    path: ROUTER.LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES
      .LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES,
    exact: true,
    name: "Product management email sending history has expired ",
    component: LicenseKeyHistorySendMailExpires,
  },
  {
    path: ROUTER.MAILACTIVE.LIST,
    exact: true,
    name: "Account Activation List",
    component: MailActiveList,
  },
  {
    path: ROUTER.LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES
      .LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES,
    exact: true,
    name: "Product management email sending history has expired",
    component: LicenseKeyHistorySendMailExpires,
  },
  {
    path: `${ROUTER.LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES.LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES}/:id`,
    exact: true,
    name: "Expiration notification email details",
    component: LicenseKeyHistorySendMailExpiresDetail,
  },
  {
    path: ROUTER.GROUPCUSTOMER.LIST,
    exact: true,
    name: "Group of customers sending Email",
    component: GroupCustomer,
  },
];

export default routes;
