import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import store from "./store/index";
import { Provider } from "react-redux";
import { icons } from "./assets/icons/index";
import "@fortawesome/fontawesome-free/css/all.css";
import "antd/dist/antd.css";
import "./assets/css/tailwind.css";
import "./assets/css/custom-antd.css";
import * as serviceWorker from "./serviceWorker";
import vi_VN from 'antd/es/locale/vi_VN'
import { ConfigProvider } from "antd";
import { I18nextProvider } from 'react-i18next'
import { vi } from "./assets/locales/vi";
import i18nConfig from './Utils/i18n-config'
React.icons = icons;
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      {/* <Suspense fallback={loading}> */}
      {/* <ConfigProvider locale={vi_VN}> */}
        {/* <I18nextProvider i18n={i18nConfig}> */}
          <App />
        {/* </I18nextProvider> */}
      {/* </ConfigProvider> */}
      {/* </Suspense> */}
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
serviceWorker.unregister();
