import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, NavLink, useLocation } from "react-router-dom";
import {
  deleteAccount,
  getAccountByIdLogin,
  updateAccount,
} from "./../AccountSlice";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import InputSearch from "../../../components/InputSearch/InputSearch";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification, Switch, Tooltip, Popconfirm, Spin, Form } from "antd";
import ROUTER from "../../../routers/routerLink";
import { debounce } from "lodash";
import avtUser from "../../../assets/image/user.png";
import { API_URL } from "../../../Utils/Config";
import queryString from "query-string";

const AccountList = (props) => {
  const [valueSearch, setValueSearch] = useState("");
  const [form] = Form.useForm();
  const search = useLocation().search;
  const dispatch = useDispatch();
  const history = useHistory();
  const listAccount = useSelector((state) => state?.account?.items);
  const isLoading = useSelector((state) => state?.account?.isLoading);
  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );
  const listDirector = listAccount?.filter(
    (item) => item?.roleCode === "director" && item?.email !== "admin@gmail.com"
  );
  const listManager = listAccount?.filter(
    (item) => item?.roleCode === "manager"
  );
  const listDeputy = listAccount?.filter((item) => item?.roleCode === "deputy");
  const listEmployee = listAccount?.filter(
    (item) => item?.roleCode === "employee"
  );

  useEffect(() => {
    if (search) {
      setValueSearch(queryString.parse(search)["keyword"]);
    } else {
      setValueSearch("");
    }
  }, [search]);

  useEffect(() => {
    const { search } = props.history.location;
    let params;
    const filterOptions = search.split("?")[1];
    if (filterOptions !== undefined) {
      params = `{"${decodeURI(filterOptions)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`;
      dispatch(
        getAccountByIdLogin({
          id: accountDetail?.id,
          params: JSON.parse(params),
        })
      );
    } else {
      dispatch(getAccountByIdLogin({ id: accountDetail?.id, params: null }));
    }
  }, [dispatch, props.history.location, accountDetail]);

  const onChangeActive = async (value) => {
    const id = value?.id;
    const data = {
      email: value?.email,
      password: "",
      name: value?.name,
      phone: value?.phone,
      roleId: value?.roleId,
      active: value?.active === true ? false : true,
      avatar: value.avatar,
      updatedBy: accountDetail?.id,
    };
    try {
      const resulDispatch = await dispatch(updateAccount({ id, data }));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Status change successful!!!` });
        history.push(ROUTER.ACCOUNT.LIST);
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };

  const onDelete = async (id) => {
    try {
      const resultAction = await dispatch(deleteAccount(id));
      unwrapResult(resultAction);
      notification.success({ message: `Delete successfully!` });
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };
  const fecthSearch = (value) => {
    if (value) {
      props.history.push(ROUTER.ACCOUNT.LIST.concat(`?keyword=${value}`));
    } else {
      props.history.push(ROUTER.ACCOUNT.LIST);
      dispatch(getAccountByIdLogin({ id: accountDetail?.id, params: null }));
    }
  };
  const debounceSearch = useCallback(debounce(fecthSearch, 1000), []);
  const onSearch = (e) => {
    setValueSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
    debounceSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
  };
  return (
    <>
      <Spin spinning={isLoading}>
        <div className="overflow-x-auto mb-10 ">
          <div className=" mb-3 bg-[#262626] px-4 py-8 rounded-[10px]">
            <div className="mx-auto w-[95%] md:flex md:justify-between">
              <div>
                <NavLink to={ROUTER.ACCOUNT.ADD}>
                  <ButtonSubmit
                    iconButton={<i className="fas fa-plus pr-[15px]"></i>}
                    titleButton="Add new"
                  />
                </NavLink>
              </div>
              <Form form={form}>
                <div className="md:flex md:justify-end">
                  <Form.Item name="searchName">
                    <InputSearch
                      placeholder="Search by email..."
                      onChange={onSearch}
                      valueSearch={valueSearch}
                    />
                  </Form.Item>
                </div>
              </Form>
            </div>

            {listAccount.length === 0 && (
              <div className="flex justify-center h-[200px]">
                <div className=" flex flex-col text-center text-white justify-center ">
                  <i className="fas fa-exclamation-circle fa-2x"></i>
                  <span className="text-xl mt-2"> No data</span>
                </div>
              </div>
            )}
            {listDirector?.length > 0 ? (
              <div className="border-[1px] border-gray-600 rounded-lg mb-4 mx-auto w-[95%]">
                <h1 className="text-lg text-white ml-3 px-3 mb-[30px] font-normal border-l-[6px] border-blue-300 mt-4">
                  Leadership
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 overflow-y-auto max-h-[550px] mb-4 px-4">
                  {listDirector?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="shadow-lg border-b border-r inline-block text-white overflow-x-auto"
                      >
                        <div className="bg-blue-300  border border-[#eee] flex justify-between">
                          <h5 className="m-0 p-2  text-center capitalize">
                            {item?.name}
                          </h5>
                          <div className="mr-2 pt-1">
                            <Link to={`${ROUTER.ACCOUNT.EDIT}/${item.id}`}>
                              <Tooltip title="Edit">
                                <i className="fas fa-edit opacity-75  text-gray-600  rounded-full pr-2 cursor-pointer"></i>
                              </Tooltip>
                            </Link>
                            <Popconfirm
                              okText="Đồng ý"
                              cancelText="Hủy"
                              title="Are you sure you want to delete?"
                              onConfirm={() => onDelete(item?.id)}
                            >
                              <Tooltip title="Delete">
                                <i className="fas fa-times opacity-75 text-gray-600   rounded-full cursor-pointer"></i>
                              </Tooltip>
                            </Popconfirm>
                          </div>
                        </div>
                        <div className="flex">
                          <img
                            alt=""
                            src={
                              item?.avatar
                                ? `${API_URL}/${item?.avatar}`
                                : avtUser
                            }
                            style={{
                              height: 100,
                              width: 100,
                              objectFit: "cover",
                            }}
                          />
                          <div className="overflow-x-auto ">
                            <div>
                              <span className="p-2 text-sm whitespace-nowrap">
                                Email: {item?.email}
                              </span>
                            </div>
                            <div className="pt-2 text-sm">
                              <span className="p-2 whitespace-nowrap">
                                {" "}
                                phone {item?.phone || "Chưa có"}
                              </span>
                            </div>
                            <div className="mt-2 px-2">
                              active
                              <Switch
                                className="ml-2"
                                {...(item?.active === true
                                  ? { checked: true }
                                  : { checked: false })}
                                onChange={() => onChangeActive(item)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
            {listManager && listManager.length > 0 ? (
              <div className="border-[1px] border-gray-600 rounded-lg mb-4 mx-auto w-[95%]">
                <h1 className="text-lg text-white ml-3 px-3 mb-[30px] font-normal border-l-[6px] border-green-300 mt-4">
                 Manager
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 overflow-y-auto max-h-[550px] mb-4 px-4">
                  {listManager?.map((item, index) => {
                    return (
                        <div
                          key={index}
                          className="shadow-lg border-b border-r inline-block text-white overflow-x-auto"
                        >
                          <div className="bg-green-300  border border-[#eee] flex justify-between">
                            <h5 className="m-0 p-2  text-center capitalize">
                              {item?.name}
                            </h5>
                            <div className="mr-2 pt-1">
                              <Link to={`${ROUTER.ACCOUNT.EDIT}/${item.id}`}>
                                <Tooltip title="Edit">
                                  <i className="fas fa-edit opacity-75  text-gray-600  rounded-full pr-2 cursor-pointer"></i>
                                </Tooltip>
                              </Link>
                              <Popconfirm
                                okText="Đồng ý"
                                cancelText="Hủy"
                                title="Are you sure you want to delete?"
                                onConfirm={() => onDelete(item?.id)}
                              >
                                <Tooltip title="Delete">
                                  <i className="fas fa-times opacity-75 text-gray-600  rounded-full cursor-pointer"></i>
                                </Tooltip>
                              </Popconfirm>
                            </div>
                          </div>
                          <div className="flex">
                            <img
                              alt=""
                              src={
                                item?.avatar
                                  ? `${API_URL}/${item?.avatar}`
                                  : avtUser
                              }
                              style={{
                                height: 100,
                                width: 100,
                                objectFit: "cover",
                              }}
                            />
                            <div className="overflow-x-auto ">
                              <div>
                                <span className="p-2 text-sm whitespace-nowrap">
                                  Email: {item?.email}
                                </span>
                              </div>
                              <div className="pt-2 text-sm">
                                <span className="p-2 whitespace-nowrap">
                                  {" "}
                                  phone {item?.phone || "Chưa có"}
                                </span>
                              </div>
                              <div className="mt-2 px-2">
                                active
                                <Switch
                                  className="ml-2"
                                  {...(item?.active === true
                                    ? { checked: true }
                                    : { checked: false })}
                                  onChange={() => onChangeActive(item)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
            {listDeputy && listDeputy.length > 0 ? (
              <div className="border-[1px] border-gray-600 rounded-lg mb-4 mx-auto w-[95%]">
                <h1 className="text-lg text-white ml-3 px-3 mb-[30px] font-normal border-l-[6px] border-yellow-300 mt-4">
                  Sub management
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 overflow-y-auto max-h-[550px] mb-4 px-4">
                  {listDeputy?.map((item, index) => {
                    return (
                        <div
                          key={index}
                          className="shadow-lg border-b border-r inline-block text-white overflow-x-auto"
                        >
                          <div className="bg-yellow-300  border border-[#eee] flex justify-between">
                            <h5 className="m-0 p-2  text-center capitalize">
                              {item?.name}
                            </h5>
                            <div className="mr-2 pt-1">
                              <Link to={`${ROUTER.ACCOUNT.EDIT}/${item.id}`}>
                                <Tooltip title="Edit">
                                  <i className="fas fa-edit opacity-75  text-gray-600  rounded-full pr-2 cursor-pointer"></i>
                                </Tooltip>
                              </Link>
                              <Popconfirm
                                okText="Đồng ý"
                                cancelText="Hủy"
                                title="Are you sure you want to delete?"
                                onConfirm={() => onDelete(item?.id)}
                              >
                                <Tooltip title="Delete">
                                  <i className="fas fa-times opacity-75 text-gray-600  rounded-full cursor-pointer"></i>
                                </Tooltip>
                              </Popconfirm>
                            </div>
                          </div>
                          <div className="flex">
                            <img
                              alt=""
                              src={
                                item?.avatar
                                  ? `${API_URL}/${item?.avatar}`
                                  : avtUser
                              }
                              style={{
                                height: 100,
                                width: 100,
                                objectFit: "cover",
                              }}
                            />
                            <div className="overflow-x-auto ">
                              <div>
                                <span className="p-2 text-sm whitespace-nowrap">
                                  Email: {item?.email}
                                </span>
                              </div>
                              <div className="pt-2 text-sm">
                                <span className="p-2 whitespace-nowrap">
                                  {" "}
                                  phone {item?.phone || "Chưa có"}
                                </span>
                              </div>
                              <div className="mt-2 px-2">
                                active
                                <Switch
                                  className="ml-2"
                                  {...(item?.active === true
                                    ? { checked: true }
                                    : { checked: false })}
                                  onChange={() => onChangeActive(item)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
            {listEmployee && listEmployee.length > 0 ? (
              <div className="border-[1px] border-gray-600 rounded-lg mb-4 mx-auto w-[95%]">
                <h1 className="text-lg text-white ml-3 px-3 mb-[30px] font-normal border-l-[6px] border-red-400 mt-4">
                  Staff
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 overflow-y-auto max-h-[550px] mb-4 px-4">
                  {listEmployee?.map((item, index) => {
                    return (
                        <div
                          key={index}
                          className="shadow-lg border-b border-r inline-block text-white overflow-x-auto"
                        >
                          <div className="bg-red-400  border border-[#eee] flex justify-between">
                            <h5 className="m-0 p-2  text-center capitalize">
                              {item?.name}
                            </h5>
                            <div className="mr-2 pt-1">
                              <Link to={`${ROUTER.ACCOUNT.EDIT}/${item.id}`}>
                                <Tooltip title="Edit">
                                  <i className="fas fa-edit opacity-75  text-gray-600  rounded-full pr-2 cursor-pointer"></i>
                                </Tooltip>
                              </Link>
                              <Popconfirm
                                okText="Đồng ý"
                                cancelText="Hủy"
                                title="Are you sure you want to delete?"
                                onConfirm={() => onDelete(item?.id)}
                              >
                                <Tooltip title="Delete">
                                  <i className="fas fa-times opacity-75 text-gray-600  rounded-full cursor-pointer"></i>
                                </Tooltip>
                              </Popconfirm>
                            </div>
                          </div>
                          <div className="flex">
                            <img
                              alt=""
                              src={
                                item?.avatar
                                  ? `${API_URL}/${item?.avatar}`
                                  : avtUser
                              }
                              style={{
                                height: 100,
                                width: 100,
                                objectFit: "cover",
                              }}
                            />
                            <div className="overflow-x-auto ">
                              <div>
                                <span className="p-2 text-sm whitespace-nowrap">
                                  Email: {item?.email}
                                </span>
                              </div>
                              <div className="pt-2 text-sm">
                                <span className="p-2 whitespace-nowrap">
                                  phone {item?.phone || "Chưa có"}
                                </span>
                              </div>
                              <div className="mt-2 px-2">
                                active
                                <Switch
                                  className="ml-2"
                                  {...(item?.active === true
                                    ? { checked: true }
                                    : { checked: false })}
                                  onChange={() => onChangeActive(item)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default AccountList;
