import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import InputSearch from "../../../components/InputSearch/InputSearch";
import ROUTER from "../../../routers/routerLink";
import {
  notification,
  Tooltip,
  Popconfirm,
  Pagination,
  Popover,
  Spin,
  Form,
  Select,
} from "antd";
import { debounce } from "lodash";
import { unwrapResult } from "@reduxjs/toolkit";
import { deleteProduct, getProduct } from "./../ProductSlice";
import noImage from "../../../assets/image/no-image.jpg";
import { API_URL } from "./../../../Utils/Config";
import queryString from "query-string";
import { removeFilters, setFilters } from "../ProductSlice";

const { Option } = Select;
const ProductList = () => {
  const history = useHistory();
  const [valueSearch, setValueSearch] = useState("");
  const [form] = Form.useForm();
  const search = useLocation().search;
  const dispatch = useDispatch();
  const listProduct = useSelector((state) => state?.product?.items);
  const filters = useSelector((state) => state?.product?.filters);
  const meta = useSelector((state) => state?.product?.meta);
  const isLoading = useSelector((state) => state?.product?.isLoading);
  useEffect(() => {
    if (filters) {
      form.setFieldsValue({
        searchType: filters["supplier"] ? filters["supplier"] : "all",
      });
      setValueSearch(filters["keyword"]);
    } else {
      form.setFieldsValue({
        searchType: "",
      });
      setValueSearch("");
    }
  }, [form, filters]);

  useEffect(() => {
    search && dispatch(setFilters(queryString.parse(search)));
  }, [dispatch, search]);

  useEffect(() => {
    return () => {
      dispatch(removeFilters());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProduct(queryString.parse(search)));
  }, [dispatch, search]);

  useEffect(() => {
    history.push(
      `${ROUTER.PRODUCT.LIST}?${queryString.stringify(filters, {
        skipEmptyString: true,
      })}`
    );
  }, [history, filters]);

  // useEffect(() => {
  //   const { search } = props.history.location;
  //   let params;
  //   const filterOptions = search.split("?")[1];
  //   if (filterOptions !== undefined) {
  //     params = `{"${decodeURI(filterOptions)
  //       .replace(/"/g, '\\"')
  //       .replace(/&/g, '","')
  //       .replace(/=/g, '":"')}"}`;
  //     dispatch(getProduct(JSON.parse(params)));
  //   } else {
  //     dispatch(getProduct({ page: 1, size: 8 }));
  //   }
  // }, [dispatch, props.history.location]);

  // const getVersionByProduct = async (id) => {
  //   try {
  //     let response = await Api.get(`/api/version/${id}/product`);
  //     if (response.status === 200) {
  //       setListVersionByProduct(response?.data?.data);
  //     }
  //   } catch (error) {}
  // };
  // useEffect(() => {
  //   if (idProduct !== null) {
  //     getVersionByProduct(idProduct);
  //   }
  // }, [idProduct]);

  // const columns = [
  //   { dataIndex: "title", title: "Tên sản phẩm", sorting: true, type: "left" },
  //   {
  //     dataIndex: "description",
  //     title: "Describe",
  //     sorting: true,
  //     type: "left",
  //     render: (value) =>
  //       value.length >= 30 ? (
  //         <Popover
  //           content={
  //             <>
  //               <div className="max-w-[250px] break-words">
  //                 <h1 className="border-b-2 text-xl font-semibold">Note </h1>
  //                 <p> {value}</p>
  //               </div>
  //             </>
  //           }
  //         >
  //           <span className="font-medium">
  //             {value.substring(0, 30) + " ..."}
  //           </span>
  //         </Popover>
  //       ) : (
  //         <span className="font-medium">{value}</span>
  //       ),
  //   },
  // ];
  const onDelete = async (id) => {
    try {
      const resultAction = await dispatch(deleteProduct(id));
      unwrapResult(resultAction);
      notification.success({ message: `Delete successfully!` });
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };
  const fecthSearch = (value) => {
    if (value) {
      dispatch(setFilters({ keyword: value, page: "", size: "" }));
    } else {
      dispatch(setFilters({ keyword: "" }));
    }
  };
  const debounceSearch = useCallback(debounce(fecthSearch, 1000), []);
  const onSearch = (e) => {
    setValueSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
    debounceSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
  };
  const onChangePage = (page, size) => {
    // const { search } = props.history.location;
    // if (search !== "") {
    //   let params;
    //   const filterOptions = search.split("?")[1];
    //   if (filterOptions !== undefined) {
    //     params = `{"${decodeURI(filterOptions)
    //       .replace(/"/g, '\\"')
    //       .replace(/&/g, '","')
    //       .replace(/=/g, '":"')}"}`;
    //   }
    //   const obj = JSON.parse(params);
    //   params = { ...obj, page, size };
    //   const urlParameters = [];
    //   Object.entries(params).forEach((e) => {
    //     if (e[1] !== undefined && e[1] !== "") {
    //       urlParameters.push(e.join("="));
    //     }
    //   });
    //   props.history.push(
    //     ROUTER.PRODUCT.LIST.concat(`?${urlParameters.join("&")}`)
    //   );
    // } else {
    //   props.history.push(ROUTER.PRODUCT.LIST.concat(`?page=${page}&size=8`));
    // }
    dispatch(setFilters({ page: page, size: size }));
  };
  const onChangeLicenseKeyType = (value) => {
    if (value && value !== "all") {
      dispatch(setFilters({ supplier: value, page: "", size: "" }));
    } else {
      dispatch(setFilters({ supplier: "" }));
    }
  };

  return (
    <>
      <div className="container pb-16 sm:px-0">
        <div className="w-full md:flex md:justify-between">
          <div>
            <NavLink to={ROUTER.PRODUCT.ADD}>
              <ButtonSubmit
                iconButton={<i className="fas fa-plus pr-[15px]"></i>}
                titleButton="Add new"
              />
            </NavLink>
          </div>
          <Form form={form}>
            <div className="md:flex md:justify-end ">
              <div className="w-[200px] mr-2">
                <Form.Item name="searchType">
                  <Select
                    placeholder="LicenseKey classification"
                    className="ant-select-custom1 border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // defaultValue="all"
                    onChange={onChangeLicenseKeyType}
                  >
                    <Option value="all">All</Option>
                    <Option value="htsc">Internal products</Option>
                    <Option value="nothtsc">Other products</Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.Item name="searchName">
                <InputSearch
                  placeholder="Search product..."
                  onChange={onSearch}
                  valueSearch={valueSearch}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
        <Spin spinning={isLoading}>
          {listProduct && listProduct.length > 0 ? (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  mb-3">
                {listProduct?.map((item) => (
                  <div className="list-product relative my-3" key={item.id}>
                    <div className="product-image overflow-hidden h-[200px] xl:h-[288px] sm:pl-8">
                      <div className="show-icon z-50 ">
                        <Link to={`${ROUTER.PRODUCT.EDIT}/${item.id}`}>
                          <Tooltip title="Edit">
                            <i className="fas fa-edit opacity-75  text-white  rounded-full pr-2 cursor-pointer"></i>
                          </Tooltip>
                        </Link>
                        <Popconfirm
                          okText="Đồng ý"
                          cancelText="Hủy"
                          title="Are you sure you want to delete?"
                          onConfirm={() => onDelete(item?.id)}
                        >
                          <Tooltip title="Delete">
                            <i className="fas fa-times opacity-75 text-white  rounded-full cursor-pointer"></i>
                          </Tooltip>
                        </Popconfirm>
                      </div>
                      <Link
                        className="text-lg text-black font-semibold hover:text-[#ff0000] transition duration-300"
                        to={`${ROUTER.PRODUCT.EDITION.LIST}/${item?.id}`}
                      >
                        <div className="border rounded-md border-gray-200 overlay-img overflow-hidden h-full w-[90%] relative">
                          <img
                            className="h-full w-full object-cover relative"
                            src={`${API_URL}/${item.urlImage}`}
                            onError={(e) => {
                              e.target.src = noImage;
                            }}
                          />
                          <div className="show-text z-50 w-full max-w-[250px]">
                            <div className="show-text-here hidden md:block">
                              <p>
                                {item?.description.length >= 70
                                  ? item?.description.substring(0, 70) + " ..."
                                  : item?.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="product-button bg-[#414141] absolute left-[2px] z-[3] bottom-4 py-2 pl-8 pr-5 shadow w-[150px] lg:w-[150px] xl:w-[230px]">
                      <Link
                        className="text-lg text-white font-semibold hover:text-[#ff0000] transition duration-300"
                        to={`${ROUTER.PRODUCT.EDITION.LIST}/${item?.id}`}
                      >
                        <div className="title-product">
                          <Tooltip title={item.title}>
                            <span>{item.title}</span>
                          </Tooltip>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
                ;
              </div>
              <div className="flex justify-center">
                {meta?.total > 8 ? (
                  <>
                    <Pagination
                      // size="small"
                      className="custom-pagination"
                      current={meta?.page ? meta?.page : 1}
                      total={meta?.total}
                      pageSize={meta?.size}
                      defaultPageSize={7}
                      onChange={onChangePage}
                      // showSizeChanger
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <div className="flex justify-center h-[200px]">
              <div className=" flex flex-col text-center text-white justify-center ">
                <i className="fas fa-exclamation-circle fa-2x"></i>
                <span className="text-xl mt-2"> No data</span>
              </div>
            </div>
          )}
        </Spin>
      </div>
    </>
  );
};

export default ProductList;
