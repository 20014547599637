import React from "react";

const ButtonCancel = ({ onClick }) => {
  return (
    <>
      <button
        className="custom-btn btn-cancel"
        style={{ maxWidth: "105px", height: "40px" }}
        type="button"
        onClick={onClick}
      >
        Cancel
      </button>
    </>
  );
};

export default ButtonCancel;
