import React, { useEffect, useState } from "react";
import logo from "../../assets/image/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { InputNumber } from "antd";
import { getCustomer } from "./../Customer/CustomerSlice";
import { getProduct } from "./../Product/ProductSlice";
import { ReportLicenseKeyTime } from "./../LicenseKey/LicenseKeySlice";
import ChartColumn from "./components/ChartColumn";
import ChartPie from "./components/ChartPie";
import Api from "../../Utils/Api";
import Notification from "./components/Notification";
import { NavLink } from "react-router-dom";
import ROUTER from "../../routers/routerLink";
import ChartColumnMonth from "./components/ChartColumnMonth";
import moment from "moment";
import { getAllLicenseKeyCategory } from "../LicenseKeyCategory/LicenseKeyCategorySlice";

const Home = () => {
  const dispatch = useDispatch();
  const metaCustomer = useSelector((state) => state.customer.meta);
  const metaProduct = useSelector((state) => state.product.meta);
  const notifiReportTimeList = useSelector(
    (state) => state.licenseKey.reportTimeList
  );
  const listAllCategory = useSelector(
    (state) => state?.licenseKeyCategory?.items
  );
  const [YearSelect, setYearSelect] = useState(moment().format("YYYY"));
  const [listLicenkeyReport, setListLicenkeyReport] = useState([]);
  const [listLProductReport, setListProductReport] = useState(null);
  const [listLicenseKeyCategory, setListLicenseKeyCategory] = useState(null);
  const [isLoadingLicensekey, setIsLoadingLicenseKey] = useState(false);
  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );
  const getLicenseKeyReport = async () => {
    try {
      let response = await Api.get(`/api/report/licensekey`);
      if (response.status === 200) {
        setListLicenkeyReport(response?.data?.data);
      }
    } catch (error) {}
  };

  const getProductReport = async () => {
    try {
      let response = await Api.get(`/api/report/product`);
      if (response.status === 200) {
        const listProduct = [];
        const listExpirationDate = [];
        const listLessThan15Days = [];
        const listMoreThan15Day = [];
        for (let i = 0; i < response.data?.data.length; i++) {
          listProduct.push({
            title: response.data?.data[i].title,
            productId: response.data?.data[i].productId,
          });
          listExpirationDate.push(response.data?.data[i].expirationDate);
          listLessThan15Days.push(response.data?.data[i].lessThan15Days);
          listMoreThan15Day.push(response.data?.data[i].moreThan15Days);
        }
        setListProductReport({
          listProduct,
          listExpirationDate,
          listLessThan15Days,
          listMoreThan15Day,
        });
      } else {
        setListProductReport(null);
      }
    } catch (error) {
      setListProductReport(null);
    }
  };

  const getDataChartLicenseKeyCateByYear = (data) => {
    const listMonth = [];
    const listTrade = [];
    const listExperiment = [];
    for (let i = 0; i < data.length; i++) {
      listMonth.push(`Month ${data[i].month}`);
      if (data[i].data) {
        const trade = data[i].data.find(
          (item) => item?.licenseKeyCategoryName === "Thương mại"
        );
        listTrade.push(trade?.total);
        const experiment = data[i].data.find(
          (item) => item?.licenseKeyCategoryName === "Dùng thử"
        );
        listExperiment.push(experiment?.total);
      }
    }
    return {
      listMonth,
      listTrade,
      listExperiment,
    };
  };

  const getLicenseKeyCategory = async (YearSelect) => {
    setIsLoadingLicenseKey(!isLoadingLicensekey);
    try {
      const params = { year: YearSelect };
      let response = await Api.get(`/api/report/category`, { params: params });
      if (response.status === 200) {
        let data = await getDataChartLicenseKeyCateByYear(response?.data?.data);
        setListLicenseKeyCategory({
          listMonth: data?.listMonth,
          listTrade: data?.listTrade,
          listExperiment: data?.listExperiment,
        });
        setIsLoadingLicenseKey(false);
      } else {
        setListLicenseKeyCategory({
          listMonth: [],
          listTrade: [],
          listExperiment: [],
        });
        setIsLoadingLicenseKey(false);
      }
    } catch (error) {
      setIsLoadingLicenseKey(false);
      setListLicenseKeyCategory(null);
    }
  };

  useEffect(() => {
    dispatch(getProduct(null));
    dispatch(ReportLicenseKeyTime());
    dispatch(getAllLicenseKeyCategory());
    getProductReport();
    dispatch(getAllLicenseKeyCategory());
    getLicenseKeyReport();
  }, [dispatch]);

  useEffect(() => {
    getLicenseKeyCategory(YearSelect);
  }, [YearSelect]);

  useEffect(() => {
    if (accountDetail?.id) {
      dispatch(getCustomer({ id: accountDetail?.id, params: null }));
    }
  }, [dispatch, accountDetail]);

  const onChangeYear = async (value) => {
    await getLicenseKeyCategory(value);
    setYearSelect(value);
  };
  return (
    <>
      <div className="overflow-x-auto mb-10">
        <div className="grid grid-cols-12 gap-5 mt-[15px]">
          <div className="col-span-full lg:col-span-9">
            <div className="grid grid-cols-12 gap-5 mt-[35px]">
              <div className="w-2/3 sm:w-full px-2 py-3 flex flex-wrap col-span-full sm:col-span-6 xl:col-span-3 bg-[#262626] shadow-lg rounded-xl ">
                <NavLink to={ROUTER.CUSTOMER.LIST}>
                  <div className="relative bg-gradient-to-tr from-gray-400 to-gray-700 -mt-11 mb-2 rounded-xl text-white grid items-center w-15 h-15 py-3 px-3 justify-center shadow-lg-purple ">
                    <span className="material-icons text-white text-3xl leading-none">
                      <i className="fas fa-users"></i>
                    </span>
                  </div>
                </NavLink>
                <div className="w-full flex-grow flex-1 text-right">
                  <header className="flex justify-end items-start mb-3 border-b border-gray-200">
                    <img
                      className="mb-3"
                      src={logo}
                      width={80}
                      height={80}
                      alt=""
                    />
                  </header>
                  <h2 className="text-xs font-semibold text-gray-400 uppercase">
                    Customer
                  </h2>
                  <div className="flex justify-end">
                    <div className="text-3xl font-bold text-white mr-1">
                      {metaCustomer?.total}
                    </div>
                    <i className="fas fa-user text-gray-500 mt-[17px] "></i>
                  </div>
                </div>
              </div>

              <div className="w-2/3 sm:w-full px-2 py-3 flex flex-wrap col-span-full sm:col-span-6 xl:col-span-3 bg-[#262626]  shadow-lg rounded-xl ">
                <NavLink to={ROUTER.LICENSEKEY.LIST}>
                  <div className="relative bg-gradient-to-tr from-gray-400 to-gray-700 -mt-11 mb-2 rounded-xl text-white grid items-center w-15 h-15 py-3 px-3 justify-center shadow-lg-purple ">
                    <span className="material-icons text-white text-3xl leading-none">
                      <i className="fas fa-plane"></i>
                    </span>
                  </div>
                </NavLink>
                <div className="w-full flex-grow flex-1 text-right">
                  <header className="flex justify-end items-start mb-3 border-b border-gray-200">
                    <img
                      className="mb-3"
                      src={logo}
                      width={80}
                      height={80}
                      alt=""
                    />
                  </header>
                  <h2 className="text-xs font-semibold text-gray-400 uppercase">
                    Key Online
                  </h2>
                  <div className="flex justify-end">
                    <div className="text-3xl font-bold text-white mr-1">
                      {listLicenkeyReport?.countLicenseKeyOnline}
                    </div>
                    <i className="fas fa-key text-gray-500 mt-[17px] "></i>
                  </div>
                </div>
              </div>
              <div className="w-2/3 sm:w-full px-2 py-3 flex flex-wrap col-span-full sm:col-span-6 xl:col-span-3 bg-[#262626]  shadow-lg rounded-xl ">
                <NavLink to={ROUTER.LICENSEKEY.LIST}>
                  <div className="relative bg-gradient-to-tr from-gray-400 to-gray-700 -mt-11 mb-2 rounded-xl text-white grid items-center w-15 h-15 py-3 px-3 justify-center shadow-lg-purple ">
                    <span className="material-icons text-white text-3xl leading-none">
                      <i className="fas fa-plane-slash"></i>
                    </span>
                  </div>
                </NavLink>
                <div className="w-full flex-grow flex-1 text-right">
                  <header className="flex justify-end items-start mb-3 border-b border-gray-200">
                    <img
                      className="mb-3"
                      src={logo}
                      width={80}
                      height={80}
                      alt=""
                    />
                  </header>
                  <h2 className="text-xs font-semibold text-gray-400 uppercase">
                    Key Offline
                  </h2>
                  <div className="flex justify-end">
                    <div className="text-3xl font-bold text-white mr-1">
                      {listLicenkeyReport?.countLiscenseKeyOffline}
                    </div>
                    <i className="fas fa-key text-gray-500 mt-[17px] "></i>
                  </div>
                </div>
              </div>
              <div className="w-2/3 sm:w-full px-2 py-3 flex flex-wrap col-span-full sm:col-span-6 xl:col-span-3 bg-[#262626]  shadow-lg rounded-xl">
                <NavLink to={ROUTER.PRODUCT.LIST}>
                  <div className="relative bg-gradient-to-tr from-gray-400 to-gray-700 -mt-11 mb-2 rounded-xl text-white grid items-center w-15 h-15 py-3 px-3 justify-center shadow-lg-purple ">
                    {/* <div className="absolute top-0 right-0 h-[20px] px-[5px] font-semibold text-white bg-yellow-500 rounded-lg">
              -5%
            </div> */}
                    <span className="material-icons text-white text-3xl leading-none">
                      <i className="fas fa-laptop-code"></i>
                    </span>
                  </div>
                </NavLink>
                <div className="w-full flex-grow flex-1 text-right">
                  <header className="flex justify-end items-start mb-3 border-b border-gray-200">
                    <img
                      className="mb-3"
                      src={logo}
                      width={80}
                      height={80}
                      alt=""
                    />
                  </header>
                  <h2 className="text-xs font-semibold text-gray-400 uppercase">
                    Product
                  </h2>
                  <div className="flex justify-end">
                    <div className="text-3xl font-bold text-white mr-1">
                      {metaProduct.total}
                    </div>
                    <i className="fas fa-file-code text-gray-500 mt-[17px] "></i>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 col-span-full xl:col-span-12 bg-[#262626] shadow-lg rounded-xl">
                <div>
                  <div>
                    <h1 className="text-white text-[25px] font-extrabold">
                      LicenseKey Expired Statistics
                    </h1>
                  </div>
                  <div className="flex justify-center overflow-x-auto">
                    {listLProductReport !== null ? (
                      <ChartColumn
                        listProduct={listLProductReport?.listProduct}
                        listExpirationDate={
                          listLProductReport?.listExpirationDate
                        }
                        listLessThan15Days={
                          listLProductReport?.listLessThan15Days
                        }
                        listMoreThan15Day={
                          listLProductReport?.listMoreThan15Day
                        }
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 col-span-full xl:col-span-12 bg-[#262626] shadow-lg rounded-xl">
                <div>
                  <div className="flex justify-between">
                    <h1 className="text-white text-[25px] font-extrabold">
                      LicenseKey catalog statistics for the year {YearSelect}
                    </h1>
                    <div className="mr-5">
                      <InputNumber
                        min={2000}
                        max={moment().format("YYYY")}
                        defaultValue={moment().format("YYYY")}
                        onChange={onChangeYear}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center">
                    {listLicenseKeyCategory !== null &&
                      listAllCategory &&
                      isLoadingLicensekey === false && (
                        <ChartColumnMonth
                          listAllCategory={listAllCategory}
                          YearSelect={YearSelect}
                          listMonth={listLicenseKeyCategory?.listMonth}
                          listTrade={listLicenseKeyCategory?.listTrade}
                          listExperiment={
                            listLicenseKeyCategory?.listExperiment
                          }
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-full lg:col-span-3">
            <div className="bg-[#262626] shadow-lg rounded-xl mt-[35px] overflow-y-auto h-auto">
              <div className="pl-3 mt-4">
                <Notification notifiReportTimeList={notifiReportTimeList} />
              </div>
            </div>
            <div className="px-4 py-4 bg-[#262626] shadow-lg rounded-xl mt-[20px]">
              <div>
                <h1 className="text-white text-[25px] font-extrabold mb-3">
                  LicenseKey type
                </h1>
                {listLicenkeyReport !== null ? (
                  <ChartPie data={listLicenkeyReport} />
                ) : null}
              </div>
            </div>
            <div className="p-3 mb-6 bg-[#262626] shadow-lg rounded-xl mt-[20px] overflow-y-auto h-[157px]">
              <h4 className="font-bold text-[20px] text-white">Help</h4>
              <p className="text-[#8A8989]">
                Are you having any problems using the software?
              </p>
              <div className="flex justify-end">
                <a href="https://htsc.vn" target="_blank" rel="noreferrer">
                  <button className="bg-[#405EE7] rounded-lg px-3 py-1 hover:bg-blue-500">
                    Contact
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
