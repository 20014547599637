import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { TheSidebar, TheFooter, TheHeader } from "./index";
import viVN from "antd/lib/locale/vi_VN";
import { ConfigProvider } from "antd";
import { CContainer, CFade } from "@coreui/react";
import moment from "moment";
import { useSelector } from "react-redux";
import "moment/locale/vi";
import ROUTER from "../../routers/routerLink";
import routes from "../../routers/routers";

const TheLayout = () => {
  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );
  moment.locale("vi");
  return (
    // <Suspense
    //   fallback={
    //     <div className="h-screen w-screen bg-black flex items-center justify-center">
    //       <img
    //         className="object-cover h-28"
    //         src={Preloader}
    //         alt="Hình ảnh không tồn tại"
    //       />
    //     </div>
    //   }
    // >
    <ConfigProvider locale={viVN}>
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper bg-black">
          <TheHeader />
          <div className="c-body bg-black">
            <main className="c-main">
              <CContainer fluid>
                <Switch>
                  {routes.map((route, index) => {
                    return (
                      route.component && (
                        <Route
                          key={index}
                          path={
                            accountDetail?.roleCode === "employee" &&
                            route.path === ROUTER.ACCOUNT.LIST
                              ? ROUTER.HOME.HOME
                              : route.path
                          }
                          exact={route.exact}
                          name={route.name}
                          render={(props) => (
                            <CFade>
                              <route.component {...props} />
                            </CFade>
                          )}
                        />
                      )
                    );
                  })}
                  <Redirect from="*" to={ROUTER.HOME.HOME} />
                </Switch>
              </CContainer>
            </main>
          </div>
          <TheFooter />
        </div>
      </div>
    </ConfigProvider>
    // </Suspense>
  );
};

export default TheLayout;
