import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "./../../../Utils/Api";

export const loginUser = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post("/api/auth/login", data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const logoutUser = createAsyncThunk("auth/logout", async () => {
  localStorage.removeItem("token");
});

const LoginUserSlice = createSlice({
  name: "LoginUser",
  initialState: {
    isAuthenticated: false,
    isLoading: false,
  },
  extraReducers: {
    [loginUser.pending]: (state, action) => {
      state.isLoading = true;
      state.isAuthenticated = false;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
    },
    [loginUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
    },
    [logoutUser.fulfilled]: (state, action) => {
      localStorage.clear();
      state.isLoading = false;
      state.isAuthenticated = false;
    },
  },
});
export default LoginUserSlice.reducer;
