import Chart from "react-apexcharts";
import { useHistory } from "react-router-dom";
import ROUTER from "../../../routers/routerLink";
import { useSelector, useDispatch } from "react-redux";
import { setFilters } from "../../LicenseKeyCategoryChart/LicenseKeyCategoryChartSlice";
import { useState } from "react";

const ChartColumnMonth = (props) => {
  const { listTrade, listExperiment, YearSelect, listMonth } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const listLicenseKeyCategory = useSelector(
    (state) => state?.licenseKeyCategory?.items
  );

  const getLicenseKeyCategoryID = (
    nameLicenseKeyCate,
    listLicenseKeyCategory
  ) => {
    for (let i = 0; i < listLicenseKeyCategory.length; i++) {
      if (
        listLicenseKeyCategory[i].title.localeCompare(nameLicenseKeyCate) === 0
      ) {
        return listLicenseKeyCategory[i].id;
      }
    }
  };

  const [dataChart] = useState({
    series: [
      {
        name: "Commerce",
        data: [...listTrade],
      },
      {
        name: "Trial",
        data: [...listExperiment],
      },
    ],
    options: {
      chart: {
        stacked: true,
        foreColor: "#fff",
        background: "none",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            if (config.w.globals.tooltip.tooltipTitle.innerHTML === "Month 1") {
              dispatch(
                setFilters({
                  timestart: `${YearSelect}-01-01`,
                  timeend: `${YearSelect}-02-01`,
                })
              );
            } else if (
              config.w.globals.tooltip.tooltipTitle.innerHTML === "Month 2"
            ) {
              dispatch(
                setFilters({
                  timestart: `${YearSelect}-02-01`,
                  timeend: `${YearSelect}-03-01`,
                })
              );
            } else if (
              config.w.globals.tooltip.tooltipTitle.innerHTML === "Month 3"
            ) {
              dispatch(
                setFilters({
                  timestart: `${YearSelect}-03-01`,
                  timeend: `${YearSelect}-04-01`,
                })
              );
            } else if (
              config.w.globals.tooltip.tooltipTitle.innerHTML === "Month 4"
            ) {
              dispatch(
                setFilters({
                  timestart: `${YearSelect}-04-01`,
                  timeend: `${YearSelect}-05-01`,
                })
              );
            } else if (
              config.w.globals.tooltip.tooltipTitle.innerHTML === "Month 5"
            ) {
              dispatch(
                setFilters({
                  timestart: `${YearSelect}-05-01`,
                  timeend: `${YearSelect}-06-01`,
                })
              );
            } else if (
              config.w.globals.tooltip.tooltipTitle.innerHTML === "Month 6"
            ) {
              dispatch(
                setFilters({
                  timestart: `${YearSelect}-06-01`,
                  timeend: `${YearSelect}-07-01`,
                })
              );
            } else if (
              config.w.globals.tooltip.tooltipTitle.innerHTML === "Month 7"
            ) {
              dispatch(
                setFilters({
                  timestart: `${YearSelect}-07-01`,
                  timeend: `${YearSelect}-08-01`,
                })
              );
            } else if (
              config.w.globals.tooltip.tooltipTitle.innerHTML === "Month 8"
            ) {
              dispatch(
                setFilters({
                  timestart: `${YearSelect}-08-01`,
                  timeend: `${YearSelect}-09-01`,
                })
              );
            } else if (
              config.w.globals.tooltip.tooltipTitle.innerHTML === "Month 9"
            ) {
              dispatch(
                setFilters({
                  timestart: `${YearSelect}-09-01`,
                  timeend: `${YearSelect}-10-01`,
                })
              );
            } else if (
              config.w.globals.tooltip.tooltipTitle.innerHTML === "Month 10"
            ) {
              dispatch(
                setFilters({
                  timestart: `${YearSelect}-10-01`,
                  timeend: `${YearSelect}-11-01`,
                })
              );
            } else if (
              config.w.globals.tooltip.tooltipTitle.innerHTML === "Month 11"
            ) {
              dispatch(
                setFilters({
                  timestart: `${YearSelect}-11-01`,
                  timeend: `${YearSelect}-12-01`,
                })
              );
            } else if (
              config.w.globals.tooltip.tooltipTitle.innerHTML === "Month 12"
            ) {
              dispatch(
                setFilters({
                  timestart: `${YearSelect}-12-01`,
                  timeend: `${YearSelect + 1}-01-01`,
                })
              );
            }
            const LicensekeyCateId = getLicenseKeyCategoryID(
              config.w.config.series[config.seriesIndex].name,
              listLicenseKeyCategory
            );
            history.push(
              `${ROUTER.LICENSEKEY_CATEGORY_CHART.LIST}/${LicensekeyCateId}`
            );
          },
        },
      },
      colors: ["#33CC00", "#33FFFF"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: listMonth,
      },
      legend: {
        position: "bottom",
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
  });

  return (
    <>
      <div
        // style={{ width: "100%", maxWidth: "90%" }}
        className="w-full max-w-[100%] xl:max-w-[90%]"
      >
        <Chart
          series={dataChart?.series}
          options={dataChart?.options}
          type="bar"
          height={310}
        />
      </div>
    </>
  );
};

export default ChartColumnMonth;
