import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ButtonCancel from "../../../components/Button/ButtonCancel";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import { notification, Switch, Button, Upload, Image } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import ROUTER from "../../../routers/routerLink";
import Api from "../../../Utils/Api";
import { updateVersion } from "./../VersionSlice";
import { getAllProduct } from "../../Product/ProductSlice";
import { getUserInfo } from "./../../../Utils/localStorage/GetUserInfo";
import { API_URL } from "./../../../Utils/Config";
import { beforeUploadFile } from "./../../../components/UploadFile/beforeUploadFile";
const VersionEdit = () => {
  const token = getUserInfo("token");
  const { id, idproduct } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [fileUploadByVesion, setfileUploadByVesion] = useState([]);
  const [listFileUpload, setListFileUpload] = useState([]);
  const [type, setType] = useState(false);
  const [nameProduct, setNameProduct] = useState("");
  const {
    handleSubmit,
    register,
    // setError,
    formState: { errors },
    reset,
  } = useForm();
  const finalUploadFileList = listFileUpload.concat(fileUploadByVesion);
  const getVersionById = async (id) => {
    try {
      let response = await Api.get(`api/version/getbyid/${id}`);
      if (response.status === 200) {
        reset(response?.data?.data);
        setfileUploadByVesion(response?.data?.data.urlFiles);
        setType(response?.data?.data.type);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      getVersionById(id);
    }
    dispatch(getAllProduct());
  }, [dispatch, id]);

  const getEditonById = async (id) => {
    try {
      let response = await Api.get(`api/edition/getbyid/${id}`);
      if (response.status === 200) {
        setNameProduct(response?.data?.data?.title);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (idproduct) {
      getEditonById(idproduct);
    }
  }, [dispatch, idproduct]);

  const onSubmit = async (value) => {
    const data = {
      type: type,
      productId: idproduct,
      title: value?.title?.trim(),
      domain: type ? value?.domain?.trim() : "",
      ipAddress: type ? value?.ipAddress?.trim() : "",
      description: value?.description?.trim() || "",
      urlFiles: finalUploadFileList || [],
    };
    try {
      const resulDispatch = await dispatch(updateVersion({ id, data }));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Edit success!!!` });
        history.push(`${ROUTER.PRODUCT.EDITION.VERSION.LIST}/${idproduct}`);
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };

  const uploadFile = {
    name: "files",
    action: `${API_URL}/api/version/uploads`,
    multiple: true,
    listType: "text",
    beforeUpload: beforeUploadFile,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onChange(info) {
      let listUrls = [];
      if (info?.fileList?.length > 0) {
        for (let i = 0; i < info?.fileList?.length; i++) {
          if (
            info?.fileList[i]?.status &&
            info?.fileList[i]?.status === "done" &&
            info?.fileList[i]?.response?.data[0]
          ) {
            listUrls?.push(info?.fileList[i]?.response?.data[0]);
          }
        }
        setListFileUpload(listUrls);
      } else {
        setListFileUpload([]);
      }
    },
    showUploadList: false,
  };

  return (
    <>
      <div className="flex justify-center mb-10">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[1000px] overflow-hidden shadow-lg bg-[#262626] rounded-[10px]"
        >
          <div className="mx-[30px] my-[30px]">
            <div className="flex flex-wrap mb-3">
              <div className="w-full md:w-1/2 px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Version
                </label>
                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                  *
                </span>
                <input
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.title
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                  type="text"
                  placeholder="Version"
                  {...register("title", {
                    required: true,
                  })}
                />
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.title && errors?.title.type === "required"
                      ? "Please enter version"
                      : ""}
                  </span>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className=" text-white text-sm font-bold mb-2">
                  Product
                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                    *
                  </span>
                </label>
                <input
                  className="text-gray-600 bg-gray-300 border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  type="text"
                  value={nameProduct}
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-3">
              <div className="w-full md:w-1/2 px-3">
                <label className=" w-full text-white text-sm font-bold mb-2">
                  Type
                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                    *
                  </span>
                </label>

                <Switch
                  checkedChildren="Online"
                  unCheckedChildren="Offline"
                  checked={type}
                  onChange={(value) => setType(value)}
                />
              </div>
            </div>
            {type && (
              <div className="flex flex-wrap mb-3 ">
                <div className="w-full md:w-1/2 px-3 ">
                  <label className=" text-white text-sm font-bold mb-2">
                    Domain
                    <span className="text-red-600 font-extrabold text-[17px] ml-1">
                      *
                    </span>
                  </label>
                  <input
                    className="bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    type="text"
                    placeholder="Domain"
                    {...register("domain", { required: true })}
                  />
                  <div className="mt-1 h-[10px]">
                    <span className="text-red-500">
                      {errors?.domain && errors?.domain.type === "required"
                        ? "Please enter Domain"
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-3 ">
                  <label className=" text-white text-sm font-bold mb-2">
                    Địa chỉ IP
                    <span className="text-red-600 font-extrabold text-[17px] ml-1">
                      *
                    </span>
                  </label>
                  <input
                    className="bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    type="text"
                    placeholder="Địa chỉ IP"
                    {...register("ipAddress", { required: true })}
                  />
                  <div className="mt-1 h-[10px]">
                    <span className="text-red-500">
                      {errors?.ipAddress &&
                      errors?.ipAddress.type === "required"
                        ? "Please enter IP Address"
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 px-3 mb-3">
                <Upload {...uploadFile}>
                  <Button>
                    <UploadOutlined /> Tải tệp đính kèm
                  </Button>
                </Upload>
              </div>
            </div>
            <div className="flex flex-wrap max-h-[200px] overflow-x-auto">
              <div className="uploaded-file-list">
                {fileUploadByVesion &&
                  fileUploadByVesion.map((item, index) => {
                    return (
                      <div className="uploaded-file-list-item mx-3" key={index}>
                        {item.split(".")[item.split(".").length - 1] ===
                          "jpg" ||
                        item.split(".")[item.split(".").length - 1] ===
                          "png" ? (
                          <Image
                            preview={false}
                            style={{
                              height: 40,
                              width: 40,
                              objectFit: "cover",
                            }}
                            src={item}
                          />
                        ) : (
                          <i className="fas fa-file-archive fa-3x text-white"></i>
                        )}
                        <div className="uploaded-file-list-item-title">
                          <a target="_blank" href={item} rel="noreferrer">
                            {item?.split("/")[5]?.split(".")[0]}
                          </a>
                        </div>
                        <Button
                          style={{ height: "40px" }}
                          onClick={() =>
                            setfileUploadByVesion(
                              fileUploadByVesion.filter((e) => e !== item)
                            )
                          }
                        >
                          <DeleteOutlined className="text-lg" />
                        </Button>
                      </div>
                    );
                  })}
              </div>
              <div className="uploaded-file-list">
                {listFileUpload &&
                  listFileUpload?.map((item, index) => {
                    return (
                      <div className="uploaded-file-list-item mx-3" key={index}>
                        {item?.split(".")[item?.split(".")?.length - 1] ===
                          "jpg" ||
                        item?.split(".")[item?.split(".")?.length - 1] ===
                          "png" ? (
                          <Image
                            preview={false}
                            style={{
                              height: 40,
                              width: 40,
                              objectFit: "cover",
                            }}
                            src={item && item}
                          />
                        ) : (
                          <i className="fas fa-file-archive fa-3x text-white"></i>
                        )}
                        <div className="uploaded-file-list-item-title">
                          <a
                            target="_blank"
                            href={item && item}
                            rel="noreferrer"
                          >
                            {item?.split("/")[5]?.split(".")[0]}
                          </a>
                        </div>
                        <Button
                          style={{ height: "40px" }}
                          onClick={() =>
                            setListFileUpload(
                              listFileUpload?.filter((e) => e !== item)
                            )
                          }
                        >
                          <DeleteOutlined className="text-lg" />
                        </Button>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="flex flex-wrap mb-8">
              <div className="w-full px-3">
                <label className=" text-white text-sm font-bold mb-2">
                  Describe
                </label>
                <textarea
                  className="bg-[#414141] pl-[10px] pt-[10px] w-full border rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  rows={5}
                  placeholder="Describe"
                  {...register("description")}
                />
              </div>
            </div>
            <div className="flex justify-center ">
              <div className="mx-2">
                <ButtonCancel onClick={() => history.goBack()} />
              </div>
              <div className="mx-2">
                <ButtonSubmit titleButton="Confirm" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default VersionEdit;
