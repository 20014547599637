import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ButtonSubmit from "../../components/Button/ButtonSubmit";
import InputSearch from "../../components/InputSearch/InputSearch";
import ROUTER from "../../routers/routerLink";
import { useHistory, useLocation } from "react-router-dom";
import { notification, Form } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { debounce } from "lodash";
import TableAdmin from "../../components/Table/TableAdmin";
import RenderStatusExpiry from "../../components/RenderStatusExpiry/RenderStatusExpiry";
import {
  deleteLicenseKeyExpirationDate,
  getLicenseKeyExpirationDate,
} from "./LicenseKeyExpirationDateSlice";
import moment from "moment";
import queryString from "query-string";

const LicenseKeyExpirationDate = (props) => {
  const [valueSearch, setValueSearch] = useState("");
  const [form] = Form.useForm();
  const search = useLocation().search;
  const history = useHistory();
  const dispatch = useDispatch();
  const listLicenseKeyExpirationDate = useSelector(
    (state) => state?.licenseKeyExpirationDate?.items
  );
  const meta = useSelector((state) => state?.licenseKeyExpirationDate?.meta);
  const isLoading = useSelector(
    (state) => state?.licenseKeyExpirationDate?.isLoading
  );
  useEffect(() => {
    if (search) {
      setValueSearch(queryString.parse(search)["keyword"]);
    } else {
      setValueSearch("");
    }
  }, [search]);

  useEffect(() => {
    const { search } = props.history.location;
    let params;
    const filterOptions = search.split("?")[1];
    if (filterOptions !== undefined) {
      params = `{"${decodeURI(filterOptions)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`;
      dispatch(getLicenseKeyExpirationDate(JSON.parse(params)));
    } else {
      dispatch(getLicenseKeyExpirationDate(null));
    }
  }, [dispatch, props.history.location]);
  const columns = [
    {
      dataIndex: "macAddress",
      title: "MAC",
      sorting: true,
      type: "center",
      render: (value) => (
        <span className="font-medium">{value !== null ? value : "N/A"}</span>
      ),
    },
    {
      dataIndex: "customerName",
      title: "Customer",
      sorting: true,
      type: "left",
      render: (value) => (
        <span className="font-medium capitalize">{value}</span>
      ),
    },
    {
      dataIndex: "email",
      title: "Email",
      sorting: true,
      type: "left",
      render: (value) => (
        <span className="font-medium">{value ? value : "N/A"}</span>
      ),
    },
    {
      dataIndex: "productName",
      title: "Product",
      sorting: true,
      type: "center",
    },
    {
      dataIndex: "versionName",
      title: "Version",
      sorting: true,
      type: "center",
    },
    {
      dataIndex: "licenseType",
      title: "Type",
      sorting: true,
      type: "center",
      render: (value) => (
        <span className="font-medium">
          {value === 1
            ? "Online"
            : value === 2
            ? "Offline"
            : value === 3
            ? "S/p Ncc khác"
            : "N/A"}
        </span>
      ),
    },
    {
      dataIndex: "licenseKeyCategoryName",
      title: "Danh mục",
      sorting: true,
      type: "center",
    },
    {
      dataIndex: "startDate",
      title: "Activation date",
      sorting: true,
      type: "center",
      render: (value) => (
        <span className="font-medium capitalize">
          {moment(value).format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      dataIndex: "expirationDate",
      title: "Expiration date",
      sorting: true,
      type: "center",
      render: (value) => (
        <span className="font-medium capitalize">
          {moment(value).format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      title: "Status",
      sorting: true,
      type: "center",
      render: (value) => RenderStatusExpiry(value?.expirationDate),
    },
    // {
    //   dataIndex: "note",
    //   title: "Note",
    //   sorting: true,
    //   type: "left",
    //   render: (value) =>
    //     value.length >= 20 ? (
    //       <Popover
    //         content={
    //           <>
    //             <div className="max-w-[250px] break-words">
    //               <h1 className="border-b-2 text-xl font-semibold">Note </h1>
    //               <p> {value}</p>
    //             </div>
    //           </>
    //         }
    //       >
    //         <span className="font-medium">
    //           {value.substring(0, 20) + " ..."}
    //         </span>
    //       </Popover>
    //     ) : (
    //       <span className="font-medium">{value}</span>
    //     ),
    // },
  ];
  const onDelete = async (id) => {
    try {
      const resultAction = await dispatch(deleteLicenseKeyExpirationDate(id));
      unwrapResult(resultAction);
      notification.success({ message: `Delete successfully!` });
    } catch (error) {
      notification.error({ message: `Error: ${error.message}` });
    }
  };
  const fecthSearch = (value) => {
    if (value) {
      props.history.push(
        `${ROUTER.LICENSEKEY_EXPIRATION_DATE.LIST}?keyword=${value}`
      );
    } else {
      props.history.push(ROUTER.LICENSEKEY_EXPIRATION_DATE.LIST);
      dispatch(getLicenseKeyExpirationDate(null));
    }
  };
  const debounceSearch = useCallback(debounce(fecthSearch, 1000), []);
  const onSearch = (e) => {
    setValueSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
    debounceSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
  };
  const onChangePage = (page, size) => {
    const { search } = props.history.location;
    if (search !== "") {
      let params;
      const filterOptions = search.split("?")[1];
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`;
      }
      const obj = JSON.parse(params);
      params = { ...obj, page, size };
      const urlParameters = [];
      Object.entries(params).forEach((e) => {
        if (e[1] !== undefined && e[1] !== "") {
          urlParameters.push(e.join("="));
        }
      });
      props.history.push(
        ROUTER.LICENSEKEY_EXPIRATION_DATE.LIST.concat(
          `?${urlParameters.join("&")}`
        )
      );
    } else {
      props.history.push(
        ROUTER.LICENSEKEY_EXPIRATION_DATE.LIST.concat(
          `?page=${page}&size=${size}`
        )
      );
    }
  };
  return (
    <>
      <div className="overflow-x-auto mb-10 mt-4">
        <div className="flex justify-center mb-3 bg-[#262626] px-4 py-8 rounded-[10px]">
          <div className="w-full">
            <div className="w-full mb-3 md:flex md:justify-between">
              <div>
                <ButtonSubmit
                  onClick={() => history.goBack()}
                  iconButton={
                    <i className="fas fa-angle-double-left pr-[15px]"></i>
                  }
                  titleButton="Quay Lại"
                />
              </div>
              <Form form={form}>
                <div className="md:flex md:justify-end">
                  <Form.Item name="searchName">
                    <InputSearch
                      onChange={onSearch}
                      valueSearch={valueSearch}
                      placeholder="Search by email..."
                    />
                  </Form.Item>
                </div>
              </Form>
            </div>

            <div className="">
              <TableAdmin
                sendMail
                Url={ROUTER.LICENSEKEY.EDIT}
                onDelete={onDelete}
                columns={columns}
                spinning={isLoading}
                dataTable={listLicenseKeyExpirationDate}
                meta={meta}
                onChangePage={onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LicenseKeyExpirationDate;
