import React from "react";
import { useForm } from "react-hook-form";
import ButtonCancel from "../../../components/Button/ButtonCancel";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ROUTER from "../../../routers/routerLink";
import { addPriority } from "../PrioritySlice";
import { validateInputName } from "../../../Utils/Validate/validateInput";
const PriorityAdd = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const resulDispatch = await dispatch(addPriority(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Successfully added new !!!` });
        history.push(ROUTER.PRIORITY.LIST);
      }
    } catch (error) {
      notification.error({ message: `Error: ${error.message}` });
    }
  };
  return (
    <>
      <div className="flex justify-center mt-[50px]">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[500px] overflow-hidden shadow-lg bg-[#262626] rounded-[10px]"
        >
          <div className="mx-[40px] my-[30px]">
            <div className="flex flex-wrap mb-8">
              <div className="w-full px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Priority level
                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                    *
                  </span>
                </label>
                <input
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.title
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                  type="text"
                  placeholder="Priority level"
                  {...register("title", {
                    required: true,
                    pattern: validateInputName,
                  })}
                />
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.title && errors?.title.type === "required"
                      ? "Please enter priority"
                      : ""}
                    {errors?.title && errors.title.type === "pattern"
                      ? "Must not contain numbers and special characters"
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="mx-2">
                <ButtonCancel onClick={() => history.goBack()} />
              </div>
              <div className="mx-2">
                <ButtonSubmit titleButton="Confirm" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PriorityAdd;
