import React, { useEffect, useState } from "react";
import FormModal from "./../../../components/FormModal/FormModal";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getAllOrganization } from "../../Organization/OrganizationSlice";
import { getAllProduct } from "../../Product/ProductSlice";
import { getAllPriority } from "../../Priority/PrioritySlice";
import { getCustomerFilter } from "../CustomerSlice";
import Api from "./../../../Utils/Api";
import { getCustomer } from "./../CustomerSlice";
import { Select, ConfigProvider } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
const CustomerFilter = (props) => {
  const { openModal, handleCancel, setDataFilter } = props;
  const dispatch = useDispatch();
  const [idOrganization, setIdOrganization] = useState(null);
  const [valueProduct, setValueProduct] = useState([]);
  const [listDepartmentByOrganization, setListDepartmentByOrganization] =
    useState([]);
  const listAllOrganization = useSelector(
    (state) => state?.organization?.items
  );
  const listAllProduct = useSelector((state) => state?.product?.items);
  const listAllPriority = useSelector((state) => state?.priority?.items);
  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );
  const { handleSubmit, register, setValue, resetField } = useForm();
  useEffect(() => {
    dispatch(getAllOrganization());
    dispatch(getAllProduct());
    dispatch(getAllPriority());
  }, [dispatch]);

  const getDepartmentByOrganization = async (id) => {
    try {
      let response = await Api.get(`/api/department/${id}/organization`);
      if (response.status === 200) {
        setListDepartmentByOrganization(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (idOrganization !== null) {
      getDepartmentByOrganization(idOrganization);
    }
  }, [idOrganization]);
  const onSubmit = async (value) => {
    const data = {
      organizationId: value?.organizationId ? value?.organizationId : "",
      departmentId: value?.departmentId ? value?.departmentId : "",
      productId: valueProduct ? valueProduct : [],
      priorityId: value?.priorityId ? value?.priorityId : "",
    };
    setDataFilter(data);
    if (
      data.organizationId === "" &&
      data.departmentId === "" &&
      data.productId.length === 0 &&
      data.priorityId === ""
    ) {
      dispatch(getCustomer({ id: accountDetail?.id, params: null }));
    } else {
      dispatch(getCustomerFilter({ id: accountDetail?.id, data: data }));
    }
    await handleCancel();
  };

  const onHandleCancel = async () => {
    await handleCancel();
  };

  const onHandleReset = () => {
    setValueProduct([]);
    resetField("priorityId");
    resetField("organizationId");
    resetField("departmentId");
    setListDepartmentByOrganization([]);
    setDataFilter(null);
    setIdOrganization(null);
  };

  const onChangeOrganization = (value) => {
    setIdOrganization(value.target.value);
    setValue("departmentId", "", { shouldDirty: true });
  };
  const onChangeProduct = (value) => {
    setValueProduct(value);
  };
  return (
    <div>
      <FormModal
        titleModal={"Filter the data"}
        titleForm={"customerfilter"}
        visible={openModal}
        okText="Apply"
        handleCancel={onHandleCancel}
        handleReset={onHandleReset}
        width={"90%"}
        className="modal-800"
      >
        <ConfigProvider
          renderEmpty={() => (
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <InfoCircleOutlined style={{ fontSize: 30, color: "white" }} />
              <p className="text-white mt-1">Trống</p>
            </div>
          )}
        >
          <form onSubmit={handleSubmit(onSubmit)} id="customerfilter">
            <div className="flex flex-wrap mb-6">
              <div className="w-1/2 px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Filter by organization
                </label>
                <select
                  {...register("organizationId")}
                  className="text-white bg-[#414141]  border h-[40px] pl-[10px] pr-8 rounded-md w-full hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  onChange={onChangeOrganization}
                >
                  {listAllOrganization?.map((item) => {
                    return (
                      <>
                        <option value="" disabled selected hidden>
                          Choose an organization
                        </option>
                        <option key={item.id} value={item.id}>
                          {item.title}
                        </option>
                        ;
                      </>
                    );
                  })}
                </select>
              </div>
              <div className="w-1/2 px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Filter by department
                </label>
                <select
                  {...register("departmentId")}
                  className="text-white bg-[#414141]  border h-[40px] pl-[10px] pr-8 rounded-md w-full hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                >
                  {listDepartmentByOrganization &&
                    listDepartmentByOrganization.map((item) => {
                      return (
                        <>
                          <option value="" disabled selected hidden>
                            Choose a department
                          </option>
                          <option key={item.id} value={item.id}>
                            {item.title}
                          </option>
                          ;
                        </>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="flex flex-wrap mb-6 ">
              <div className="w-1/2 px-3 relative">
                <label className=" text-white text-sm font-bold mb-2">
                  Filter by product
                </label>
                <Select
                  className="ant-select-custom-muti border w-full min-h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent "
                  mode="multiple"
                  placeholder="Select product"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={valueProduct}
                  onChange={onChangeProduct}
                >
                  {listAllProduct &&
                    listAllProduct.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.title}
                        </Option>
                      );
                    })}
                </Select>
                <i className="text-white fas fa-angle-down fa-2x absolute top-[59%] right-[2%] pr-3 transform -translate-y-1/6 cursor-pointer text-xs"></i>
              </div>
              <div className="w-1/2 px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Filter the data 
                </label>
                <select
                  {...register("priorityId")}
                  className=" text-white bg-[#414141] border h-[40px] pl-[10px] pr-8 rounded-md w-full hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                >
                  {listAllPriority?.map((item) => {
                    return (
                      <>
                        <option value="" disabled selected hidden>
                         Choose priority
                        </option>
                        <option key={item.id} value={item.id}>
                          {item.title}
                        </option>
                        ;
                      </>
                    );
                  })}
                </select>
              </div>
            </div>
          </form>
        </ConfigProvider>
      </FormModal>
    </div>
  );
};

export default CustomerFilter;
