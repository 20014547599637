import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getLicenseKeyCategory = createAsyncThunk(
  "LKM/getLicenseKeyCategory",
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/licensekeycategory/get`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllLicenseKeyCategory = createAsyncThunk(
  "LKM/getAllLicenseKeyCategory",
  async () => {
    try {
      const response = await Api.get(`/api/licensekeycategory/getall`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {}
  }
);
export const deleteLicenseKeyCategory = createAsyncThunk(
  "LKM/deleteLicenseKeyCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/licensekeycategory/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addLicenseKeyCategory = createAsyncThunk(
  "LKM/addLicenseKeyCategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/licensekeycategory/create`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const updateLicenseKeyCategory = createAsyncThunk(
  "LKM/updateLicenseKeyCategory",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put(
        `/api/licensekeycategory/update/${id}`,
        data
      );
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const LicenseKeyCategorySlice = createSlice({
  name: "LicenseKeyCategory",
  initialState: {
    items: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  extraReducers: {
    [getLicenseKeyCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [getLicenseKeyCategory.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.items = data;
      state.isLoading = false;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getLicenseKeyCategory.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllLicenseKeyCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllLicenseKeyCategory.fulfilled]: (state, action) => {
      const { data } = action?.payload;
      state.items = data;
      state.isLoading = false;
    },
    [getAllLicenseKeyCategory.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteLicenseKeyCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteLicenseKeyCategory.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deleteLicenseKeyCategory.rejected]: (state) => {
      state.isLoading = false;
    },
    [addLicenseKeyCategory.pending]: (state) => {
      state.loading = true;
    },
    [addLicenseKeyCategory.fulfilled]: (state) => {
      state.loading = false;
    },
    [addLicenseKeyCategory.rejected]: (state) => {
      state.loading = false;
    },
    [updateLicenseKeyCategory.pending]: (state) => {
      state.loading = true;
    },
    [updateLicenseKeyCategory.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateLicenseKeyCategory.rejected]: (state) => {
      state.loading = false;
    },
  },
});
export default LicenseKeyCategorySlice.reducer;
