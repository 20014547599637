import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getEditionByIdProduct = createAsyncThunk(
  "LKM/getEditionByIdProduct",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/edition/${id}/product`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const deleteEdition = createAsyncThunk(
  "LKM/deleteEdition",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/edition/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addEdition = createAsyncThunk(
  "LKM/addEdition",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/edition/create`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const updateEdition = createAsyncThunk(
  "LKM/updateEdition",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/api/edition/update/${id}`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const EditionSlice = createSlice({
  name: "Edition",
  initialState: {
    items: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  extraReducers: {
    [getEditionByIdProduct.pending]: (state) => {
      state.isLoading = true;
    },
    [getEditionByIdProduct.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.items = data;
      state.isLoading = false;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getEditionByIdProduct.rejected]: (state) => {
      state.isLoading = false;
    },

    [deleteEdition.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteEdition.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deleteEdition.rejected]: (state) => {
      state.isLoading = false;
    },
    [addEdition.pending]: (state) => {
      state.isLoading = true;
    },
    [addEdition.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [addEdition.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateEdition.pending]: (state) => {
      state.isLoading = true;
    },
    [updateEdition.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [updateEdition.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});
export default EditionSlice.reducer;
