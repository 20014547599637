import React from "react";

const InputPassword = ({ children, showPassword, setShowPassWord, color }) => {
  const togglePassword = () => {
    setShowPassWord(!showPassword);
    // onTogglePass(!showPassword);
  };
  return (
    <div className="relative">
      {children}
      {showPassword ? (
        <i
          className={`text-${
            color ? color : "black"
          } far fa-eye absolute top-1/2 right-0 pr-3 transform -translate-y-1/2 cursor-pointer text-xs`}
          onClick={togglePassword}
        ></i>
      ) : (
        <i
          className={`text-${
            color ? color : "black"
          } far fa-eye-slash absolute top-1/2 right-0 pr-3 transform -translate-y-1/2 cursor-pointer text-xs`}
          onClick={togglePassword}
        ></i>
      )}
    </div>
  );
};

export default InputPassword;
