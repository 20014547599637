import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getCustomerGroup = createAsyncThunk(
  "LKM/getCustomerGroup",
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/customergroup/get`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const deleteCustomerGroup = createAsyncThunk(
  "LKM/deleteCustomerGroup",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/customergroup/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addCustomerGroup = createAsyncThunk(
  "LKM/addCustomerGroup",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/customergroup/create`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const updateCustomerGroup = createAsyncThunk(
  "LKM/updateCustomerGroup",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/api/customergroup/update/${id}`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const CustomerGroupSlice = createSlice({
  name: "CustomerGroup",
  initialState: {
    items: [],
    filters: {},
    reportList: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  extraReducers: {
    [getCustomerGroup.pending]: (state) => {
      state.isLoading = true;
    },
    [getCustomerGroup.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.items = data;
      state.isLoading = false;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getCustomerGroup.rejected]: (state) => {
      state.isLoading = false;
    },

    [deleteCustomerGroup.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteCustomerGroup.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deleteCustomerGroup.rejected]: (state) => {
      state.isLoading = false;
    },
    [addCustomerGroup.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addCustomerGroup.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [addCustomerGroup.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [updateCustomerGroup.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateCustomerGroup.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateCustomerGroup.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default CustomerGroupSlice.reducer;
