import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getSendMail = createAsyncThunk(
  "LKM/getSendMail",
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/intromail/get`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "LKM/deleteCustomer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/customer/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const sendMailIntro = createAsyncThunk(
  "LKM/sendMailIntro",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/sendmail/intromail`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const SendMailIntroSlice = createSlice({
  name: "SendMailIntro",
  initialState: {
    items: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },

  extraReducers: {
    [getSendMail.pending]: (state) => {
      state.isLoading = true;
    },
    [getSendMail.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.items = data;
      state.isLoading = false;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getSendMail.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteCustomer.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteCustomer.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deleteCustomer.rejected]: (state) => {
      state.isLoading = false;
    },
    [sendMailIntro.pending]: (state, action) => {
      state.isLoading = true;
    },
    [sendMailIntro.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [sendMailIntro.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default SendMailIntroSlice.reducer;
