import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getProduct = createAsyncThunk(
  "LKM/getProduct",
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/product/get`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getReportProduct = createAsyncThunk(
  "LKM/getReportProduct",
  async () => {
    try {
      const response = await Api.get(`/api/report/product`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {}
  }
);

export const getAllProduct = createAsyncThunk("LKM/getAllProduct", async () => {
  try {
    const response = await Api.get(`/api/product/getall`);
    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
});
export const deleteProduct = createAsyncThunk(
  "LKM/deleteProduct",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/product/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addProduct = createAsyncThunk(
  "LKM/addProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/product/create`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const updateProduct = createAsyncThunk(
  "LKM/updateProduct",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/api/product/update/${id}`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const ProductSlice = createSlice({
  name: "Product",
  initialState: {
    items: [],
    filters: {},
    reportList: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    removeFilters: (state) => {
      state.filters = {};
    },
  },
  extraReducers: {
    [getProduct.pending]: (state) => {
      state.isLoading = true;
    },
    [getProduct.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.items = data;
      state.isLoading = false;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getProduct.rejected]: (state) => {
      state.isLoading = false;
    },
    [getReportProduct.pending]: (state) => {
      state.isLoading = true;
    },
    [getReportProduct.fulfilled]: (state, action) => {
      state.reportList = action?.payload?.data;
      state.isLoading = false;
    },
    [getReportProduct.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllProduct.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllProduct.fulfilled]: (state, action) => {
      state.items = action?.payload?.data;
      state.isLoading = false;
    },
    [getAllProduct.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteProduct.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteProduct.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deleteProduct.rejected]: (state) => {
      state.isLoading = false;
    },
    [addProduct.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addProduct.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [addProduct.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [updateProduct.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateProduct.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateProduct.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});
export const { setFilters, removeFilters } = ProductSlice.actions;
export default ProductSlice.reducer;
