import React from "react";
import ROUTER from "../../routers/routerLink";

export const nav1 = [
  {
    _tag: "CSidebarNavItem",
    name: "HOME",
    to: ROUTER.HOME.HOME,
    icon: <i className="fas fa-home pr-[30px]"></i>,
  },

  {
    _tag: "CSidebarNavTitle",
    _children: ["Category"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "PRODUCT",
    to: ROUTER.PRODUCT.LIST,
    icon: (
      <i className="fas fa-copy pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "PRODUCT MANAGEMENT",
    to: ROUTER.LICENSEKEY.LIST,
    icon: <i className="fas fa-key pr-[30px]" style={{ maxWidth: "40px" }}></i>,
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["ACTIVE ACCOUNT"],
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Nhóm khách hàng gửi Email",
  //   to: ROUTER.GROUPCUSTOMER.LIST,
  //   icon: (
  //     <i
  //       className="fas fa-users-cog pr-[30px]"
  //       style={{ maxWidth: "40px" }}
  //     ></i>
  //   ),
  // },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Lịch sử gửi email hết hạn",
  //   to: "/history-send-mail-expires",
  //   icon: (
  //     <i className="fas fa-history pr-[30px]" style={{ maxWidth: "40px" }}></i>
  //   ),
  // },
  
  {
    _tag: "CSidebarNavItem",
    name: "ACCOUNT ACTIVATION",
    to: ROUTER.MAILACTIVE.LIST,
    icon: (
      <i className="fas fa-history pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Gửi email giới thiệu",
  //   to: ROUTER.LICENSEKEY_SEND_MAIL_INTRO.LICENSEKEY_SEND_MAIL_INTRO,
  //   icon: (
  //     <i className="fas fa-clock pr-[30px]" style={{ maxWidth: "40px" }}></i>
  //   ),
  // },

  {
    _tag: "CSidebarNavTitle",
    _children: ["CUSTOMER MANAGEMENT"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "CUSTOMER",
    to: ROUTER.CUSTOMER.LIST,
    icon: (
      <i className="fas fa-users pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Department",
  //   to: "/department",
  //   icon: (
  //     <i className="fas fa-building pr-[30px]" style={{ maxWidth: "40px" }}></i>
  //   ),
  // },
  {
    _tag: "CSidebarNavItem",
    name: "OGANIZATION",
    to: ROUTER.ORGANIZATION.LIST,
    icon: (
      <i className="fas fa-sitemap pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Ưu tiên",
  //   to: "/priority",
  //   icon: <i className="far fa-gem pr-[30px]" style={{ maxWidth: "40px" }}></i>,
  // },

  {
    _tag: "CSidebarNavTitle",
    _children: ["ACCOUNT MAMAGEMENT"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "ACCOUNT",
    to: ROUTER.ACCOUNT.LIST,
    icon: (
      <i
        className="fas fa-user-alt max-w-1/3 pr-[30px]"
        style={{ maxWidth: "40px" }}
      ></i>
    ),
  },
];

export const nav2 = [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: ROUTER.HOME.HOME,
    icon: <i className="fas fa-home pr-[30px]"></i>,
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Category"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Product",
    to: ROUTER.PRODUCT.LIST,
    icon: (
      <i className="fas fa-copy pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "LicenseKey",
    to: ROUTER.LICENSEKEY.LIST,
    icon: <i className="fas fa-key pr-[30px]" style={{ maxWidth: "40px" }}></i>,
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Send email"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Group of customers sending Email",
    to: ROUTER.GROUPCUSTOMER.LIST,
    icon: (
      <i
        className="fas fa-users-cog pr-[30px]"
        style={{ maxWidth: "40px" }}
      ></i>
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "Expired email sending history",
    to: ROUTER.LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES
      .LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES,
    icon: (
      <i className="fas fa-history pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Gửi email giới thiệu",
  //   to: ROUTER.LICENSEKEY_SEND_MAIL_INTRO.LICENSEKEY_SEND_MAIL_INTRO,
  //   icon: (
  //     <i className="fas fa-clock pr-[30px]" style={{ maxWidth: "40px" }}></i>
  //   ),
  // },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Customer management"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Customer",
    to: ROUTER.CUSTOMER.LIST,
    icon: (
      <i className="fas fa-users pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Department",
  //   to: "/department",
  //   icon: (
  //     <i className="fas fa-building pr-[30px]" style={{ maxWidth: "40px" }}></i>
  //   ),
  // },
  {
    _tag: "CSidebarNavItem",
    name: "Organization",
    to: ROUTER.ORGANIZATION.LIST,
    icon: (
      <i className="fas fa-sitemap pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Ưu tiên",
  //   to: "/priority",
  //   icon: <i className="far fa-gem pr-[30px]" style={{ maxWidth: "40px" }}></i>,
  // },
];
