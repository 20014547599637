import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ButtonCancel from "../../../components/Button/ButtonCancel";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import { getAllOrganization } from "../../Organization/OrganizationSlice";
import { addDepartment } from "./../DeparmentSlice";
import { notification } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import ROUTER from "../../../routers/routerLink";
const DepartmentAdd = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const listAllOrganization = useSelector((state) => state.organization.items);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getAllOrganization());
  }, [dispatch]);
  const onSubmit = async (data) => {
    try {
      const resulDispatch = await dispatch(addDepartment(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Successfully added new !!!` });
        history.push(ROUTER.DEPARTMENT.LIST);
      }
    } catch (error) {
      notification.error({ message: `Error: ${error.message}` });
    }
  };
  return (
    <>
      <div className="flex justify-center mt-[50px]">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[500px]  overflow-hidden shadow-lg bg-[#262626] rounded-[10px]"
        >
          <div className="mx-[40px] my-[30px]">
            <div className="flex flex-wrap mb-8">
              <div className="w-full">
                <label className=" text-white text-sm font-bold mb-2">
                  Department name
                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                    *
                  </span>
                </label>
                <input
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.title
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                  type="text"
                  placeholder="Department name"
                  {...register("title", {
                    required: true,
                  })}
                />
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.title && errors?.title.type === "required"
                      ? "Please enter Department name"
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mb-8">
              <div className="w-full">
                <label className=" text-white text-sm font-bold mb-2">
                  Organization
                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                    *
                  </span>
                </label>
                <select
                  {...register("organizationId", { required: true })}
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.organizationId
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                >
                  {listAllOrganization.map((item) => {
                    return (
                      <>
                        <option value="" disabled selected hidden>
                          Choose an organization
                        </option>
                        <option key={item.id} value={item.id}>
                          {item.title}
                        </option>
                      </>
                    );
                  })}
                </select>
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.organizationId &&
                    errors?.organizationId.type === "required"
                      ? "Please Choose Organization"
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mb-8">
              <div className="w-full">
                <label className=" text-white text-sm font-bold mb-2">
                  Describe
                </label>
                <textarea
                  className="bg-[#414141] pl-[10px] pt-[10px] w-full border rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  rows={5}
                  placeholder="Describe"
                  {...register("note")}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="mx-2">
                <ButtonCancel onClick={() => history.goBack()} />
              </div>
              <div className="mx-2">
                <ButtonSubmit titleButton="Confirm" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default DepartmentAdd;
