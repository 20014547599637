import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getOrganization = createAsyncThunk(
  "LKM/getOrganization",
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/organization/get`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

export const getAllOrganization = createAsyncThunk(
  "LKM/getAllOrganization",
  async () => {
    try {
      const response = await Api.get(`/api/organization/getall`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {}
  }
);

export const deleteOrganization = createAsyncThunk(
  "LKM/deleteOrganization",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/organization/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

export const addOrganization = createAsyncThunk(
  "LKM/addOrganization",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/organization/create`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const updateOrganization = createAsyncThunk(
  "LKM/updateOrganization",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/api/organization/update/${id}`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const OrganizationSlice = createSlice({
  name: "Organization",
  initialState: {
    items: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  extraReducers: {
    [getOrganization.pending]: (state) => {
      state.isLoading = true;
    },
    [getOrganization.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.items = data;
      state.isLoading = false;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getOrganization.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllOrganization.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllOrganization.fulfilled]: (state, action) => {
      state.items = action?.payload?.data;
      state.isLoading = false;
    },
    [getAllOrganization.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteOrganization.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteOrganization.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deleteOrganization.rejected]: (state) => {
      state.isLoading = false;
    },
    [addOrganization.pending]: (state) => {
      state.isLoading = true;
    },
    [addOrganization.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [addOrganization.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateOrganization.pending]: (state) => {
      state.isLoading = true;
    },
    [updateOrganization.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [updateOrganization.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});
export default OrganizationSlice.reducer;
