import React, { useEffect, useState } from "react";
import { notification, Switch } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import ButtonCancel from "../../../components/Button/ButtonCancel";
import { unwrapResult } from "@reduxjs/toolkit";
import ROUTER from "../../../routers/routerLink";
import { getAllProduct } from "../../Product/ProductSlice";
import { addEdition } from "./../EditionSlice";
import Api from "./../../../Utils/Api";

const EditionAdd = () => {
  const { idproduct } = useParams();
  const [nameProduct, setNameProduct] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const [type, setType] = useState(false);
  const [productIsHtsc, SetProductIsHtsc] = useState(false);
  const getProductById = async (id) => {
    try {
      let response = await Api.get(`api/product/getbyid/${id}`);
      if (response.status === 200) {
        SetProductIsHtsc(response?.data?.data?.isHTSC);
        setNameProduct(response?.data?.data?.title);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (idproduct) {
      getProductById(idproduct);
    }
  }, [dispatch, idproduct]);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    dispatch(getAllProduct());
  }, [dispatch]);
  const onSubmit = async (value) => {
    const data = {
      type: productIsHtsc === true ? type : "",
      productId: idproduct,
      title: value?.title?.trim(),
      description: value?.description?.trim() || "",
    };
    try {
      const resulDispatch = await dispatch(addEdition(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Successfully added new !!!` });
        history.push(`${ROUTER.PRODUCT.EDITION.LIST}/${idproduct}`);
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };

  return (
    <>
      <div className="flex justify-center mb-10">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[1000px] overflow-hidden shadow-lg bg-[#262626] rounded-[10px]"
        >
          <div className="mx-[40px] my-[30px]">
            <div className="flex flex-wrap mb-3">
              <div className="w-full md:w-1/2 px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Editon
                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                    *
                  </span>
                </label>

                <input
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.title
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                  type="text"
                  placeholder="Editon"
                  {...register("title", {
                    required: true,
                  })}
                />
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.title && errors?.title.type === "required"
                      ? "Please enter edition"
                      : ""}
                  </span>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className=" text-white text-sm font-bold mb-2">
                  Product
                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                    *
                  </span>
                </label>
                <input
                  className="text-gray-600 bg-gray-300 border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  type="text"
                  value={nameProduct}
                  disabled
                />
              </div>
            </div>
            {productIsHtsc && (
              <div className="flex flex-wrap mb-3">
                <div className="w-full md:w-1/2 px-3 ">
                  <label className=" w-full text-white text-sm font-bold mb-2">
                    Type
                    <span className="text-red-600 font-extrabold text-[17px] ml-1">
                      *
                    </span>
                  </label>
                  <Switch
                    checkedChildren="Online"
                    unCheckedChildren="Offline"
                    checked={type}
                    onChange={(value) => setType(value)}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-wrap mb-8">
              <div className="w-full px-3">
                <label className=" text-white text-sm font-bold mb-2">
                  Describe
                </label>
                <textarea
                  className="bg-[#414141] pl-[10px] pt-[10px] w-full border rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  rows={5}
                  placeholder="Describe"
                  {...register("description")}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="mx-2">
                <ButtonCancel onClick={() => history.goBack()} />
              </div>
              <div className="mx-2">
                <ButtonSubmit titleButton="Confirm" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditionAdd;
