import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getDepartment = createAsyncThunk(
  "LKM/getDepartment",
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/department/get`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const deleteDepartment = createAsyncThunk(
  "LKM/deleteDepartment",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/department/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addDepartment = createAsyncThunk(
  "LKM/addDepartment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/department/create`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const updateDepartment = createAsyncThunk(
  "LKM/updateDepartment",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/api/department/update/${id}`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const DepartmentSlice = createSlice({
  name: "Department",
  initialState: {
    items: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  extraReducers: {
    [getDepartment.pending]: (state) => {
      state.isLoading = true;
    },
    [getDepartment.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.items = data;
      state.isLoading = false;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getDepartment.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteDepartment.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteDepartment.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deleteDepartment.rejected]: (state) => {
      state.isLoading = false;
    },
    [addDepartment.pending]: (state, action) => {
      state.loading = true;
    },
    [addDepartment.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [addDepartment.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateDepartment.pending]: (state, action) => {
      state.loading = true;
    },
    [updateDepartment.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateDepartment.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});
export default DepartmentSlice.reducer;
