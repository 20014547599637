import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import RenderStatusExpiry from "./../../components/RenderStatusExpiry/RenderStatusExpiry";
import ButtonSubmit from "../../components/Button/ButtonSubmit";
import ROUTER from "../../routers/routerLink";
import TableAdmin from "./../../components/Table/TableAdmin";
import { notification, Tooltip, Form, Popconfirm, Switch, Space, Modal } from "antd";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getEmailActiveList, lockAndUnLockAccount, sendMailToActiveState } from "./MailActiveSlice";
import queryString from "query-string";
import { SendOutlined } from "@ant-design/icons";
import { current, unwrapResult } from "@reduxjs/toolkit";

const MailActiveList = (props) => {
    const [valueSearch, setValueSearch] = useState("");
    const history = useHistory();
    const search = useLocation().search;
    const [showFilter, setShowFilter] = useState(false);
    const dispatch = useDispatch();
    const emailActive = useSelector((state) => state?.mailActive?.items);
    const meta = useSelector((state) => state?.mailActive?.meta);
    const filters = useSelector((state) => state?.mailActive?.filters);
    const isLoading = useSelector((state) => state?.mailActive?.isLoading);
    const [modal, contextHolder] = Modal.useModal();
    console.log(emailActive)
    const columns = [
        {
          dataIndex: "email",
          title: "Email",
          sorting: true,
          type: "left",
          render: (value) => (
            <span className="font-medium">{value}</span>
          ),
        },
        {
          dataIndex: "userName",
          title: "Account",
          sorting: true,
          type: "left",
          render: (value) => (
            <span className="font-medium">{value ? value : "N/A"}</span>
          ),
        },
        {
          dataIndex: "productId",
          title: "Product",
          sorting: true,
          type: "left",
        },
        {
          title: "Status",
          sorting: true,
          type: "center",
          // dataIndex: "status",
          render: (record) => {
            return (
                <>
                  {/* <div className={value === true ? "using": "expiration-date"}>{ value === true ? `Đã xử lý` : `Chưa xử lý` }</div> */}
              
                  <Space>
                    <Switch style={{backgroundColor: 'orange'}} checkedChildren="Active" unCheckedChildren="Deactive" checked={record.status} onChange={(value) => deactivateUser(value,record)} />
                  </Space>
                </>
              );
          },
        },
        {
          title: "Function",
          sorting: true,
          type: "center",
          render: (value) => {
            <Switch />
            if(!value.isActive){
                return (
                  <>
                  <Tooltip placement="topLeft" title="Send email"  >
                    <SendOutlined onClick={() => sendMailToActive(value)} disabled={value.status}/>
                  </Tooltip>
                  </>
                )
            }
            return (
              <>
              <div>Activated successfully</div>
              </>
            )
          } 
        },
      ];
     const deactivateUser =  (value, record) => {
        console.log(record);
        Modal.confirm({title: record.status ? 'Do you want to deactivate the account?' : 'Do you want to open an active account?', onOk: async () => {
          try{
            let payload ={
              email: record.email,
              userName: record.userName,
            }
            const resulDispatch = await dispatch(lockAndUnLockAccount(payload));
            unwrapResult(resulDispatch);
            if (resulDispatch.payload.success === true) {
              notification.success({
                message: resulDispatch.payload.message,
              });
            }
          } catch (error) {
            if (error.message === "Rejected") {
              notification.error({
                message: `Error: Please check your network connection again!`,
              });
            } else {
              notification.error({ message: `Error: ${error.message}` });
            }
          }
        }})
     }

      const sendMailToActive = async(record) => {
        try{
          let payload ={
            email: record.email,
            userName: record.userName,
            token: record.token
          }
          const resulDispatch = await dispatch(sendMailToActiveState(payload));
          unwrapResult(resulDispatch);
          if (resulDispatch.payload.success === true) {
            notification.success({
              message: resulDispatch.payload.message,
            });
          }
        } catch (error) {
          if (error.message === "Rejected") {
            notification.error({
              message: `Error: Please check your network connection again!`,
            });
          } else {
            notification.error({ message: `Error: ${error.message}` });
          }
        }
      }


      const onDelete = async (id) => {
        try {
         
        } catch (error) {
          if (error.message === "Rejected") {
            notification.error({
              message: `Error: Please check your network connection again!`,
            });
          } else {
            notification.error({ message: `Error: ${error.message}` });
          }
        }
      };
      const onChangePage = (page, size) => {
        // const { search } = props.history.location;
        // if (search !== "") {
        //   let params;
        //   const filterOptions = search.split("?")[1];
        //   if (filterOptions !== undefined) {
        //     params = `{"${decodeURI(filterOptions)
        //       .replace(/"/g, '\\"')
        //       .replace(/&/g, '","')
        //       .replace(/=/g, '":"')}"}`;
        //   }
        //   const obj = JSON.parse(params);
        //   params = { ...obj, page, size };
        //   const urlParameters = [];
        //   Object.entries(params).forEach((e) => {
        //     if (e[1] !== undefined && e[1] !== "") {
        //       urlParameters.push(e.join("="));
        //     }
        //   });
        //   props.history.push(
        //     ROUTER.LICENSEKEY.LIST.concat(`?${urlParameters.join("&")}`)
        //   );
        // } else {
        //   props.history.push(
        //     ROUTER.LICENSEKEY.LIST.concat(`?page=${page}&size=${size}`)
        //   );
        // }
        // dispatch(setFilters({ page: page, size: size }));
      };

      useEffect(() => {
        dispatch(getEmailActiveList(queryString.parse(search)));
      }, [dispatch, search]);

  return (
    <>
      <div className="overflow-x-auto mb-10 mt-4">
        <div className="flex justify-center mb-3 bg-[#262626] px-4 py-8 rounded-[10px]">
          <div className="w-full">
            <div className="w-full md:flex md:justify-between h-[40px] mb-3">
            </div>
            <div className="">
              <TableAdmin
                 downloadTxt
                 Url={ROUTER.MAILACTIVE.LIST}
                onDelete={onDelete}
                columns={columns}
                spinning={isLoading}
                dataTable={emailActive}
                meta={meta}
                hiddenAction={true}
                onChangePage={onChangePage}
                handlePagination={(v) =>
                onChangePage(v?.currentPage, v?.itemsPerPage)
                }
                totalItems={meta?.total}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MailActiveList;
