import React, { useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Modal, Tooltip, Button, Input, Form, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  updateConfigurationEmail,
  getConfigurationEmail,
} from "./../ConfigSendMailLicenseKeySlice";
import { validateEmail } from "../../../../Utils/Validate/validateInput";
import { validateNumber } from "./../../../../Utils/Validate/validateInput";

const ModalConfigurationEmail = ({
  modalConfigurationEmail,
  setModalConfigurationEmail,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const infoConfigurationEmail = useSelector(
    (state) => state?.configSendMail?.configurationEmail
  );

  useEffect(() => {
    if (infoConfigurationEmail) {
      form.setFieldsValue({
        email: infoConfigurationEmail.email,
        // password: infoConfigurationEmail.password,
        mailcatcher_HOST_NAME: infoConfigurationEmail.mailcatcher_HOST_NAME,
        mailcatcher_SSL_PORT: infoConfigurationEmail.mailcatcher_SSL_PORT,
        tsl_PORT: infoConfigurationEmail.tsl_PORT,
        host_NAME: infoConfigurationEmail.host_NAME,
        ssl_PORT: infoConfigurationEmail.ssl_PORT,
      });
    }
  }, [infoConfigurationEmail]);

  useEffect(() => {
    dispatch(getConfigurationEmail());
  }, []);

  const handleCancleModalConfigurationEmail = () => {
    setModalConfigurationEmail(false);
    dispatch(getConfigurationEmail());
  };
  const onFinish = async (value) => {
    const data = {
      email: value?.email,
      password: value?.password
        ? value?.password
        : infoConfigurationEmail.password,
      mailcatcher_HOST_NAME: value?.mailcatcher_HOST_NAME,
      mailcatcher_SSL_PORT: value?.mailcatcher_SSL_PORT,
      tsl_PORT: value?.tsl_PORT,
      host_NAME: value?.host_NAME,
      ssl_PORT: value?.ssl_PORT,
    };
    try {
      const resulDispatch = await dispatch(updateConfigurationEmail(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({
          message: `Email configuration update successful!`,
        });
        handleCancleModalConfigurationEmail();
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };
  return (
    <Modal
      title="Email sending configuration"
      visible={modalConfigurationEmail}
      onCancel={() => {
        handleCancleModalConfigurationEmail();
        form.resetFields();
      }}
      width={"70%"}
      className="overflow-x-auto"
      centered
      destroyOnClose={true}
      footer={[
        <Button
          onClick={() => {
            handleCancleModalConfigurationEmail();
            form.resetFields();
          }}
        >
          Exit
        </Button>,
        <Button
          className="min-w-[80px]"
          form={`ConfigurationEmail`}
          type="primary"
          htmlType="submit"
        >
          Lưu
        </Button>,
      ]}
      closeIcon={
        <Tooltip title="Close">
          <CloseOutlined />
        </Tooltip>
      }
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        id={`ConfigurationEmail`}
      >
        <div className="flex flex-wrap">
          <Form.Item
            name="email"
            className="w-full md:w-1/2 px-3"
            label="Email"
            rules={[
              {
                required: true,
                message: "Can not be empty!",
              },
              {
                pattern: validateEmail,
                message: "Invalid email",
              },
            ]}
          >
            <Input
              className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            className="w-full md:w-1/2 px-3"
            label="Password"
          >
            <Input
              className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
              placeholder="Password"
            />
          </Form.Item>
        </div>
        <div className="flex flex-wrap">
          <Form.Item
            name="ssl_PORT"
            className="w-full md:w-1/2 px-3"
            label="ssl_PORT"
            rules={[
              {
                required: true,
                message: "Can not be empty!",
              },
              {
                pattern: validateNumber,
                message: "Please enter digits",
              },
            ]}
          >
            <Input
              className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
              placeholder="ssl_PORT"
            />
          </Form.Item>

          <Form.Item
            name="host_NAME"
            className="w-full md:w-1/2 px-3"
            label="host_NAME"
            rules={[
              {
                required: true,
                message: "Can not be empty!",
              },
            ]}
          >
            <Input
              className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
              placeholder="host_NAME"
            />
          </Form.Item>
        </div>
        <div className="flex flex-wrap">
          <Form.Item
            name="mailcatcher_HOST_NAME"
            className="w-full md:w-1/2 px-3"
            label="mailcatcher_HOST_NAME"
          >
            <Input
              className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
              placeholder="mailcatcher_HOST_NAME"
            />
          </Form.Item>
          <Form.Item
            name="mailcatcher_SSL_PORT"
            className="w-full md:w-1/2 px-3"
            label="mailcatcher_SSL_PORT"
          >
            <Input
              className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
              placeholder="mailcatcher_SSL_PORT"
            />
          </Form.Item>
        </div>

        <div className="flex flex-wrap">
          <Form.Item
            name="tsl_PORT"
            className="w-full md:w-1/2 px-3"
            label="tsl_PORT"
          >
            <Input
              className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
              placeholder="tsl_PORT"
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalConfigurationEmail;
