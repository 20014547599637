import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "../../../assets/css/login.css";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "./LoginSlice";
import { useHistory } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification, Spin } from "antd";
import { setUserInfo } from "./../../../Utils/localStorage/SetUserInfo";
import logo from "../../../assets/image/logo.png";
import InputPassword from "./../../../components/InputPassword/inputPassword";
import { validateEmail } from "../../../Utils/Validate/validateInput";
import ROUTER from "../../../routers/routerLink";
import { useTranslation } from "react-i18next";

const Login = () => {
  const [showPassword, setShowPassWord] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.loginUser);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [t] = useTranslation()
  const onSubmit = async (value) => {
    try {
      const resultApi = await dispatch(loginUser(value));
      unwrapResult(resultApi);
      if (resultApi?.payload?.success === true) {
        setUserInfo("token", resultApi.payload.data.accessToken);
        notification.success({ message: `Logged in successfully` });
        history.push("/");
      } else {
      }
    } catch (error) {
      if (error?.error === "User is disabled") {
        notification.error({
          message: `Error: Account not activated. Please contact ADMIN for support`,
        });
      } else if (error?.error === "Bad credentials") {
        notification.error({
          message: `Error: Email or password is incorrect!`,
        });
      } else {
        notification.error({
          message: `Error: Please check your network connection!`,
        });
      }
    }
  };
  return (
    <>
      <div
        className="login-box border-2 border-gray-500"
        style={{ backgroundColor: "rgb(220,220,220,0.1)" }}
      >
        <div className="flex justify-center mb-6 ">
          <img className=" w-[200px] h-[80px] " src={logo} alt="" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="h-2 w-full mb-2 flex justify-center">
            <Spin spinning={isLoading}></Spin>
          </div>
          <div className="">
            <label className=" text-gray-200 text-sm font-bold mb-2">
              Email
            </label>
            <input
              className="border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
              type="text"
              placeholder="Email"
              {...register("username", {
                required: true,
                pattern: validateEmail,
              })}
            />
            <div className="mt-1 h-[10px] ">
              <span className="text-red-500">
                {errors?.username && errors?.username.type === "required"
                  ? "Please enter your email"
                  : ""}
                {errors?.username && errors.username.type === "pattern"
                  ? "Invalid email format"
                  : ""}
              </span>
            </div>
          </div>
          <div className="mt-3">
            <label className=" text-gray-200 text-sm font-bold mb-2">
              Password
            </label>
            <InputPassword
              showPassword={showPassword}
              setShowPassWord={setShowPassWord}
            >
              <input
                className="border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                {...register("password", { required: true })}
              />
            </InputPassword>

            <div className="mt-1 h-[10px]">
              <span className="text-red-500">
                {errors?.password && errors?.password.type === "required"
                  ? "Please enter password"
                  : ""}
              </span>
            </div>
          </div>
          <div className="flex justify-center">
            <button disabled={isLoading} href="#">
              {isLoading ? "Loading..." : "Log in"}
              {isLoading ? (
                <>
                  <span className="span1" />
                  <span className="span2" />
                  <span className="span3" />
                  <span className="span4" />{" "}
                </>
              ) : (
                ""
              )}
            </button>
          </div>
        </form>
        <div className="pt-3 text-center">
          <a
            href={ROUTER.AUTH.FORGOTPASSWORD}
            className="font-semibold text-[#1877f2] hover:text-blue-300"
          >
            Forgot password?
          </a>
        </div>
      </div>
    </>
  );
};

export default Login;
