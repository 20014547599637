import React, { useEffect } from "react";
import { Modal, notification, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import {
  deleteLicenseKeyByCustomer,
  getListLicensekeyByCustomer,
} from "./../CustomerSlice";
import RenderStatusExpiry from "../../../components/RenderStatusExpiry/RenderStatusExpiry";
import TableAdmin from "../../../components/Table/TableAdmin";
import ROUTER from "../../../routers/routerLink";
import { unwrapResult } from "@reduxjs/toolkit";

const LicenseKeyByCustomer = ({ handleCancel, openModal, idCustomer }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state?.customer?.isLoading);
  const listLicensekeyByCustomer = useSelector(
    (state) => state?.customer?.itemsLicenseKey
  );
  const metaLicensekey = useSelector(
    (state) => state?.customer?.itemsLicenseKey
  );

  useEffect(() => {
    if (idCustomer) {
      dispatch(getListLicensekeyByCustomer({ id: idCustomer, params: null }));
    }
  }, [dispatch, idCustomer]);
  const columns = [
    // {
    //   dataIndex: "macAddress",
    //   title: "MAC",
    //   sorting: true,
    //   type: "center",
    //   render: (value) => (
    //     <span className="font-medium">{value !== null ? value : "N/A"}</span>
    //   ),
    // },
    // {
    //   dataIndex: "email",
    //   title: "Email",
    //   sorting: true,
    //   type: "left",
    //   render: (value) => (
    //     <span className="font-medium">{value ? value : "N/A"}</span>
    //   ),
    // },
    {
      dataIndex: "productName",
      title: "Product",
      sorting: true,
      type: "center",
    },
    {
      dataIndex: "versionName",
      title: "Version",
      sorting: true,
      type: "center",
    },
    {
      dataIndex: "licenseType",
      title: "Type",
      sorting: true,
      type: "center",
      render: (value) => (
        <span className="font-medium">
          {value === false ? "Online" : value === true ? "Offline" : "N/A"}
        </span>
      ),
    },
    {
      dataIndex: "licenseKeyCategoryName",
      title: "Category",
      sorting: true,
      type: "center",
    },
    {
      dataIndex: "startDate",
      title: "Activation date",
      sorting: true,
      type: "center",
    },
    {
      dataIndex: "expirationDate",
      title: "Expiration date",
      sorting: true,
      type: "center",
    },
    {
      title: "Status",
      sorting: true,
      type: "center",
      render: (value) => RenderStatusExpiry(value?.expirationDate),
    },
    // {
    //   dataIndex: "note",
    //   title: "Note",
    //   sorting: true,
    //   type: "left",
    //   render: (value) =>
    //     value.length >= 20 ? (
    //       <Popover
    //         content={
    //           <>
    //             <div className="max-w-[250px] break-words">
    //               <h1 className="border-b-2 text-xl font-semibold">Note </h1>
    //               <p> {value}</p>
    //             </div>
    //           </>
    //         }
    //       >
    //         <span className="font-medium">
    //           {value.substring(0, 20) + " ..."}
    //         </span>
    //       </Popover>
    //     ) : (
    //       <span className="font-medium">{value}</span>
    //     ),
    // },
  ];
  const onDelete = async (id) => {
    try {
      const resultAction = await dispatch(deleteLicenseKeyByCustomer(id));
      unwrapResult(resultAction);
      notification.success({ message: `Delete successfully!` });
    } catch (error) {
      notification.error({ message: `Error: ${error.message}` });
    }
  };
  const onChangePage = (page, size) => {
    dispatch(
      getListLicensekeyByCustomer({
        id: idCustomer,
        params: { page: `${page}`, size: `${size}` },
      })
    );
  };
  return (
    <>
      <Modal
        title="LicenseKey"
        visible={openModal}
        onOk={handleCancel}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: false }}
        cancelText="Exit"
        width={"100%"}
        className="modal-1100"
        centered
        destroyOnClose={true}
        closeIcon={
          <Tooltip title="Close">
            <CloseOutlined />
          </Tooltip>
        }
      >
        <TableAdmin
          Url={ROUTER.LICENSEKEY.EDIT}
          onDelete={onDelete}
          columns={columns}
          spinning={isLoading}
          dataTable={listLicensekeyByCustomer}
          meta={metaLicensekey}
          onChangePage={onChangePage}
        />
      </Modal>
    </>
  );
};

export default LicenseKeyByCustomer;
