import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getCustomer = createAsyncThunk(
  "LKM/getCustomer",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/customer/${id}/get`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getCustomerFilter = createAsyncThunk(
  "LKM/getCustomerFilter",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/customer/${id}/get`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllCustomer = createAsyncThunk(
  "LKM/getAllCustomer",
  async () => {
    try {
      const response = await Api.get(`/api/customer/getall`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {}
  }
);

export const deleteCustomer = createAsyncThunk(
  "LKM/deleteCustomer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/customer/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addCustomer = createAsyncThunk(
  "LKM/addCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/customer/create`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "LKM/updateCustomer",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/api/customer/update/${id}`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
//list lkicenseKye
export const getListLicensekeyByCustomer = createAsyncThunk(
  "LKM/getListLicensekeyByCustomer",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await Api.get(`api/licensekey/${id}/customer`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const deleteLicenseKeyByCustomer = createAsyncThunk(
  "LKM/deleteLicenseKeyByCustomer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/licensekey/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const CustomerSlice = createSlice({
  name: "Customer",
  initialState: {
    items: [],
    filters: {},
    isFilter: false,
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    itemsLicenseKey: [],
    metaLicenseKey: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    removeFilters: (state) => {
      state.filters = {};
    },
  },
  extraReducers: {
    [getCustomer.pending]: (state) => {
      state.isLoading = true;
      state.isFilter = false;
    },
    [getCustomer.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.items = data;
      state.isLoading = false;
      state.isFilter = false;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getCustomer.rejected]: (state) => {
      state.isLoading = false;
      state.isFilter = false;
    },
    [getCustomerFilter.pending]: (state) => {
      state.isLoading = true;
      state.isFilter = true;
    },
    [getCustomerFilter.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.items = data;
      state.isLoading = false;
      state.isFilter = true;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getCustomerFilter.rejected]: (state) => {
      state.isLoading = false;
      state.isFilter = true;
    },
    [getAllCustomer.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllCustomer.fulfilled]: (state, action) => {
      state.items = action?.payload?.data;
      state.isLoading = false;
    },
    [getAllCustomer.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteCustomer.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteCustomer.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deleteCustomer.rejected]: (state) => {
      state.isLoading = false;
    },
    [addCustomer.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addCustomer.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [addCustomer.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [updateCustomer.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateCustomer.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateCustomer.rejected]: (state, action) => {
      state.isLoading = false;
    },
    //
    [getListLicensekeyByCustomer.pending]: (state) => {
      state.isLoading = true;
    },
    [getListLicensekeyByCustomer.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.itemsLicenseKey = data;
      state.isLoading = false;
      state.metaLicenseKey = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getListLicensekeyByCustomer.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteLicenseKeyByCustomer.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteLicenseKeyByCustomer.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.itemsLicenseKey = state.itemsLicenseKey.filter(
          (item) => item.id !== action?.meta?.arg
        );
      }
      state.isLoading = false;
    },
    [deleteLicenseKeyByCustomer.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setFilters, removeFilters } = CustomerSlice.actions;
export default CustomerSlice.reducer;
