import React from "react";

const InputSearch = ({ onChange, placeholder, valueSearch }) => {
  return (
    <>
      <div className="relative flex justify-center rounded-md border-[1px] border-white">
        <i className="absolute top-3 right-3 fas fa-search text-white"></i>
        <input
          type="search"
          placeholder={placeholder ? placeholder : "Search..."}
          className="overflow-x-hidden bg-[#262626] shadow-md w-[230px] sm:w-[300px] h-full block rounded-md border-0 hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-grow p-[9px] text-white"
          onChange={onChange}
          value={valueSearch}
        />
      </div>
    </>
  );
};

export default InputSearch;
