import React, { useEffect,useState } from "react";
import { ConfigProvider } from 'antd';
import "./App.css";
import "../src/assets/scss/style.scss";
import TheLayout from "./components/MainLayout/TheLayout";
import { Route, Redirect, Switch } from "react-router-dom";
import Login from "./pages/Auth/Login/Login";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { logoutUser } from "./pages/Auth/Login/LoginSlice";
import ROUTER from "./routers/routerLink";
import { I18nextProvider } from "react-i18next";
import i18nConfig from './Utils/i18n-config'
import vi_VN from 'antd/lib/locale/vi_VN';
import en_US from 'antd/lib/locale/en_US';
import fr_FR from 'antd/lib/locale/fr_FR';
import ja_JP from 'antd/lib/locale/ja_JP';
import ko_KR from 'antd/lib/locale/ko_KR';
import zh_CN from 'antd/lib/locale/zh_CN';
import de_DE from 'antd/lib/locale/de_DE';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/vi';
import 'moment/locale/fr';
import 'moment/locale/ko';
import 'moment/locale/ja'

const jwtSecret = process.env.JWT_SECRET
  ? process.env.JWT_SECRET
  : "license_plate_hti";
function App() {
  const dispatch = useDispatch();

  const [selectLanguage, setSelectLanguage] = useState(en_US);
  const checkAuth = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logoutUser());
      return false;
    }
    try {
      const { exp } = decode(token, jwtSecret);
      if (exp < parseInt(new Date().getTime() / 1000)) {
        window.location.href = "/login";
        dispatch(logoutUser());
        return false;
      } else {
        return true;
      }
    } catch (error) {
      dispatch(logoutUser());
      return false;
    }
  };

  let fallbackLanguage = localStorage.getItem("selectedLanguage");

  useEffect(() => {
    if (fallbackLanguage) {
      getLanguage(fallbackLanguage)
    }
  }, [fallbackLanguage])

  const getLanguage = (value) => {
    switch (value) {
      case 'vi': return setSelectLanguage(vi_VN), moment.locale('vi');
      case 'en': return setSelectLanguage(en_US), moment.locale('en');
      case 'fe': return setSelectLanguage(fr_FR), moment.locale('fr');
      case 'ja': return setSelectLanguage(ja_JP), moment.locale('ja');
      case 'ko': return setSelectLanguage(ko_KR), moment.locale('ko');
      case 'ch': return setSelectLanguage(zh_CN), moment.locale('zh_cn');
      case 'ger': return setSelectLanguage(de_DE), moment.locale('de_DE');
      default: return setSelectLanguage(en_US), moment.locale('en');
    }
  }

  return (
    <ConfigProvider locale={selectLanguage}>
      <I18nextProvider i18n={i18nConfig}>
        <Switch>
          <Route path={ROUTER.AUTH.LOGIN} component={Login} exact />
          <Route
            path={ROUTER.AUTH.FORGOTPASSWORD}
            component={ForgotPassword}
            exact
          />
          <Route
            path={ROUTER.HOME.HOME}
            render={(props) =>
              checkAuth() ? (
                <TheLayout />
              ) : (
                <Redirect
                  to={{
                    pathname: ROUTER.AUTH.LOGIN,
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
        </Switch>
      </I18nextProvider>s
    </ConfigProvider>
  );
}

export default App;
