import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getLicenseKeyCategoryChart = createAsyncThunk(
  "LKM/getLicenseKeyCategoryChart",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await Api.get(`api/licensekey/${id}/licensecategory`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const deleteLicenseKeyCategoryChart = createAsyncThunk(
  "LKM/deleteLicenseKeyCategoryChart",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/licensekey/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const LicenseKeyCategoryChartSlice = createSlice({
  name: "LicenseKeyCategoryChart",
  initialState: {
    items: [],
    filters: {},
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    removeFilters: (state) => {
      state.filters = {};
    },
  },
  extraReducers: {
    [getLicenseKeyCategoryChart.pending]: (state) => {
      state.isLoading = true;
    },
    [getLicenseKeyCategoryChart.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.isLoading = false;
      state.items = data;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getLicenseKeyCategoryChart.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteLicenseKeyCategoryChart.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteLicenseKeyCategoryChart.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deleteLicenseKeyCategoryChart.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setFilters, removeFilters } =
  LicenseKeyCategoryChartSlice.actions;
export default LicenseKeyCategoryChartSlice.reducer;
