import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Switch, notification, Select, Checkbox } from "antd";
import ButtonCancel from "./../../../components/Button/ButtonCancel";
import ButtonSubmit from "./../../../components/Button/ButtonSubmit";
import { useHistory } from "react-router-dom";
import { getAllOrganization } from "../../Organization/OrganizationSlice";
import { getAllProduct } from "../../Product/ProductSlice";
import { getAllPriority } from "../../Priority/PrioritySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import ROUTER from "../../../routers/routerLink";
import { addCustomer } from "./../CustomerSlice";
import {
  validateEmail,
  validateNumber,
  validatePhoneNumber,
} from "../../../Utils/Validate/validateInput";
import Api from "../../../Utils/Api";
import Creatable from "react-select/creatable";
import { addOrganization } from "../../Organization/OrganizationSlice";
import { addDepartment } from "./../../Department/DeparmentSlice";

const { Option } = Select;
const CustomerAdd = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [idOrganization, setIdOrganization] = useState(null);
  const [iddDepartment, setIdDepartment] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [valueProduct, setValueProduct] = useState([]);
  const [listDepartmentByOrganization, setListDepartmentByOrganization] =
    useState([]);
  const [listOptionOrganization, setListOptionOrganization] = useState([]);
  const [listAllCustomergroup, setListAllCustomergroup] = useState([]);
  const [checkboxSendMail, setCheckboxSendMail] = useState(true);
  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );
  const listAllOrganization = useSelector(
    (state) => state?.organization?.items
  );
  const listAllProduct = useSelector((state) => state?.product?.items);
  const listAllPriority = useSelector((state) => state?.priority?.items);
  const {
    handleSubmit,
    register,
    // setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getAllOrganization());
    dispatch(getAllProduct());
    dispatch(getAllPriority());
  }, [dispatch]);

  useEffect(() => {
    const listOption = [];
    if (listAllOrganization) {
      for (let i = 0; i < listAllOrganization.length; i++) {
        listOption.push({
          label: `${listAllOrganization[i]?.title}`,
          value: listAllOrganization[i]?.id,
        });
      }
      setListOptionOrganization(listOption);
    }
  }, [listAllOrganization]);

  const getDepartmentByOrganization = async (id) => {
    try {
      let response = await Api.get(`/api/department/${id}/organization`);
      const listOption = [];
      if (response.status === 200) {
        const dataRes = response?.data?.data;

        for (let i = 0; i < dataRes.length; i++) {
          listOption.push({
            label: `${dataRes[i]?.title}`,
            value: dataRes[i]?.id,
          });
        }
        setListDepartmentByOrganization(listOption);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (idOrganization !== null) {
      getDepartmentByOrganization(idOrganization?.value);
    }
  }, [idOrganization]);

  const getAllCustomergroup = async () => {
    try {
      const { data } = await Api.get(`/api/customergroup/getall`);
      if (data?.success === true) {
        setListAllCustomergroup(data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAllCustomergroup();
  }, []);

  const onSubmit = async (value) => {
    const data = {
      name: value?.name?.trim(),
      email: value?.email,
      note: value?.note || "",
      customerGroupId: value?.customerGroupId || "",
      organizationId: idOrganization?.value,
      departmentId: iddDepartment?.value || "",
      phone: value?.phone || null,
      workPhone: value?.workPhone || null,
      priorityId: value?.priorityId,
      productId: valueProduct,
      isPrivate,
      roleCode: accountDetail.roleCode,
      createdBy: accountDetail.id,
      isReceiveMail: checkboxSendMail,
    };
    try {
      const resulDispatch = await dispatch(addCustomer(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Successfully added new !!!` });
        history.push(ROUTER.CUSTOMER.LIST);
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };
  const onChangeOrganization = (value) => {
    setIdOrganization(value);
    setIdDepartment(null);
  };
  const onCreateOptionOrganization = async (value) => {
    const data = {
      title: value.trim(),
      note: "",
    };
    try {
      const resulDispatch = await dispatch(addOrganization(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Add new successful organization !!!` });
        dispatch(getAllOrganization());
        setIdOrganization({
          value: resulDispatch?.payload?.data?.id,
          label: resulDispatch?.payload?.data?.title,
        });
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };
  const onChangeDepartment = (value) => {
    setIdDepartment(value);
  };
  const onCreateOptionDepartment = async (value) => {
    const data = {
      title: value.trim(),
      organizationId: idOrganization?.value,
      note: "",
    };
    try {
      const resulDispatch = await dispatch(addDepartment(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({
          message: `Add new department of "${idOrganization?.label}" success !!!`,
        });
        getDepartmentByOrganization(
          resulDispatch?.payload?.data?.organizationId
        );
        setIdDepartment({
          value: resulDispatch?.payload?.data?.id,
          label: resulDispatch?.payload?.data?.title,
        });
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };
  const onChangeProduct = (value) => {
    setValueProduct(value);
  };
  return (
    <>
      <div className="flex justify-center mb-10">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[1200px] overflow-hidden shadow-lg bg-[#262626] mb-12 rounded-[10px]"
        >
          <div className="mx-[40px] my-[30px]">
            <div className="flex flex-wrap mb-3">
              <div className="w-full md:w-1/2 px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Full name
                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                    *
                  </span>
                </label>
                <input
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.name
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                  type="text"
                  placeholder="Full name"
                  {...register("name", {
                    required: true,
                    // pattern: validateInputName,
                  })}
                />
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.name && errors?.name.type === "required"
                      ? "Please enter your full name"
                      : ""}
                    {errors?.name && errors.name.type === "pattern"
                      ? "Must not contain numbers and special characters"
                      : ""}
                  </span>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className=" text-white text-sm font-bold mb-2">
                  Email
                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                    *
                  </span>
                </label>
                <input
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.email
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                  // type="email"
                  placeholder="Email"
                  {...register("email", {
                    required: true,
                    pattern: validateEmail,
                  })}
                />
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.email && errors?.email.type === "required"
                      ? "Please enter your email address"
                      : ""}
                    {errors?.email && errors.email.type === "pattern"
                      ? "Định dạng Email không hợp lệ"
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mb-3">
              <div className="w-full md:w-1/2 px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Phone
                </label>
                <input
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.phone
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                  type="text"
                  placeholder="Phone"
                  {...register("phone", {
                    pattern: validatePhoneNumber,
                  })}
                />
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.phone && errors.phone.type === "pattern"
                      ? "Invalid phone number"
                      : ""}
                  </span>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Office phone number
                </label>
                <input
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.workPhone
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                  type="text"
                  placeholder="Office phone number"
                  {...register("workPhone", {
                    pattern: validateNumber,
                  })}
                />
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.workPhone && errors.workPhone.type === "pattern"
                      ? "Invalid phone number"
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mb-3">
              <div className="w-full md:w-1/2 px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Organization
                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                    *
                  </span>
                </label>
                {/* <select
                  {...register("organizationId")}
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.organizationId
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                  onChange={onChangeOrganization}
                >
                  {listAllOrganization &&
                    listAllOrganization.map((item) => {
                      return (
                        <>
                          <option value="" disabled selected hidden>
                            Choose an organization
                          </option>
                          <option key={item.id} value={item.id}>
                            {item.title}
                          </option>
                        </>
                      );
                    })}
                </select> */}

                <Creatable
                  formatCreateLabel={(inputText) => `Add new "${inputText}"`}
                  isSearchable={true}
                  className="custom-react-select"
                  placeholder="Organization"
                  onChange={onChangeOrganization}
                  onCreateOption={onCreateOptionOrganization}
                  options={listOptionOrganization}
                  value={idOrganization}
                />

                <div className="mt-1 h-[10px]">
                  {/* <span className="text-red-500">
                    {errors?.organizationId &&
                    errors?.organizationId.type === "required"
                      ? "Please Choose Organization"
                      : ""}
                  </span> */}
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className=" text-white text-sm font-bold mb-2">
                  Department
                </label>
                {/* <select
                  {...register("departmentId")}
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.departmentId
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                  disabled={idOrganization?.value === null ? "disabled" : ""}
                >
                  {listDepartmentByOrganization &&
                    listDepartmentByOrganization.map((item) => {
                      return (
                        <>
                          <option value="" disabled selected hidden>
                            Choose a department
                          </option>
                          <option key={item.id} value={item.id}>
                            {item.title}
                          </option>
                        </>
                      );
                    })}
                </select> */}
                <Creatable
                  formatCreateLabel={(inputText) => `Add new "${inputText}"`}
                  isDisabled={idOrganization ? false : true}
                  isSearchable={true}
                  isClearable
                  className="custom-react-select"
                  placeholder={
                    !idOrganization ? "Select the organization first" : "Department"
                  }
                  onChange={onChangeDepartment}
                  onCreateOption={onCreateOptionDepartment}
                  options={listDepartmentByOrganization}
                  value={iddDepartment}
                />
                <div className="mt-1 h-[10px]">
                  {/* <span className="text-red-500">
                    {errors?.departmentId &&
                    errors?.departmentId.type === "required"
                      ? " Choose a department"
                      : ""}
                  </span> */}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mb-3">
              <div className="w-full md:w-1/2 px-3">
                <label className=" text-white text-sm font-bold mb-2">
                  Priority level
                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                    *
                  </span>
                </label>
                <select
                  {...register("priorityId", { required: true })}
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.priorityId
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                >
                  {listAllPriority &&
                    listAllPriority.map((item) => {
                      return (
                        <>
                          <option value="" disabled selected hidden>
                          Choose priority
                          </option>
                          <option key={item.id} value={item.id}>
                            {item.title}
                          </option>
                        </>
                      );
                    })}
                </select>
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.priorityId &&
                    errors?.priorityId.type === "required"
                      ? "Please choose priority for customers"
                      : ""}
                  </span>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3 relative">
                <label className=" text-white text-sm font-bold mb-2">
                  Products of interest 
                </label>
                <Select
                  className="ant-select-custom-muti border w-full min-h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  mode="multiple"
                  placeholder="Select product"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // disabled={`${
                  //   accountDetail?.roleCode === "employee" ? "disabled" : ""
                  // }`}
                  onChange={onChangeProduct}
                >
                  {listAllProduct &&
                    listAllProduct.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.title}
                        </Option>
                      );
                    })}
                </Select>
                {/* <DownOutlined className="text-white absolute top-1/2 right-3 pr-3 transform -translate-y-1/6 cursor-pointer text-xs" /> */}
                <i className="text-white fas fa-angle-down fa-2x absolute top-1/2 right-3 pr-3 transform -translate-y-1/6 cursor-pointer text-xs"></i>
              </div>
            </div>
            <div className="flex flex-wrap mb-3">
              <div className="w-full md:w-1/2 px-3">
                <label className=" text-white text-sm font-bold mb-2">
                  Group of customers sending Email
                </label>
                <select
                  {...register("customerGroupId")}
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.customerGroupId
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                >
                  {listAllCustomergroup &&
                    listAllCustomergroup.map((item) => {
                      return (
                        <>
                          <option value="" disabled selected hidden>
                          Select group
                          </option>
                          <option key={item.id} value={item.id}>
                            {item?.title}
                          </option>
                        </>
                      );
                    })}
                </select>
                <div className="mt-1 h-[10px]">
                  {/* <span className="text-red-500">
                    {errors?.customerGroupId &&
                    errors?.customerGroupId.type === "required"
                      ? "Please choose priority for customers"
                      : ""}
                  </span> */}
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3 ">
                <label className="w-full text-white text-sm font-bold mb-2">
                  Private
                </label>
                <Switch
                  disabled={
                    accountDetail?.roleCode === "employee" ? "disabled" : ""
                  }
                  onChange={(value) => setIsPrivate(value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-3">
              <div className="w-full md:w-1/2 px-3 mt-2">
                <Checkbox
                  className="mr-2"
                  checked={checkboxSendMail}
                  onChange={() => setCheckboxSendMail(!checkboxSendMail)}
                ></Checkbox>
                <label
                  className=" text-white text-sm font-bold"
                  onClick={() => setCheckboxSendMail(!checkboxSendMail)}
                >
                  Receive automatic email notification of expiration
                </label>
                <div className="mt-1 h-[10px]">
                  {/* <span className="text-red-500">
                    {errors?.customerGroupId &&
                    errors?.customerGroupId.type === "required"
                      ? "Please choose priority for customers"
                      : ""}
                  </span> */}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mb-8">
              <div className="w-full px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Note
                </label>
                <textarea
                  className="bg-[#414141] pl-[10px] pt-[10px] w-full border rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  rows={5}
                  placeholder="Note"
                  {...register("note")}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="mx-2">
                <ButtonCancel onClick={() => history.goBack()} />
              </div>
              <div className="mx-2">
                <ButtonSubmit titleButton="Confirm" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CustomerAdd;
