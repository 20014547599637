import React from "react";
import moment from "moment";
const RenderStatusExpiry = (endTime) => {
  const startDate = new Date(moment().format("YYYY-MM-DD"));
  const dateEnd = new Date(endTime);
  const diffTime = dateEnd - startDate;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays > 14) {
    return (
      <>
        <div className="using">Using</div>
      </>
    );
  } else if (diffDays >= 0 && diffDays <= 14) {
    return (
      <>
        <div className="unexpired-date">Have {diffDays} day</div>
      </>
    );
  } else {
    return (
      <>
        <div className="expiration-date">Expired</div>
      </>
    );
  }
};

export default RenderStatusExpiry;
