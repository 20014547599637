import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "./../../../Utils/Api";

export const getConfigSendMail = createAsyncThunk(
  "LKM/getConfigSendMail",
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/sendmailconfig/getall`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const deleteConfigSendMail = createAsyncThunk(
  "LKM/deleteConfigSendMail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/sendmailconfig/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const addConfigSendMail = createAsyncThunk(
  "LKM/addConfigSendMail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/sendmailconfig/create`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addTempalteMailAuto = createAsyncThunk(
  "LKM/addTempalteMailAuto",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/templatemailauto/create`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const updateTempalteMailAuto = createAsyncThunk(
  "LKM/updateTempalteMailAuto",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put(
        `/api/templatemailauto/update/${id}`,
        data
      );
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getConfigurationEmail = createAsyncThunk(
  "LKM/getConfigurationEmail",
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/mailsenderconfig/getall`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateConfigurationEmail = createAsyncThunk(
  "LKM/updateConfigurationEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/mailsenderconfig/update`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const ConfigSendMailSlice = createSlice({
  name: "ConfigSendMail",
  initialState: {
    items: [],
    templateMail: "",
    isLoading: false,
    templateMailById: {},
    configurationEmail: {},
  },
  extraReducers: {
    [getConfigSendMail.pending]: (state) => {
      state.isLoading = true;
    },
    [getConfigSendMail.fulfilled]: (state, action) => {
      const { data } = action?.payload;
      state.items = data;
      state.isLoading = false;
    },
    [getConfigSendMail.rejected]: (state) => {
      state.isLoading = false;
    },

    [deleteConfigSendMail.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteConfigSendMail.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deleteConfigSendMail.rejected]: (state) => {
      state.isLoading = false;
    },
    [addConfigSendMail.pending]: (state, action) => {
      state.loading = true;
    },
    [addConfigSendMail.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [addConfigSendMail.rejected]: (state, action) => {
      state.loading = false;
    },
    [addTempalteMailAuto.pending]: (state) => {
      state.isLoading = true;
    },
    [addTempalteMailAuto.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [addTempalteMailAuto.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateTempalteMailAuto.pending]: (state) => {
      state.isLoading = true;
    },
    [updateTempalteMailAuto.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [updateTempalteMailAuto.rejected]: (state) => {
      state.isLoading = false;
    },
    [getConfigurationEmail.pending]: (state) => {
      state.isLoading = true;
    },
    [getConfigurationEmail.fulfilled]: (state, action) => {
      const { data } = action?.payload;
      state.configurationEmail = data[0];
      state.isLoading = false;
    },
    [getConfigurationEmail.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateConfigurationEmail.pending]: (state) => {
      state.isLoading = true;
    },
    [updateConfigurationEmail.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [updateConfigurationEmail.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});
export default ConfigSendMailSlice.reducer;
