import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { FilterOutlined } from "@ant-design/icons";
import InputSearch from "../../../components/InputSearch/InputSearch";
import ButtonSubmit from "./../../../components/Button/ButtonSubmit";
import CustomerFilter from "./../Components/CustomerFilter";
import TableAdmin from "./../../../components/Table/TableAdmin";
import {
  deleteCustomer,
  getCustomer,
  getCustomerFilter,
} from "./../CustomerSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification, Tooltip, Button, Form } from "antd";
import ROUTER from "../../../routers/routerLink";
import { debounce } from "lodash";
import LicenseKeyByCustomer from "./LicenseKeyByCustomer";
import queryString from "query-string";

const CustomerList = (props) => {
  const [form] = Form.useForm();
  const search = useLocation().search;
  const [valueSearch, setValueSearch] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  // const [roleCode, setRoleCode] = useState(false);
  const dispatch = useDispatch();
  // const history = useHistory();
  // const search = useLocation().search;
  const listCustomer = useSelector((state) => state.customer.items);
  const meta = useSelector((state) => state?.customer?.meta);
  const isLoading = useSelector((state) => state?.customer?.isLoading);
  const isFilter = useSelector((state) => state?.customer?.isFilter);
  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );
  const [dataModal, setDataModal] = useState({
    openModal: false,
    idCustomer: "",
  });
  const [dataFilter, setDataFilter] = useState(null);

  useEffect(() => {
    if (search) {
      setValueSearch(queryString.parse(search)["keyword"]);
    } else {
      setValueSearch("");
    }
  }, [search]);

  useEffect(() => {
    let { search } = props.history.location;
    let params;
    const filterOptions = search.split("?")[1];
    if (filterOptions !== undefined) {
      params = `{"${decodeURI(filterOptions)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`;
      dispatch(
        getCustomer({ id: accountDetail?.id, params: JSON.parse(params) })
      );
    } else {
      dispatch(getCustomer({ id: accountDetail?.id, params: null }));
    }
    // setRoleCode(accountDetail?.roleCode);
  }, [dispatch, props.history.location, accountDetail]);

  // useEffect(() => {
  //   search && dispatch(setFilters(queryString.parse(search)));
  //   return () => {
  //     dispatch(removeFilters());
  //   };
  // }, []);
  // useEffect(() => {
  //   if (accountDetail?.id) {
  //     dispatch(
  //       getCustomer({
  //         id: accountDetail?.id,
  //         params: queryString.parse(search),
  //       })
  //     );
  //   }
  // }, [search, accountDetail]);
  // useEffect(() => {
  //   history.push(
  //     `${ROUTER.CUSTOMER.LIST}?${queryString.stringify(filters, {
  //       skipEmptyString: true,
  //     })}`
  //   );
  // }, [filters]);

  // const onChange = async (value) => {
  //   const id = value?.id;
  //   const data = {
  //     ...value,
  //     isPrivate: value?.isPrivate === true ? false : true,
  //     updatedBy: value?.updatedBy ? value?.updatedBy : accountDetail?.id,
  //   };
  //   try {
  //     const resulDispatch = await dispatch(updateCustomer({ id, data }));
  //     unwrapResult(resulDispatch);
  //     if (resulDispatch.payload.success === true) {
  //       notification.success({
  //         message: `Thay đổi Private thành công !!!`,
  //       });
  //       history.push(ROUTER.CUSTOMER.LIST);
  //     }
  //   } catch (error) {
  //     notification.error({ message: `Error: ${error.message}` });
  //   }
  // } else {
  //   notification.error({
  //     message: `Bạn không được thay đổi Private người khác !`,
  //   });
  // }
  // };
  const columns = [
    { dataIndex: "name", title: "Full name", sorting: true, type: "left" },

    { dataIndex: "email", title: "Email", type: "left" },
    // {
    //   dataIndex: "phone",
    //   title: "Phone",
    //   type: "left",
    //   render: (value) => (
    //     <span
    //       className={`cu ${
    //         value ? "font-medium capitalize" : "italic text-xs"
    //       }`}
    //     >
    //       {value ? value : "not data"}
    //     </span>
    //   ),
    // },
    // {
    //   dataIndex: "workPhone",
    //   title: "SĐT cơ quan",
    // },
    // {
    //   dataIndex: "productName",
    //   title: "Sản phẩm",
    //   type: "left",
    //   type: "left",
    // },
    {
      dataIndex: "organizationTitle",
      title: "Organization",
      type: "left",
    },
    // {
    //   dataIndex: "departmentTitle",
    //   title: "Department",
    //   type: "left",
    // },

    {
      title: "LicenseKey",
      sorting: true,
      render: (value) => (
        <Button onClick={() => showModalListLicenseKey(value)}>Detail</Button>
      ),
    },
    {
      dataIndex: "priorityTitle",
      title: "Priority level",
    },
    {
      dataIndex: "createByName",
      title: "User create",
      render: (value) => (
        <span className="cu font-medium capitalize">{value ? value : "-"}</span>
      ),
    },
    // {
    //   dataIndex: "note",
    //   title: "Note",
    //   type: "left",
    //   render: (value) =>
    //     value?.length >= 20 ? (
    //       <Popover
    //         content={
    //           <>
    //             <div className="max-w-[250px] break-words">
    //               <h1 className="border-b-2 text-xl font-semibold">Note </h1>
    //               <p> {value}</p>
    //             </div>
    //           </>
    //         }
    //       >
    //         <span className="font-medium">
    //           {value.substring(0, 20) + " ..."}
    //         </span>
    //       </Popover>
    //     ) : (
    //       <span className="font-medium">{value}</span>
    //     ),
    // },
  ];
  const showModalListLicenseKey = async (value) => {
    setDataModal({
      openModal: true,
      idCustomer: value?.id,
    });
  };
  const handleCancel = async () => {
    await setDataModal({
      openModal: false,
      idCustomer: null,
    });
  };
  const onDelete = async (id) => {
    try {
      const resultAction = await dispatch(deleteCustomer(id));
      unwrapResult(resultAction);
      notification.success({ message: `Delete successfully!` });
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };
  const fecthSearch = (value) => {
    if (value) {
      props.history.push(`${ROUTER.CUSTOMER.LIST}?keyword=${value}`);
    } else {
      props.history.push(ROUTER.CUSTOMER.LIST);
      dispatch(getCustomer({ id: accountDetail?.id, params: null }));
    }
  };
  const debounceSearch = useCallback(debounce(fecthSearch, 1000), []);
  const onSearch = (e) => {
    setValueSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
    debounceSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
  };

  const onChangePage = (page, size) => {
    if (isFilter === false) {
      const { search } = props.history.location;
      if (search !== "") {
        let params;
        const filterOptions = search.split("?")[1];
        if (filterOptions !== undefined) {
          params = `{"${decodeURI(filterOptions)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"')}"}`;
        }
        const obj = JSON.parse(params);
        params = { ...obj, page, size };
        const urlParameters = [];
        Object.entries(params).forEach((e) => {
          if (e[1] !== undefined && e[1] !== "") {
            urlParameters.push(e.join("="));
          }
        });
        props.history.push(
          ROUTER.CUSTOMER.LIST.concat(`?${urlParameters.join("&")}`)
        );
      } else {
        props.history.push(
          ROUTER.CUSTOMER.LIST.concat(`?page=${page}&size=${size}`)
        );
      }
    } else {
      let payload = {
        ...dataFilter,
        page: page,
        size: size,
      };
      dispatch(getCustomerFilter({ id: accountDetail?.id, data: payload }));
    }
  };
  return (
    <>
      <LicenseKeyByCustomer
        openModal={dataModal.openModal}
        idCustomer={dataModal.idCustomer}
        handleCancel={handleCancel}
      />
      <div className="overflow-x-auto  mb-10 mt-4">
        <div className="flex justify-center mb-3 bg-[#262626] px-4 py-8 rounded-[10px]">
          <div className="w-full">
            <div className="w-full mb-3 md:flex md:justify-between h-[40px]">
              <div>
                <NavLink to={ROUTER.CUSTOMER.ADD}>
                  <ButtonSubmit
                    iconButton={<i className="fas fa-plus pr-[15px]"></i>}
                    titleButton="Add new"
                  />
                </NavLink>
              </div>
              <Form form={form}>
                <div className="md:flex md:justify-end">
                  <Form.Item name="searchName">
                    <InputSearch
                      onChange={onSearch}
                      valueSearch={valueSearch}
                    />
                  </Form.Item>
                  <div className="ml-2 h-[40px]">
                    <Tooltip title="Filter the data">
                      <button
                        onClick={() => setShowFilter(true)}
                        className="border-[1px] h-full w-[45px] mr-[2px] rounded-md shadow-md bg-[#262626] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
                      >
                        <FilterOutlined className="text-[20px] text-white font-semibold" />
                      </button>
                    </Tooltip>
                    <CustomerFilter
                      openModal={showFilter}
                      dataFilter={dataFilter}
                      handleCancel={() => setShowFilter(false)}
                      style={{ fontSize: "20px" }}
                      setDataFilter={(data) => setDataFilter(data)}
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div>
              <TableAdmin
                columns={columns}
                dataTable={listCustomer}
                Url={ROUTER.CUSTOMER.EDIT}
                onDelete={onDelete}
                spinning={isLoading}
                meta={meta}
                onChangePage={onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerList;
