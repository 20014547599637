import Modal from "antd/lib/modal/Modal";
import React from "react";
import { Button, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const FormModal = (props) => {
  const {
    titleModal,
    handleCancel,
    handleReset,
    visible,
    children,
    titleForm,
    onOk,
    okText,
    hiddenReset,
    ...orther
  } = props;
  return (
    <>
      <Modal
        title={titleModal}
        onCancel={handleCancel}
        visible={visible}
        onOk={onOk}
        forceRender
        closeIcon={
          <Tooltip title="Close">
            <CloseOutlined />
          </Tooltip>
        }
        footer={[
          hiddenReset || <Button onClick={handleReset}>Reset</Button>,
          <Button onClick={handleCancel}>Exit</Button>,
          <Button
            className="min-w-[80px]"
            form={`${titleForm}`}
            type="primary"
            htmlType="submit"
            onClick={onOk}
          >
            {okText}
          </Button>,
        ]}
        {...orther}
      >
        {children}
      </Modal>
    </>
  );
};

export default FormModal;
