import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "./../../../Utils/Api";
import { Popover, Skeleton } from "antd";
import avtUser from "../../../assets/image/user.png";
import { API_URL } from "./../../../Utils/Config";
import parse from "html-react-parser";
import { RenderTimeSendMailDetail } from "./../../../components/RenderTimeSendMail.js/RenderTimeSendMail";

const LicenseKeyHistorySendMailExpiresDetail = () => {
  const [sendMailById, setSendMailById] = useState(null);
  const [accountById, setAccountById] = useState({});
  //   const [showModal, setShowModal] = useState({
  //     openModal: false,
  //     type: "",
  //     data: {},
  //   });
  const { id } = useParams();
  const getSendMailIntroById = async (id) => {
    try {
      let response = await Api.get(`api/mail/historty/getbyid/${id}`);
      if (response.status === 200) {
        setSendMailById(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      getSendMailIntroById(id);
    }
  }, [id]);

  const getAccountById = async (id) => {
    try {
      let response = await Api.get(`api/account/getbyid/${id}`);
      if (response.status === 200) {
        setAccountById(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (sendMailById?.sendByAccountId) {
      getAccountById(sendMailById?.sendByAccountId);
    }
  }, [sendMailById?.sendByAccountId]);

  //   const saveFile = (item) => {
  //     saveAs(
  //       `${API_URL}/${item}`,
  //       item?.split("/")[2]?.split(".")[0].length >= 50
  //         ? item?.split("/")[2]?.split(".")[0].substring(0, 50) + ". . ."
  //         : item?.split("/")[2]?.split(".")[0]
  //     );
  //   };

  //   const onShowModal = async (type, item) => {
  //     if (type === "forward") {
  //       setShowModal({
  //         openModal: true,
  //         type: type,
  //         data: type === "forward" ? item : {},
  //       });
  //     }
  //   };

  //   const handleCancel = async () => {
  //     await setShowModal({
  //       openModal: false,
  //       type: "",
  //       data: null,
  //     });
  //     await getSendMailIntroById(id);
  //   };
  const contentAcount = (
    <div>
      {sendMailById?.sendByAccountId ? (
        <>
          <div className="capitalize">
            <span className="font-semibold ">
              <i className="fas fa-user  pr-1"></i>
              Họ tên
            </span>
            : {accountById?.name}
          </div>
          <div className="pt-[5px]">
            <span className="font-semibold">
              <i className="fas fa-envelope pr-1"></i>
              Email
            </span>
            : {accountById?.email || "Chưa có"}
          </div>
          <div className="pt-[5px]">
            <span className="font-semibold">
              <i className="fas fa-phone  pr-1"></i>
              SĐT
            </span>
            : {accountById?.phone || "Chưa có"}
          </div>
          <div className="pt-[5px] capitalize">
            <span className="font-semibold ">
              <i className="fas fa-user-tag pr-1"></i>
              Chức vụ
            </span>
            : {accountById?.roleName || "Chưa có"}
          </div>
        </>
      ) : (
        <>
          <div className="capitalize">
            <span className="font-semibold ">Email tự động</span>
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      {/* <ModalComposeMail
        showModal={showModal?.openModal}
        handleCancel={handleCancel}
        type={showModal?.type}
        data={showModal?.data}
      /> */}
      {sendMailById && sendMailById !== null ? (
        <div className="overflow-x-auto mb-10 mt-4 bg-[#eeecec] rounded-[10px]">
          <div className="mx-10">
            <p className=" font-medium text-3xl mt-6 font">
              {sendMailById?.subject}
            </p>
          </div>
          <div className="flex justify-between mt-3">
            <div className="flex mx-10">
              <div className="mr-3">
                <Popover content={contentAcount}>
                  <img
                    className="rounded-full w-12 h-12 object-cover"
                    src={
                      accountById?.avatar
                        ? `${API_URL}/${accountById?.avatar}`
                        : avtUser
                    }
                  />
                </Popover>
              </div>
              <div className="flex-col">
                <div className=" font-bold text-xl capitalize">
                  <Popover content={contentAcount}>
                    {sendMailById?.sendByAccountName || "Mail Tự động"}
                  </Popover>
                </div>
                <span className="text-gray-500 text-xs">
                  Tới : {sendMailById?.receiveMails}
                </span>
              </div>
            </div>
            <div className="mt-1 mr-16">
              <span className="text-gray-500">
                {RenderTimeSendMailDetail(sendMailById?.sendingTime)}
              </span>
            </div>
          </div>

          <div className="mt-[30px] mx-10  mb-8 whitespace-pre-line">
            {sendMailById?.context && parse(sendMailById?.context)}
          </div>
        </div>
      ) : (
        <div className="overflow-x-auto mb-10 mt-4 bg-[#eeecec] rounded-[10px] p-5">
          <Skeleton active avatar paragraph={{ rows: 6 }} />
        </div>
      )}
    </>
  );
};

export default LicenseKeyHistorySendMailExpiresDetail;
