import { message } from "antd";

export function beforeUploadImage(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";
  if (!isJpgOrPng) {
    message.error("Please choose the correct format!!!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Please choose a photo smaller than 2Mb!");
  }
  return isJpgOrPng && isLt2M;
}
