import React, { useCallback, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import InputSearch from "../../../components/InputSearch/InputSearch";
import TableAdmin from "./../../../components/Table/TableAdmin";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import ROUTER from "../../../routers/routerLink";
import { debounce } from "lodash";
import { getPriority, deletePriority } from "./../PrioritySlice";

const PriorityList = (props) => {
  const dispatch = useDispatch();
  const listPriority = useSelector((state) => state?.priority?.items);
  const meta = useSelector((state) => state?.priority?.meta);
  const isLoading = useSelector((state) => state?.priority?.isLoading);
  useEffect(() => {
    const { search } = props.history.location;
    let params;
    const filterOptions = search.split("?")[1];
    if (filterOptions !== undefined) {
      params = `{"${decodeURI(filterOptions)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`;
      dispatch(getPriority(JSON.parse(params)));
    } else {
      dispatch(getPriority(null));
    }
  }, [dispatch, props.history.location]);
  const columns = [
    { dataIndex: "title", title: "Priority level", sorting: true, type: "left" },
  ];
  const onDelete = async (id) => {
    try {
      const resultAction = await dispatch(deletePriority(id));
      unwrapResult(resultAction);
      notification.success({ message: `Delete successfully!` });
    } catch (error) {
      notification.error({ message: `Error: ${error.message}` });
    }
  };
  const fecthSearch = (value) => {
    if (value) {
      props.history.push(ROUTER.PRIORITY.LIST.concat(`?keyword=${value}`));
    } else {
      props.history.push(ROUTER.PRIORITY.LIST);
      dispatch(getPriority(null));
    }
  };
  const debounceSearch = useCallback(debounce(fecthSearch, 1000), []);
  const onSearch = (e) => {
    debounceSearch(e.target.value);
  };

  const onChangePage = (page, size) => {
    const { search } = props.history.location;
    if (search !== "") {
      let params;
      const filterOptions = search.split("?")[1];
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`;
      }
      const obj = JSON.parse(params);
      params = { ...obj, page, size };
      const urlParameters = [];
      Object.entries(params).forEach((e) => {
        if (e[1] !== undefined && e[1] !== "") {
          urlParameters.push(e.join("="));
        }
      });
      props.history.push(
        ROUTER.PRIORITY.LIST.concat(`?${urlParameters.join("&")}`)
      );
    } else {
      props.history.push(
        ROUTER.PRIORITY.LIST.concat(`?page=${page}&size=${size}`)
      );
    }
  };
  return (
    <>
      <div className="overflow-x-auto mb-10 mt-4">
        <div className="flex justify-center mb-3 bg-[#262626] px-4 py-8 rounded-[10px]">
          <div className="w-full">
            <div className="w-full mb-3 md:flex md:justify-between">
              <div>
                <NavLink to={ROUTER.PRIORITY.ADD}>
                  <ButtonSubmit
                    iconButton={<i className="fas fa-plus pr-[15px]"></i>}
                    titleButton="Add new"
                  />
                </NavLink>
              </div>
              <div className="md:flex md:justify-end">
                <InputSearch placeholder="Search..." onChange={onSearch} />
              </div>
            </div>
            <div>
              <TableAdmin
                columns={columns}
                dataTable={listPriority}
                Url={ROUTER.PRIORITY.EDIT}
                spinning={isLoading}
                onDelete={onDelete}
                meta={meta}
                onChangePage={onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriorityList;
