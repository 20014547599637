import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "./../../../Utils/Api";
import { Image, Popover, Tooltip, Skeleton } from "antd";
import avtUser from "../../../assets/image/user.png";
import { API_URL } from "./../../../Utils/Config";
import parse from "html-react-parser";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import ModalComposeMail from "./ModalComposeMail";
import { RenderTimeSendMailDetail } from "./../../../components/RenderTimeSendMail.js/RenderTimeSendMail";

const LicenseKeySendMailIntroDetail = () => {
  const [sendMailById, setSendMailById] = useState(null);
  const [accountById, setAccountById] = useState({});
  const [showModal, setShowModal] = useState({
    openModal: false,
    type: "",
    data: {},
  });
  const { id } = useParams();
  const getSendMailIntroById = async (id) => {
    try {
      let response = await Api.get(`api/intromail/getbyid/${id}`);
      if (response.status === 200) {
        setSendMailById(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      getSendMailIntroById(id);
    }
  }, [id]);

  const getAccountById = async (id) => {
    try {
      let response = await Api.get(`api/account/getbyid/${id}`);
      if (response.status === 200) {
        setAccountById(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (sendMailById?.createdBy) {
      getAccountById(sendMailById?.createdBy);
    }
  }, [sendMailById?.createdBy]);

  const saveFile = (item) => {
    saveAs(
      `${API_URL}/${item}`,
      item?.split("/")[2]?.split(".")[0].length >= 50
        ? item?.split("/")[2]?.split(".")[0].substring(0, 50) + ". . ."
        : item?.split("/")[2]?.split(".")[0]
    );
  };

  const onShowModal = async (type, item) => {
    if (type === "forward") {
      setShowModal({
        openModal: true,
        type: type,
        data: type === "forward" ? item : {},
      });
    }
  };

  const handleCancel = async () => {
    await setShowModal({
      openModal: false,
      type: "",
      data: null,
    });
    await getSendMailIntroById(id);
  };
  const contentAcount = (
    <div>
      <div className="capitalize">
        <span className="font-semibold ">
          <i className="fas fa-user  pr-1"></i>
          Họ tên
        </span>
        : {accountById?.name}
      </div>
      <div className="pt-[5px]">
        <span className="font-semibold">
          <i className="fas fa-envelope pr-1"></i>
          Email
        </span>
        : {accountById?.email || "Chưa có"}
      </div>
      <div className="pt-[5px]">
        <span className="font-semibold">
          <i className="fas fa-phone  pr-1"></i>
          SĐT
        </span>
        : {accountById?.phone || "Chưa có"}
      </div>
      <div className="pt-[5px] capitalize">
        <span className="font-semibold ">
          <i className="fas fa-user-tag pr-1"></i>
          Chức vụ
        </span>
        : {accountById?.roleName || "Chưa có"}
      </div>
    </div>
  );

  return (
    <>
      <ModalComposeMail
        showModal={showModal?.openModal}
        handleCancel={handleCancel}
        type={showModal?.type}
        data={showModal?.data}
      />
      {sendMailById && sendMailById !== null ? (
        <div className="overflow-x-auto mb-10 mt-4 bg-[#eeecec] rounded-[10px]">
          <div className="mx-10">
            <p className=" font-medium text-3xl mt-6 font">
              {sendMailById?.subject}
            </p>
          </div>
          <div className="flex justify-between">
            <div className="flex mx-10">
              <div className="mr-3">
                <Popover content={contentAcount}>
                  <img
                    className="rounded-full w-12 h-12 object-cover"
                    src={
                      accountById?.avatar
                        ? `${API_URL}/${accountById?.avatar}`
                        : avtUser
                    }
                  />
                </Popover>
              </div>
              <div className="flex-col">
                <div className=" font-bold text-xl capitalize">
                  <Popover content={contentAcount}>
                    {sendMailById?.sender}
                  </Popover>
                </div>
                <span className="text-gray-600 text-xs flex">
                  <b className="mt-1 pr-2">Tới :</b>
                  <div className="flex">
                    {sendMailById?.receiveMail.slice(0, 4).map((e, index2) => {
                      return (
                        <Tooltip key={index2} title={e}>
                          <span className="border py-[2px] px-[3px] text-[10px] rounded-2xl mr-1">
                            {e?.length >= 20
                              ? e?.substring(0, 20) + ". . ."
                              : e}
                          </span>
                        </Tooltip>
                      );
                    })}
                    {sendMailById?.receiveMail.length > 4 && (
                      <Popover
                        content={sendMailById?.receiveMail
                          .slice(4)
                          .map((e2, indexx) => {
                            return (
                              <span>
                                {sendMailById?.receiveMail.slice(4).length ==
                                indexx + 1
                                  ? `${e2}`
                                  : `${e2},  `}
                              </span>
                            );
                          })}
                      >
                        <div className="text-gray-600 border py-[2px] px-[3px] rounded-2xl mr-2 ">
                          +{sendMailById?.receiveMail?.length - 4}
                        </div>
                      </Popover>
                    )}
                  </div>
                </span>
              </div>
            </div>
            <div className="mt-1 mr-16">
              <span className="text-gray-500">
                {RenderTimeSendMailDetail(sendMailById?.sendingTime)}
              </span>
              <Tooltip title="Chuyển tiếp">
                <i
                  onClick={() => onShowModal("forward", sendMailById)}
                  className="ml-[10px] hover:scale-110 fas fa-share cursor-pointer opacity-75"
                ></i>
              </Tooltip>
            </div>
          </div>

          <div className="mt-[30px] mx-10  mb-8 whitespace-pre-line">
            {sendMailById?.context && parse(sendMailById?.context)}
          </div>

          {sendMailById?.urlAttachment?.length > 0 && (
            <>
              <hr className="border-1  bg-slate-50 mb-3 mx-10"></hr>
              <div className=" mx-3 mb-6">
                <span className=" mx-3">
                  {sendMailById?.urlAttachment?.length} tệp đính kèm
                </span>
                <div className="flex flex-wrap max-h-[400px] overflow-x-auto">
                  <div className="uploaded-file-list mt-2">
                    {sendMailById?.urlAttachment &&
                      sendMailById?.urlAttachment.map((item, index) => {
                        return (
                          <div className="uploaded-file relative" key={index}>
                            <Popover content={item?.split("/")[2]}>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`${API_URL}/${item}`}
                                className="uploaded-file-list-item mx-3"
                              >
                                {item.split(".")[item.split(".").length - 1] ===
                                  "jpg" ||
                                item.split(".")[item.split(".").length - 1] ===
                                  "png" ? (
                                  <Image
                                    preview={false}
                                    style={{
                                      height: 40,
                                      width: 40,
                                      objectFit: "cover",
                                    }}
                                    src={`${API_URL}/${item}`}
                                  />
                                ) : (
                                  <i className="fas fa-file-archive fa-3x "></i>
                                )}
                                <div className="uploaded-file-list-item-title">
                                  <span className="text-gray-500">
                                    {item?.split("/")[2]?.split(".")[0]
                                      .length >= 70
                                      ? item
                                          ?.split("/")[2]
                                          ?.split(".")[0]
                                          .substring(0, 70) + ". . ."
                                      : item?.split("/")[2]?.split(".")[0]}
                                  </span>
                                </div>
                              </a>
                            </Popover>
                            <div className="show-icon-dowload-file ">
                              <Tooltip title="Download">
                                <VerticalAlignBottomOutlined
                                  onClick={() => saveFile(item)}
                                  className="bg-slate-100 hover:bg-slate-300 py-1 px-2  opacity-75 text-lg cursor-pointer hover:scale-110 transition-all rounded-md"
                                />
                              </Tooltip>
                              {/* </Popconfirm> */}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="overflow-x-auto mb-10 mt-4 bg-[#eeecec] rounded-[10px] p-5">
          <Skeleton active avatar paragraph={{ rows: 6 }} />
        </div>
      )}
    </>
  );
};

export default LicenseKeySendMailIntroDetail;
