import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ButtonCancel from "../../../components/Button/ButtonCancel";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import { updateOrganization } from "./../OrganizationSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ROUTER from "../../../routers/routerLink";
import Api from "../../../Utils/Api";
import { validateInput } from "../../../Utils/Validate/validateInput";
const OrganizationEdit = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const getOrganizationById = async (id) => {
    try {
      let response = await Api.get(`api/organization/getbyid/${id}`);
      if (response.status === 200) {
        reset(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      getOrganizationById(id);
    }
  }, []);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (value) => {
    const data = {
      title: value?.title?.trim(),
      note: value?.note?.trim(),
    };
    try {
      const resulDispatch = await dispatch(updateOrganization({ id, data }));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Edit success!!!` });
        history.push(ROUTER.ORGANIZATION.LIST);
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };
  return (
    <>
      <div className="flex justify-center mt-[50px]">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[500px] overflow-hidden shadow-lg bg-[#262626] rounded-[10px]"
        >
          <div className="mx-[40px] my-[30px]">
            <div className="flex flex-wrap mb-8">
              <div className="w-full ">
                <label className=" text-white text-sm font-bold mb-2">
                  Organization Name
                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                    *
                  </span>
                </label>
                <input
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.title
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                  type="text"
                  placeholder="Organization Name"
                  {...register("title", {
                    required: true,
                    pattern: validateInput,
                  })}
                />
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.title && errors?.title.type === "required"
                      ? "Please enter organization name"
                      : ""}
                    {errors?.title && errors.title.type === "pattern"
                      ? "Không được chứa kí tự đặc biệt"
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mb-8">
              <div className="w-full">
                <label className=" text-white text-sm font-bold mb-2">
                  Describe
                </label>
                <textarea
                  className="bg-[#414141] pl-[10px] pt-[10px] w-full border rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  rows={5}
                  placeholder="Describe"
                  {...register("note")}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="mx-2">
                <ButtonCancel onClick={() => history.goBack()} />
              </div>
              <div className="mx-2">
                <ButtonSubmit titleButton="Confirm" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default OrganizationEdit;
