import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Input,
  Select,
  Tooltip,
  ConfigProvider,
  Form,
  notification,
  Popover,
  Pagination,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import ButtonSubmit from "./../../../components/Button/ButtonSubmit";
import Api from "./../../../Utils/Api";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  addCustomerGroup,
  getCustomerGroup,
  updateCustomerGroup,
  deleteCustomerGroup,
} from "./../GroupCustomerSlice";
import ROUTER from "../../../routers/routerLink";
import { getAllOrganization } from "../../Organization/OrganizationSlice";

const { Option } = Select;
const GroupCustomer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const listCustomerGroup = useSelector((state) => state?.customerGroup?.items);
  const listAllOrganization = useSelector((state) => state.organization.items);
  const meta = useSelector((state) => state?.customerGroup?.meta);
  const [showSelectCustomer, setShowSelectCustomer] = useState({
    type: true,
    id: "",
  });
  const [showAddCustomerGroup, setShowAddCustomerGroup] = useState(false);
  const [listAllCustomer, setListAllCustomer] = useState([]);
  const [valueTitleUpdate, setValueTitleUpdate] = useState("");
  const [idOrganizationAdd, setIdOrganizationAdd] = useState("");
  const [selectedCustomerAdd, setSelectedCustomerAdd] = useState([]);
  const [customerByOrganiAdd, setCustomerByOrganiAdd] = useState([]);
  const [idOrganizationUpdate, setIdOrganizationUpdate] = useState("");
  const [selectedCustomerUpdate, setSelectedCustomerUpdate] = useState([]);
  const [customerByOrganiUpdate, setCustomerByOrganiUpdate] = useState([]);
  const [valueTemplateMailUpdate, setValueTemplateMailUpdate] = useState("");
  const [listAllTemplateMailAuto, setListAllTemplateMailAuto] = useState([]);

  let valueCustomerAdd = Array.from(
    new Set([].concat(customerByOrganiAdd, selectedCustomerAdd))
  );
  let valueCustomerUpdate = Array.from(
    new Set([].concat(customerByOrganiUpdate, selectedCustomerUpdate))
  );

  useEffect(async () => {
    if (idOrganizationAdd) {
      try {
        const { data } = await Api.post(`/api/customer/getbyorigin`, {
          organizationIds: idOrganizationAdd,
        });
        if (data?.success === true && data?.data) {
          setCustomerByOrganiAdd(data?.data);
        } else {
          setCustomerByOrganiAdd([]);
        }
      } catch (error) {}
    }
  }, [idOrganizationAdd]);

  useEffect(async () => {
    if (idOrganizationUpdate) {
      try {
        const { data } = await Api.post(`/api/customer/getbyorigin`, {
          organizationIds: idOrganizationUpdate,
        });
        if (data?.success === true && data?.data) {
          setCustomerByOrganiUpdate(data?.data);
        } else {
          setCustomerByOrganiUpdate([]);
        }
      } catch (error) {}
    }
  }, [idOrganizationUpdate]);

  const getAllTemplateAuto = async () => {
    try {
      const { data } = await Api.get(`/api/templatemailauto/getall`);
      if (data?.success === true) {
        setListAllTemplateMailAuto(data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(getAllOrganization());
  }, [dispatch]);
  useEffect(() => {
    getAllTemplateAuto();
  }, []);

  const getCustomerGroupById = async (id) => {
    try {
      let response = await Api.get(`api/customergroup/getbyid/${id}`);
      if (response.status === 200) {
        setValueTitleUpdate(response?.data?.data?.title);
        setSelectedCustomerUpdate(response?.data?.data?.customerId);
        setValueTemplateMailUpdate(response?.data?.data?.templateMailAutoId);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (!showSelectCustomer?.type && showSelectCustomer?.id) {
      getCustomerGroupById(showSelectCustomer?.id);
    }
  }, [!showSelectCustomer?.type, showSelectCustomer?.id]);

  const getAllCustomer = async () => {
    try {
      let response = await Api.get(`/api/customer/getall`);
      if (response.status === 200) {
        setListAllCustomer(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAllCustomer();
  }, []);

  useEffect(() => {
    const { search } = props.history.location;
    let params;
    const filterOptions = search.split("?")[1];
    if (filterOptions !== undefined) {
      params = `{"${decodeURI(filterOptions)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`;
      dispatch(getCustomerGroup(JSON.parse(params)));
    } else {
      dispatch(getCustomerGroup(null));
    }
  }, [dispatch, props.history.location]);

  const onFinishCustomerGroupAdd = async (value) => {
    const data = {
      title: value?.title?.trim(),
      customerId: valueCustomerAdd,
      templateMailAutoId: value?.templateMailAutoId,
    };
    try {
      const resulDispatch = await dispatch(addCustomerGroup(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Successfully added new group!!!` });
        dispatch(getCustomerGroup());
        getAllCustomer();
        setShowAddCustomerGroup(false);
        setSelectedCustomerAdd([]);
        setCustomerByOrganiAdd([]);
        form.resetFields();

        history.push(ROUTER.GROUPCUSTOMER.LIST);
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };
  const OnUpdateCustomerGroup = async (value) => {
    const data = {
      title: value?.title?.trim(),
      customerId: value?.customerId,
      templateMailAutoId: value?.templateMailAutoId,
    };
    let id = value?.id;
    try {
      const resulDispatch = await dispatch(updateCustomerGroup({ id, data }));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({
          message: `Editing group of customers who sent Email successfully!!!`,
        });
        dispatch(getCustomerGroup());
        getAllCustomer();
        setShowSelectCustomer({
          type: true,
          id: "",
        });
        setCustomerByOrganiUpdate([]);
        setSelectedCustomerUpdate([]);
        setValueTitleUpdate("");
        // setValueCustomerUpdate([]);
        setValueTemplateMailUpdate("");

        history.push(ROUTER.GROUPCUSTOMER.LIST);
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };

  const OnDeleteCustomerGroup = async (id) => {
    try {
      const resultAction = await dispatch(deleteCustomerGroup(id));
      unwrapResult(resultAction);
      notification.success({ message: `Delete successfully!` });
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };

  const onChangePage = (page, size) => {
    const { search } = props.history.location;
    if (search !== "") {
      let params;
      const filterOptions = search.split("?")[1];
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`;
      }
      const obj = JSON.parse(params);
      params = { ...obj, page, size };
      const urlParameters = [];
      Object.entries(params).forEach((e) => {
        if (e[1] !== undefined && e[1] !== "") {
          urlParameters.push(e.join("="));
        }
      });
      props.history.push(
        ROUTER.GROUPCUSTOMER.LIST.concat(`?${urlParameters.join("&")}`)
      );
    } else {
      props.history.push(
        ROUTER.GROUPCUSTOMER.LIST.concat(`?page=${page}&size=${size}`)
      );
    }
  };

  return (
    <>
      <ConfigProvider
        renderEmpty={() => (
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <InfoCircleOutlined style={{ fontSize: 30, color: "white" }} />
            <p className="text-white mt-1">Trống</p>
          </div>
        )}
      >
        <div className="overflow-x-auto mb-10 mt-6 bg-[#262626] rounded-[10px]">
          <div className="flex justify-center mb-3 px-4 py-8">
            <div className="w-full">
              <div className="w-full md:flex md:justify-between h-auto mb-4">
                <div>
                  <ButtonSubmit
                    onClick={() => setShowAddCustomerGroup(true)}
                    iconButton={<i className="fas fa-plus pr-[15px]"></i>}
                    titleButton="Add group"
                  />
                </div>
              </div>
              <div className="flex bg-[#3E3C3C] text-white uppercase  leading-normal rounded-[10px] p-2">
                <div className="flex w-1/3">
                  <span className="w-1/2 font-bold text-xl ">Nhóm</span>
                  <span className="w-1/2 font-bold text-xl">Mẫu mail</span>
                </div>
                <div className="w-2/3">
                  <span className="w-1/2 font-bold text-xl">Khánh hàng</span>
                </div>
              </div>
              <div className="">
                {showAddCustomerGroup && (
                  <div className="list-groupcustomer mx-2">
                    <Form
                      onFinish={onFinishCustomerGroupAdd}
                      form={form}
                      className="border-b pt-2 cursor-pointer"
                    >
                      <div className="flex">
                        <div className="w-1/3 flex mr-3 overflow-hidden">
                          <div className="capitalize  w-1/2 mr-3">
                            <Form.Item
                              name="title"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter the group name",
                                },
                              ]}
                            >
                              <Input
                                className="text-white custom-input-ant-customergroup border h-[42px]"
                                placeholder="New group name"
                              />
                            </Form.Item>
                          </div>
                          <div className="capitalize  w-1/2">
                            <Form.Item
                              name="templateMailAutoId"
                            >
                              <Select
                                className="text-white ant-select-tempaltemail-customergruop border overflow-y-auto"
                                placeholder="Select email template"
                                // showSearch
                                filterOption={(input, option) =>
                                  option?.children
                                    ?.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {listAllTemplateMailAuto?.map((item) => {
                                  return (
                                    <Option key={item.id} value={item?.id}>
                                      <Tooltip title={item?.subject}>
                                        {item?.subject.length >= 50
                                          ? item?.subject?.substring(0, 50) +
                                            ". . ."
                                          : item?.subject}
                                      </Tooltip>
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div className="w-2/3 sm:mr-[20px]">
                          <div className="flex">
                            <span className="text-white w-[80%]">
                              <Select
                                className="text-white ant-select-customergroup-muti border w-full overflow-y-auto"
                                mode="multiple"
                                placeholder="Choose a customer"
                                showSearch
                                filterOption={(input, option) =>
                                  option?.children
                                    ?.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                value={valueCustomerAdd}
                                onChange={(e) => setSelectedCustomerAdd(e)}
                              >
                                {listAllCustomer.map((item) => {
                                  return (
                                    <Option key={item.id} value={item.id}>
                                      {`${item.name} (${item?.email})  ${
                                        item.customerGroupName
                                          ? `[${item.customerGroupName}]`
                                          : ""
                                      }`}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </span>

                            <div className="ml-2 w-[20%]">
                              <Select
                                mode="multiple"
                                className="text-white ant-select-customergroup-muti border w-full overflow-y-auto"
                                placeholder="Choose an organization"
                                // showSearch
                                allowClear
                                filterOption={(input, option) =>
                                  option?.children
                                    ?.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                // value={valueTemplateMailUpdate}
                                onChange={(e) => setIdOrganizationAdd(e)}
                              >
                                {listAllOrganization &&
                                  listAllOrganization.map((item) => {
                                    return (
                                      <Option key={item.id} value={item.id}>
                                        {item.title}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>
                          <div className="flex ml-2 my-2">
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="mr-2 h-[40px]"
                            >
                             Create new
                            </Button>
                            <Button
                              className="h-[40px]"
                              onClick={() => {
                                setShowAddCustomerGroup(false);
                                form.resetFields();
                                setCustomerByOrganiAdd([]);
                                setSelectedCustomerAdd([]);
                                setIdOrganizationAdd("");
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}
                {listCustomerGroup && listCustomerGroup.length > 0 ? (
                  <>
                    {listCustomerGroup?.map((item, index) => (
                      <div className="list-groupcustomer mx-2" key={index}>
                        <Tooltip
                          title={
                            showSelectCustomer?.type
                              ? "Click to edit"
                              : false
                          }
                        >
                          <div
                            // target="_blank"
                            className="flex border-b py-2 cursor-pointer"
                          >
                            <div
                              className="w-1/3 flex mr-3 overflow-hidden"
                              onClick={() =>
                                setShowSelectCustomer({
                                  type: false,
                                  id: item?.id,
                                })
                              }
                            >
                              <div className="capitalize w-1/2 mr-3">
                                {!showSelectCustomer?.type &&
                                showSelectCustomer?.id === item?.id ? (
                                  <Input
                                    className="text-white custom-input-ant-customergroup border w-full h-[42px] "
                                    placeholder="Group name"
                                    value={valueTitleUpdate}
                                    onChange={(e) =>
                                      setValueTitleUpdate(e?.target?.value)
                                    }
                                  />
                                ) : (
                                  <span
                                    className="text-white font-semibold text-base"
                                    onClick={() =>
                                      setShowSelectCustomer({
                                        type: false,
                                        id: item?.id,
                                      })
                                    }
                                  >
                                    {item?.title}
                                  </span>
                                )}
                              </div>
                              <div className="capitalize w-1/2">
                                {!showSelectCustomer?.type &&
                                showSelectCustomer?.id === item?.id ? (
                                  <Select
                                    className="text-white ant-select-tempaltemail-customergruop w-full border overflow-y-auto"
                                    placeholder="Select email template"
                                    // showSearch
                                    filterOption={(input, option) =>
                                      option?.children
                                        ?.toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={valueTemplateMailUpdate}
                                    onChange={(e) =>
                                      setValueTemplateMailUpdate(e)
                                    }
                                  >
                                    {listAllTemplateMailAuto?.map((item) => {
                                      return (
                                        <Option key={item.id} value={item?.id}>
                                          <Tooltip title={item?.subject}>
                                            {item?.subject.length >= 50
                                              ? item?.subject?.substring(
                                                  0,
                                                  50
                                                ) + ". . ."
                                              : item?.subject}
                                          </Tooltip>
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                ) : (
                                  <span
                                    className="text-white font-semibold text-base"
                                    onClick={() =>
                                      setShowSelectCustomer({
                                        type: false,
                                        id: item?.id,
                                      })
                                    }
                                  >
                                    {item?.templateMailAutoName || "-"}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="w-2/3 sm:mr-[20px]">
                              {!showSelectCustomer?.type &&
                              showSelectCustomer?.id === item?.id ? (
                                <>
                                  <div className="flex">
                                    <div
                                      className="w-[80%]"
                                      onClick={() =>
                                        setShowSelectCustomer({
                                          type: false,
                                          id: item?.id,
                                        })
                                      }
                                    >
                                      <Select
                                        className="text-white ant-select-customergroup-muti border w-full overflow-y-auto"
                                        mode="multiple"
                                        placeholder="Choose a customer"
                                        showSearch
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={valueCustomerUpdate}
                                        onChange={(e) =>
                                          setSelectedCustomerUpdate(e)
                                        }
                                      >
                                        {listAllCustomer &&
                                          listAllCustomer.map((item) => {
                                            return (
                                              <Option
                                                key={item.id}
                                                value={item.id}
                                              >
                                                {`${item.name} (${
                                                  item?.email
                                                })  ${
                                                  item.customerGroupName
                                                    ? `[${item.customerGroupName}]`
                                                    : ""
                                                }`}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                    <div className="ml-2 w-[20%]">
                                      <Select
                                        mode="multiple"
                                        className="text-white ant-select-customergroup-muti border w-full overflow-y-auto"
                                        placeholder="Choose an organization"
                                        // showSearch
                                        filterOption={(input, option) =>
                                          option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(e) =>
                                          setIdOrganizationUpdate(e)
                                        }
                                      >
                                        {listAllOrganization &&
                                          listAllOrganization.map((item) => {
                                            return (
                                              <Option
                                                key={item.id}
                                                value={item.id}
                                              >
                                                {item.title}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <Button
                                      className="mr-2 h-[40px]"
                                      type="primary"
                                      onClick={() =>
                                        OnUpdateCustomerGroup({
                                          id: item?.id,
                                          title: valueTitleUpdate,
                                          customerId: valueCustomerUpdate,
                                          templateMailAutoId:
                                            valueTemplateMailUpdate,
                                        })
                                      }
                                    >
                                      Lưu Lại
                                    </Button>
                                    <Button
                                      className="mr-2 h-[40px]  ant-btn-danger"
                                      onClick={() =>
                                        OnDeleteCustomerGroup(item?.id)
                                      }
                                    >
                                      Delete
                                    </Button>
                                    <Button
                                      className="h-[40px]"
                                      onClick={() =>
                                        setShowSelectCustomer({
                                          type: true,
                                          id: "",
                                        })
                                      }
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <div
                                  className="max-h-[120px] overflow-y-auto"
                                  onClick={() =>
                                    setShowSelectCustomer({
                                      type: false,
                                      id: item?.id,
                                    })
                                  }
                                >
                                  {item?.infoCustomer.length > 0 ? (
                                    item?.infoCustomer?.map((e) => (
                                      <Popover
                                        key={e?.id}
                                        content={
                                          <div>
                                            <div className="capitalize">
                                              <span className="font-semibold ">
                                                <i className="fas fa-user  pr-1"></i>
                                                Họ tên
                                              </span>
                                              : {e?.name}
                                            </div>
                                            <div className="pt-[5px]">
                                              <span className="font-semibold">
                                                <i className="fas fa-sitemap pr-1"></i>
                                                Organization
                                              </span>
                                              :{" "}
                                              {e?.organizationTitle ||
                                                "Chưa có"}
                                            </div>
                                            <div className="pt-[5px]">
                                              <span className="font-semibold">
                                                <i className="fas fa-building pr-1"></i>
                                                Department
                                              </span>
                                              :{" "}
                                              {e?.departmentTitle || "Chưa có"}
                                            </div>
                                            <div className="pt-[5px]">
                                              <span className="font-semibold">
                                                <i className="fas fa-envelope pr-1"></i>
                                                Email
                                              </span>
                                              : {e?.email || "Chưa có"}
                                            </div>
                                            <div className="pt-[5px]">
                                              <span className="font-semibold">
                                                <i className="fas fa-phone  pr-1"></i>
                                                SĐT
                                              </span>
                                              : {e?.phone || "Chưa có"}
                                            </div>
                                          </div>
                                        }
                                      >
                                        <button className="h-[40px] button-customer-group">
                                          <span className="p-2 capitalize">
                                            {e?.name}
                                          </span>
                                        </button>
                                      </Popover>
                                    ))
                                  ) : (
                                    <span
                                      className="text-white font-semibold text-base"
                                      onClick={() =>
                                        setShowSelectCustomer({
                                          type: false,
                                          id: item?.id,
                                        })
                                      }
                                    >
                                      Empty
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="flex justify-center h-[100px]">
                    <div className=" flex flex-col text-center text-white justify-center ">
                      <i className="fas fa-exclamation-circle fa-2x"></i>
                      <span className="text-xl mt-2">There are no groups</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-3 mb-6 mx-3">
            {meta?.total > 10 ? (
              <>
                <Pagination
                  // size="small"
                  className="custom-pagination"
                  current={meta?.page ? meta?.page : 1}
                  total={meta?.total}
                  pageSize={meta?.size}
                  onChange={onChangePage}
                  showSizeChanger
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </ConfigProvider>
    </>
  );
};

export default GroupCustomer;
