import React, { useState } from "react";
import backgroupPass from "../../../assets/image/bg_change_pass.jpg";
import ButtonSubmit from "./../../../components/Button/ButtonSubmit";
import ROUTER from "../../../routers/routerLink";
import ButtonCancel from "./../../../components/Button/ButtonCancel";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputPassword from "./../../../components/InputPassword/inputPassword";
import Api from "../../../Utils/Api";
import { useSelector } from "react-redux";
import { notification } from "antd";
import { isStrongPassword } from "../../../Utils/Validate/validateInput";
const ChangePassword = () => {
  const history = useHistory();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const accountDetail = useSelector(
    (state) => state.accountDetail.accountDetail
  );
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm();
  const onSubmit = async (value) => {
    if (value.newPassword !== value.cf_newPassword) {
      setError("cf_newPassword", {
        type: "cf_pass_err",
        message: "Re-confirm password mới không trùng khớp",
      });
    } else if (value.oldPassword === value.newPassword) {
      setError("newPassword", {
        type: "cf_newPass_err",
        message: "Mật khẩu mới phải khác mật khẩu cũ",
      });
    } else {
      const data = {
        id: accountDetail?.id,
        oldPassword: value?.oldPassword,
        newPassword: value?.newPassword,
      };
      try {
        let response = await Api.post(`api/account/changepassword`, data);
        if (response.status === 200) {
          notification.success({ message: `Change password successfully!!!` });
          history.push(ROUTER.HOME.HOME);
        }
      } catch (error) {
        if (error?.data?.message === "Bad credentials") {
          setError("oldPassword", {
            type: "cf_oldPass_err",
            message: "Mật khẩu cũ không đúng",
          });
        } else if (error?.data?.message) {
          notification.error({
            message: `Lỗi: ${error?.data?.message}`,
          });
        } else {
          notification.error({
            message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng`,
          });
        }
      }
    }
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="flex mb-10 w-3/4 bg-[#262626] rounded-lg shadow-xl ">
          <div
            className="hidden lg:block w-0 lg:w-3/5 bg-cover "
            style={{
              backgroundImage: `url(${backgroupPass})`,
            }}
          />
          <div className="w-full lg:w-2/5 py-6 px-6">
            <h1 className="text-white text-[30px] font-extrabold text-center mb-3">
            Change Password
            </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-4">
                <label className=" text-white text-sm font-bold mb-2">
                  Old password
                </label>
                <InputPassword
                  showPassword={showCurrentPassword}
                  setShowPassWord={setShowCurrentPassword}
                  color="white"
                >
                  <input
                    className="bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300   focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    type={showCurrentPassword ? "text" : "password"}
                    placeholder="Old password"
                    {...register("oldPassword", { required: true })}
                  />
                </InputPassword>
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.oldPassword &&
                    errors?.oldPassword.type === "required"
                      ? "Please enter password cũ"
                      : ""}
                    {errors?.oldPassword &&
                    errors?.oldPassword.type === "cf_oldPass_err"
                      ? errors?.oldPassword.message
                      : ""}
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <label className=" text-white text-sm font-bold mb-2">
                  New password
                </label>
                <InputPassword
                  showPassword={showNewPassword}
                  setShowPassWord={setShowNewPassword}
                  color="white"
                >
                  <input
                    className="bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300   focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="New password"
                    {...register("newPassword", {
                      required: true,
                      pattern: isStrongPassword,
                    })}
                  />
                </InputPassword>
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.newPassword &&
                    errors?.newPassword.type === "required"
                      ? "Please enter password mới"
                      : ""}
                    {errors?.newPassword &&
                    errors.newPassword.type === "pattern"
                      ? "Password must be at least 8 characters including letters, numbers and characters"
                      : ""}
                    {errors?.newPassword &&
                    errors?.newPassword.type === "cf_newPass_err"
                      ? errors?.newPassword.message
                      : ""}
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <label className=" text-white text-sm font-bold mb-2">
                  Re-confirm password
                </label>
                <InputPassword
                  showPassword={showConfirmPassword}
                  setShowPassWord={setShowConfirmPassword}
                  color="white"
                >
                  <input
                    className="bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300   focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Re-confirm password"
                    {...register("cf_newPassword", { required: true })}
                  />
                </InputPassword>
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.cf_newPassword &&
                    errors.cf_newPassword.type === "required"
                      ? "Re-confirm password"
                      : ""}
                    {(errors.cf_newPassword && errors.cf_newPassword.type) ===
                    "cf_pass_err"
                      ? errors.cf_newPassword.message
                      : ""}
                  </span>
                </div>
              </div>
              <div className="flex justify-center mb-[20px] mt-7">
                <div className="mx-2">
                  <ButtonCancel onClick={() => history.goBack()} />
                </div>
                <div className="mx-2">
                  <ButtonSubmit titleButton="Agree" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
