import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useParams, useLocation } from "react-router-dom";
import ButtonSubmit from "./../../../components/Button/ButtonSubmit";
import TableAdmin from "./../../../components/Table/TableAdmin";
import InputSearch from "../../../components/InputSearch/InputSearch";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification, Form } from "antd";
import ROUTER from "../../../routers/routerLink";
import { debounce } from "lodash";
import { deleteVersion, getVersionById } from "./../VersionSlice";
import queryString from "query-string";

const VersionList = (props) => {
  const [valueSearch, setValueSearch] = useState("");
  const [form] = Form.useForm();
  const search = useLocation().search;
  const { idEdition } = useParams();
  const dispatch = useDispatch();
  const listVersion = useSelector((state) => state?.version?.items);
  const meta = useSelector((state) => state?.version?.meta);
  const isLoading = useSelector((state) => state?.version?.isLoading);

  useEffect(() => {
    if (search) {
      setValueSearch(queryString.parse(search)["keyword"]);
    } else {
      setValueSearch("");
    }
  }, [search]);

  useEffect(() => {
    const { search } = props.history.location;
    let params;
    const filterOptions = search.split("?")[1];
    if (filterOptions !== undefined) {
      params = `{"${decodeURI(filterOptions)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`;
      dispatch(getVersionById({ id: idEdition, params: JSON.parse(params) }));
    } else {
      dispatch(getVersionById({ id: idEdition, params: null }));
    }
  }, [dispatch, props.history.location, idEdition]);
  // const onChange = async (value) => {
  //   const id = value?.id;
  //   const data = {
  //     title: value?.title,
  //     productId: value?.productId,
  //     type: value?.type === true ? false : true,
  //     ipAddress: value?.ipAddress,
  //     domain: value?.domain,
  //     description: value?.description,
  //   };
  //   try {
  //     const resulDispatch = await dispatch(updateVersion({ id, data }));
  //     unwrapResult(resulDispatch);
  //     if (resulDispatch.payload.success === true) {
  //       notification.success({
  //         message: `Thay đổi Type version thành công !!!`,
  //       });
  //       history.push(`${ROUTER.PRODUCT.VERSION.LIST}/${idEdition}`);
  //     }
  //   } catch (error) {
  //     notification.error({ message: `Error: ${error.message}` });
  //   }
  // };
  const columns = [
    { dataIndex: "title", title: "Version", type: "center" },
    {
      dataIndex: "productTitle",
      title: "Product",
    },
    {
      dataIndex: "domain",
      title: "Domain",
      render: (value) => (
        <span className="cu font-medium">{value ? value : "-"}</span>
      ),
    },
    {
      dataIndex: "ipAddress",
      title: "Địa chỉ IP",
      render: (value) => (
        <span className="cu font-medium">{value ? value : "-"}</span>
      ),
    },
    {
      dataIndex: "type",
      title: "Type",
      render: (value) => (
        <span className="font-medium">
          {value === true ? "Online" : "Offline"}
        </span>
      ),
    },
    // {
    //   dataIndex: "description",
    //   title: "Note",
    //   type: "left",
    //   render: (value) =>
    //     value?.length >= 20 ? (
    //       <Popover
    //         content={
    //           <>
    //             <div className="max-w-[250px] break-words">
    //               <h1 className="border-b-2 text-xl font-semibold">Note </h1>
    //               <p> {value}</p>
    //             </div>
    //           </>
    //         }
    //       >
    //         <span className="font-medium">
    //           {value.substring(0, 20) + " ..."}
    //         </span>
    //       </Popover>
    //     ) : (
    //       <span className="font-medium">{value}</span>
    //     ),
    // },
  ];
  const onDelete = async (id) => {
    try {
      const resultAction = await dispatch(deleteVersion(id));
      unwrapResult(resultAction);
      notification.success({ message: `Delete successfully!` });
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };
  const fecthSearch = (value) => {
    if (value) {
      props.history.push(
        `${ROUTER.PRODUCT.EDITION.VERSION.LIST}/${idEdition}?keyword=${value}`
      );
    } else {
      props.history.push(`${ROUTER.PRODUCT.EDITION.VERSION.LIST}/${idEdition}`);
      dispatch(getVersionById({ id: idEdition, params: null }));
    }
  };
  const debounceSearch = useCallback(debounce(fecthSearch, 1000), []);
  const onSearch = (e) => {
    setValueSearch(e.target.value.replace(/[&%*?]/g, ""));
    debounceSearch(e.target.value.replace(/[&%?]/g, ""));
  };

  const onChangePage = (page, size) => {
    const { search } = props.history.location;
    if (search !== "") {
      let params;
      const filterOptions = search.split("?")[1];
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`;
      }
      const obj = JSON.parse(params);
      params = { ...obj, page, size };
      const urlParameters = [];
      Object.entries(params).forEach((e) => {
        if (e[1] !== undefined && e[1] !== "") {
          urlParameters.push(e.join("="));
        }
      });
      props.history.push(
        `${ROUTER.PRODUCT.EDITION.VERSION.LIST}/${idEdition}`.concat(
          `?${urlParameters.join("&")}`
        )
      );
    } else {
      props.history.push(
        `${ROUTER.PRODUCT.EDITION.VERSION.LIST}/${idEdition}`.concat(
          `?page=${page}&size=${size}`
        )
      );
    }
  };

  return (
    <>
      <div className="overflow-x-auto mb-10 mt-4">
        <div className="flex justify-center mb-3 bg-[#262626] px-4 py-8 rounded-[10px]">
          <div className="w-full">
            <div className="w-full mb-3 md:flex md:justify-between">
              <div>
                <NavLink
                  to={`${ROUTER.PRODUCT.EDITION.VERSION.LIST}/${idEdition}/add`}
                >
                  <ButtonSubmit
                    // onClick={() => history.push(ROUTER.VERSION.ADD)}
                    iconButton={<i className="fas fa-plus pr-[15px]"></i>}
                    titleButton="Add new"
                  />
                </NavLink>
              </div>
              <Form form={form}>
                <div className="md:flex md:justify-end">
                  <Form.Item name="searchName">
                    <InputSearch
                      placeholder="Search version..."
                      onChange={onSearch}
                      valueSearch={valueSearch}
                    />
                  </Form.Item>
                </div>
              </Form>
            </div>
            <div>
              <TableAdmin
                columns={columns}
                dataTable={listVersion}
                Url={`${ROUTER.PRODUCT.EDITION.VERSION.LIST}/${idEdition}/edit`}
                spinning={isLoading}
                onDelete={onDelete}
                // handlePagination={(v) =>
                //   onChangePage(v?.currentPage, v?.itemsPerPage)
                // }
                // totalItems={meta?.total}
                meta={meta}
                onChangePage={onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VersionList;
