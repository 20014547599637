import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker, Select, ConfigProvider } from "antd";
import FormModal from "./../../../components/FormModal/FormModal";
import moment from "moment";
import { useForm } from "react-hook-form";
import { getAllProduct } from "../../Product/ProductSlice";
import { setFilters } from "../LicenseKeySlice";
import { getAllOrganization } from "../../Organization/OrganizationSlice";
import Api from "../../../Utils/Api";
import { InfoCircleOutlined, CalendarOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Option } = Select;
const LicenseKeyFilter = (props) => {
  const {
    handleSubmit,
    register,
    resetField,
    setValue,
    setError,
    formState: { errors },
  } = useForm();
  const [startDate, setStartDate] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [datePickerValue, setDatePickerValue] = useState(null);
  const [idOrganization, setIdOrganization] = useState(null);
  const [idDepartment, setIdDepartment] = useState(null);
  const [listDepartmentByOrganization, setListDepartmentByOrganization] =
    useState([]);
  const [listCustomer, setListCustomer] = useState([]);
  const dispatch = useDispatch();
  const { openModal, handleCancel } = props;
  const listAllProduct = useSelector((state) => state?.product?.items);
  const listAllOrganization = useSelector((state) => state.organization.items);

  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );

  useEffect(() => {
    dispatch(getAllOrganization());
    dispatch(getAllProduct());
  }, [dispatch]);

  const getDepartmentByOrganization = async (id) => {
    try {
      let response = await Api.get(`/api/department/${id}/organization`);
      if (response.status === 200) {
        setListDepartmentByOrganization(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (idOrganization !== null) {
      getDepartmentByOrganization(idOrganization);
    }
  }, [idOrganization]);

  const getCustomerByOrganization = async (idAccount, idOrganization) => {
    try {
      let response = await Api.get(
        `/api/customer/${idAccount}/organization/${idOrganization}`
      );
      if (response.status === 200) {
        setListCustomer(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (idOrganization && !idDepartment) {
      const idAccount = accountDetail?.id;
      getCustomerByOrganization(idAccount, idOrganization);
    }
  }, [idOrganization, idDepartment, accountDetail]);

  const getCustomerByDepartment = async (idAccount, idDepartment) => {
    try {
      let response = await Api.get(
        `/api/customer/${idAccount}/department/${idDepartment}`
      );
      if (response.status === 200) {
        setListCustomer(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (idDepartment !== null) {
      const idAccount = accountDetail?.id;
      getCustomerByDepartment(idAccount, idDepartment);
    }
  }, [accountDetail, idDepartment]);
  const onSubmit = async (value) => {
    if (value) {
      if (idOrganization && !value?.customerId) {
        setError("customerId", {
          type: "customerId_err",
          message: "Vui lòng chọn khách hàng cần lọc",
        });
      } else {
        dispatch(
          setFilters({
            customerId: value?.customerId,
            productId: value?.productId,
            licenseType: value?.licenseType,
            startdate: startDate ? moment(startDate)?.format("YYYY-MM-DD") : "",
            enddate: dateEnd ? moment(dateEnd)?.format("YYYY-MM-DD") : "",
          })
        );
        await handleCancel();
      }
    } else {
      dispatch(
        setFilters({
          customerId: "",
          productId: "",
          licenseType: "",
          startdate: "",
          enddate: "",
        })
      );
      await handleCancel();
    }
  };

  const onChangeDate = (e) => {
    if (e && e.length > 0 && e[0]) {
      setStartDate(moment(e[0]));
    }
    if (e && e.length > 0 && e[1]) {
      setDateEnd(moment(e[1]));
    }
    setDatePickerValue(e);
  };

  const onHandleCancel = async () => {
    await handleCancel();
  };
  const onHandleReset = () => {
    resetField("productId");
    resetField("licenseType");
    setIdOrganization(null);
    setIdDepartment(null);
    resetField("customerId");
    setStartDate("");
    setDateEnd("");
    setDatePickerValue(null);
    setListDepartmentByOrganization([]);
    setListCustomer([]);
  };
  const onChangeOrganization = (value) => {
    setIdOrganization(value);
    setIdDepartment(null);
    setListCustomer([]);
    setListDepartmentByOrganization([]);
    setValue("customerId", "", { shouldDirty: true });
  };
  const onChangeDepartment = (value) => {
    setIdDepartment(value);
    setListCustomer([]);
    setValue("customerId", "", { shouldDirty: true });
  };
  return (
    <div>
      <FormModal
        titleModal={"Filter the data "}
        titleForm={"licensefilter"}
        visible={openModal}
        okText="Apply"
        handleCancel={onHandleCancel}
        handleReset={onHandleReset}
        width={"90%"}
        className="modal-800"
      >
        <ConfigProvider
          renderEmpty={() => (
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <InfoCircleOutlined style={{ fontSize: 30, color: "white" }} />
              <p className="text-white mt-1">Trống</p>
            </div>
          )}
        >
          <form onSubmit={handleSubmit(onSubmit)} id="licensefilter">
            <div className="flex flex-wrap mb-6">
              <div className="w-full px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Filter the data 
                </label>
                <RangePicker
                  placeholder={["Start time", "End time"]}
                  suffixIcon={<CalendarOutlined className="text-white" />}
                  allowClear={false}
                  format="DD/MM/YYYY"
                  onChange={onChangeDate}
                  value={datePickerValue}
                  style={{
                    borderRadius: "6px",
                    width: "100%",
                    height: "40px",
                    backgroundColor: "#414141",
                  }}
                  className="custom-datepicker hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-6">
              <div className="w-1/2 px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                Filter by product
                </label>
                <select
                  {...register("productId")}
                  className=" text-white bg-[#414141]  border h-[40px] pl-[10px] pr-8 rounded-md w-full hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                >
                  {listAllProduct &&
                    listAllProduct.map((item) => {
                      return (
                        <>
                          <option value="" disabled selected hidden>
                            Select product
                          </option>
                          <option key={item.id} value={item.id}>
                            {item.title}
                          </option>
                          ;
                        </>
                      );
                    })}
                </select>
              </div>
              <div className="w-1/2 px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Filter by LicenseKey type
                </label>
                <select
                  {...register("licenseType")}
                  className="text-white bg-[#414141] border h-[40px] pl-[10px] pr-8 rounded-md w-full hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                >
                  <option value="" disabled selected hidden>
                    Select licenseKey type
                  </option>
                  <option value="online">Online</option>
                  <option value="offline">Offline</option>
                  <option value="other">Orther product</option>
                </select>
              </div>
            </div>
            <label className=" text-white text-sm font-bold mt-3 mb-3 px-3">
              Filter by customer
            </label>
            <div className="grid grid-cols-5 mb-6">
              <div className="col-span-2">
                <div className="pl-3">
                  <label className="w-1/4 font-bold text-white text-sm  mb-2">
                    Organization
                  </label>
                  <Select
                    className="ant-select-custom border pl-[10px] w-2/3 pr-8 h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    showSearch
                    allowClear
                    placeholder="Choose an organization"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={onChangeOrganization}
                    value={idOrganization}
                  >
                    {listAllOrganization?.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.title}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div className="pl-3 pt-4">
                  <label className="w-1/4 font-bold text-white text-sm mb-2">
                    Phòng
                  </label>
                  <Select
                    className="ant-select-custom border pl-[10px] w-2/3 pr-8 h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    showSearch
                    allowClear
                    placeholder="Choose a department"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={idDepartment}
                    onChange={onChangeDepartment}
                  >
                    {listDepartmentByOrganization?.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.title}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="col-span-3">
                <div className="grid grid-cols-6 mb-6 ">
                  <div className="col-span-1 w-full ml-3 px-3 mt-[30px]">
                    <i className="fas fa-angle-double-right fa-3x text-white  "></i>
                  </div>
                  <div className="col-span-5">
                    <div className="w-full px-3">
                      <label className=" text-white text-sm font-bold mb-2">
                        Customer
                      </label>
                      <select
                        {...register("customerId")}
                        className="text-white bg-[#414141] border h-[40px] pl-[10px] pr-8 rounded-md w-full hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      >
                        {listCustomer &&
                          listCustomer.map((item) => {
                            return (
                              <>
                                <option value="" disabled selected hidden>
                                  Choose a customer 
                                </option>
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                                ;
                              </>
                            );
                          })}
                      </select>
                      <div className="mt-1 h-[10px]">
                        <span className="text-red-500">
                          {errors?.customerId &&
                          errors.customerId.type === "customerId_err"
                            ? errors.customerId.message
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ConfigProvider>
      </FormModal>
    </div>
  );
};

export default LicenseKeyFilter;
