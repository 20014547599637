import { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Upload, Image, Button, message, notification, Switch } from "antd";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import ButtonCancel from "../../../components/Button/ButtonCancel";
import { useParams } from "react-router-dom";
import Api from "../../../Utils/Api";
import { API_URL } from "./../../../Utils/Config";
import { unwrapResult } from "@reduxjs/toolkit";
import avtUser from "../../../assets/image/user.png";
import ROUTER from "../../../routers/routerLink";
import { getRoleByIdAccount } from "./../../Role/RoleSilce";
import { getUserInfo } from "./../../../Utils/localStorage/GetUserInfo";
import { beforeUploadImage } from "../../../components/UploadFile/beforeUploadImage";
import { updateAccount } from "../AccountSlice";
import {
  validateEmail,
  validatePhoneNumber,
  validateInputName,
  isStrongPassword,
} from "../../../Utils/Validate/validateInput";
import InputPassword from "./../../../components/InputPassword/inputPassword";

const AccountEdit = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = getUserInfo("token");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [listShareAccount, setListShareAccount] = useState([]);
  const [idRole, setIdRole] = useState(null);
  const [RoleCodeById, setRoleCodeById] = useState("");
  const [active, setActive] = useState(true);
  const [avatar, setAvatar] = useState("");
  const listRoleByIdAccount = useSelector((state) => state.role.items);
  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );
  const {
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (value) => {
    if (value.password !== value.cf_password) {
      setError("cf_password", {
        type: "cf_pass_err",
        message: "Re-confirm password không trùng khớp",
      });
    } else {
      const data = {
        email: value?.email,
        password: value?.password ? value?.password : "",
        name: value?.name?.trim(),
        phone: value?.phone,
        roleId: value?.roleId,
        active: active,
        avatar: avatar ? avatar : "",
        parentAccountId: value?.parentAccountId,
        updatedBy: accountDetail?.id
      };
      try {
        const resulDispatch = await dispatch(updateAccount({ id, data }));
        unwrapResult(resulDispatch);
        if (resulDispatch.payload.success === true) {
          notification.success({ message: `Edit success!!!` });
          history.push(ROUTER.ACCOUNT.LIST);
        }
      } catch (error) {
        if (error.message === "Rejected") {
          notification.error({
            message: `Error: Please check your network connection again!`,
          });
        } else {
          notification.error({ message: `Error: ${error.message}` });
        }
      }
    }
  };
  const getShareAccount = async () => {
    try {
      let response = await Api.get(`api/account/getShareAccount`);
      if (response.status === 200) {
        setListShareAccount(response?.data?.data);
      }
    } catch (error) {}
  };
  const onChangeRole = (e) => {
    setIdRole(e.target.value);
  };
  const getRoleById = async (id) => {
    try {
      let response = await Api.get(`api/role/getRole/${id}`);
      if (response.status === 200) {
        setRoleCodeById(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getShareAccount();
  }, []);
  useEffect(() => {
    if (idRole !== null) {
      getRoleById(idRole);
    }
  }, [idRole]);

  const getInfoAccountById = async (id) => {
    try {
      let response = await Api.get(`api/account/getbyid/${id}`);
      if (response.status === 200) {
        setIdRole(response?.data?.data?.roleId);
        setActive(response?.data?.data.active);
        setAvatar(response?.data?.data.avatar);
        setValue("password", "", { shouldDirty: true });
        reset(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      getInfoAccountById(id);
    }
    const idAccount = accountDetail?.id;
    dispatch(getRoleByIdAccount(idAccount));
  }, [dispatch, accountDetail]);

  const uploadImage = {
    name: "file",
    action: `${API_URL}/api/account/uploadimage`,
    multiple: true,
    beforeUpload: beforeUploadImage,
    showUploadList: false,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onChange(info) {
      if (info.file.status === "done") {
        setAvatar(info.file.response.data);
        message.success(`Tải ảnh lên thành công !`);
      } else if (info.file.status === "error") {
        message.error(`Tải ảnh thất bại`);
      }
    },
  };
  return (
    <>
      <div className="flex justify-center ">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[1400px] overflow-hidden shadow-lg bg-[#262626] rounded-[10px]"
        >
          <div className="grid grid-cols-7 gap-[10px] mx-[30px] mt-[20px] xl:mx-[60px] lg:mt-[50px]">
            <div className="col-span-full md:col-span-5">
              <div className="flex flex-wrap md:mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className=" text-white text-sm font-bold mb-2">
                    Full name
                    <span className="text-red-600 font-extrabold text-[17px] ml-1">
                      *
                    </span>
                  </label>
                  <input
                    className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                      errors?.name
                        ? "focus:ring-red-500 focus:ring-1"
                        : "focus:ring-purple-500 focus:ring-2"
                    } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                    type="text"
                    placeholder="Full name"
                    {...register("name", {
                      required: true,
                      pattern: validateInputName,
                    })}
                  />
                  <div className="mt-1 h-[10px]">
                    <span className="text-red-500">
                      {errors?.name && errors?.name.type === "required"
                        ? "Please enter your full name"
                        : ""}
                      {errors?.name && errors.name.type === "pattern"
                        ? "Must not contain numbers and special characters"
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className=" text-white text-sm font-bold mb-2">
                    Email
                    <span className="text-red-600 font-extrabold text-[17px] ml-1">
                      *
                    </span>
                  </label>
                  <input
                    className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                      errors?.email
                        ? "focus:ring-red-500 focus:ring-1"
                        : "focus:ring-purple-500 focus:ring-2"
                    } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                    // type="email"
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      pattern: validateEmail,
                    })}
                  />
                  <div className="mt-1 h-[10px]">
                    <span className="text-red-500">
                      {errors?.email && errors?.email.type === "required"
                        ? "Please enter your email address"
                        : ""}
                      {errors?.email && errors.email.type === "pattern"
                        ? "Định dạng Email không hợp lệ"
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap md:mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className=" text-white text-sm font-bold mb-2">
                    Role
                    <span className="text-red-600 font-extrabold text-[17px] ml-1">
                      *
                    </span>
                  </label>
                  <select
                    {...register("roleId", { required: true })}
                    className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                      errors?.roleId
                        ? "focus:ring-red-500 focus:ring-1"
                        : "focus:ring-purple-500 focus:ring-2"
                    } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent capitalize`}
                    onChange={onChangeRole}
                  >
                    {listRoleByIdAccount &&
                      listRoleByIdAccount.map((item) => {
                        return (
                          <>
                            <option value="" disabled selected hidden>
                              Choose a role
                            </option>
                            <option
                              className="capitalize"
                              key={item.id}
                              value={item.id}
                            >
                              {item.title}
                            </option>
                            ;
                          </>
                        );
                      })}
                  </select>
                  <div className="mt-1 h-[10px]">
                    <span className="text-red-500">
                      {errors?.roleId && errors?.roleId.type === "required"
                        ? "Choose a role"
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className=" text-white text-sm font-bold mb-2">
                    Phone
                  </label>
                  <input
                    className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                      errors?.phone
                        ? "focus:ring-red-500 focus:ring-1"
                        : "focus:ring-purple-500 focus:ring-2"
                    } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                    type="text"
                    placeholder="Phone"
                    {...register("phone", {
                      // required: true,
                      pattern: validatePhoneNumber,
                    })}
                  />
                  <div className="mt-1 h-[10px]">
                    <span className="text-red-500">
                      {errors?.phone && errors.phone.type === "pattern"
                        ? "Invalid phone number"
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap md:mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0  ">
                  <label className=" text-white text-sm font-bold mb-2">
                    Password
                    {/* <span className="text-red-600 font-extrabold text-[17px] ml-1">
                      *
                    </span> */}
                  </label>
                  <InputPassword
                    showPassword={showPassword}
                    setShowPassWord={setShowPassword}
                    color="white"
                  >
                    <input
                      className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                        errors?.password
                          ? "focus:ring-red-500 focus:ring-1"
                          : "focus:ring-purple-500 focus:ring-2"
                      } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      {...register("password", { pattern: isStrongPassword })}
                    />
                  </InputPassword>
                  <div className="mt-1 h-[10px]">
                    <span className="text-red-500">
                      {errors?.password && errors.password.type === "pattern"
                        ? "Password must be at least 8 characters including letters, numbers and characters"
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className=" text-white text-sm font-bold mb-2">
                    Re-confirm password
                    {/* <span className="text-red-600 font-extrabold text-[17px] ml-1">
                      *
                    </span> */}
                  </label>
                  <InputPassword
                    showPassword={showConfirmPassword}
                    setShowPassWord={setShowConfirmPassword}
                    color="white"
                  >
                    <input
                      className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                        errors?.cf_password
                          ? "focus:ring-red-500 focus:ring-1"
                          : "focus:ring-purple-500 focus:ring-2"
                      } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Re-confirm password"
                      {...register("cf_password")}
                    />
                  </InputPassword>
                  <div className="mt-1 h-[10px]">
                    <span className="text-red-500">
                      {(errors.cf_password && errors.cf_password.type) ===
                      "cf_pass_err"
                        ? errors.cf_password.message
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap md:mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 ">
                  <label className="w-full text-white text-sm font-bold mb-2">
                    active
                  </label>
                  <Switch
                    checked={active}
                    onChange={(value) => setActive(value)}
                  />
                </div>
                {(accountDetail?.roleCode === "director" ||
                  accountDetail?.roleCode === "superadmin") &&
                (RoleCodeById === "employee" || RoleCodeById === "deputy") ? (
                  <>
                    <div className="w-full md:w-1/2 px-3">
                      <label className=" text-white text-sm font-bold">
                        Quản lý
                        <span className="text-red-600 font-extrabold text-[17px] ml-1">
                          *
                        </span>
                      </label>
                      <select
                        {...register("parentAccountId", { required: true })}
                        className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                          errors?.parentAccountId
                            ? "focus:ring-red-500 focus:ring-1"
                            : "focus:ring-purple-500 focus:ring-2"
                        } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent capitalize`}
                      >
                        {listShareAccount &&
                          listShareAccount.map((item) => {
                            return (
                              <>
                                <option value="" disabled selected hidden>
                                  Choose a department head
                                </option>
                                <option
                                  className="capitalize"
                                  key={item.id}
                                  value={item.id}
                                >
                                  {item.name}
                                </option>
                              </>
                            );
                          })}
                      </select>
                      <div className="mt-1 h-[10px]">
                        <span className="text-red-500">
                          {errors?.parentAccountId &&
                          errors?.parentAccountId.type === "required"
                            ? "Please choose a manager"
                            : ""}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-span-full md:col-span-2 xl:ml-[10px] flex items-center flex-col mb-6 md:mb-0">
              <div className="mb-[15px] md:mt-[30px]">
                <Image
                  src={avatar ? `${API_URL}/${avatar}` : avtUser}
                  style={{
                    height: 200,
                    width: 200,
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              </div>

              <Upload {...uploadImage}>
                <Button icon={<UploadOutlined />}>Choose a photo</Button>
              </Upload>
            </div>
          </div>
          <div className="flex justify-center mb-[50px]">
            <div className="mx-2">
              <ButtonCancel onClick={() => history.goBack()} />
            </div>
            <div className="mx-2">
              <ButtonSubmit titleButton="Confirm" />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AccountEdit;
