import React, {  useCallback, useEffect } from "react";
import {  NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import InputSearch from "../../../components/InputSearch/InputSearch";
import ROUTER from "../../../routers/routerLink";
import { notification, Popover } from "antd";
import { debounce } from "lodash";
import { unwrapResult } from "@reduxjs/toolkit";
import { getDepartment, deleteDepartment } from "./../DeparmentSlice";
import TableAdmin from "./../../../components/Table/TableAdmin";

const DepartmentList = (props) => {
  const dispatch = useDispatch();
  const listDepartment = useSelector((state) => state?.department?.items);
  const meta = useSelector((state) => state?.department?.meta);
  const isLoading = useSelector((state) => state?.department?.isLoading);
  useEffect(() => {
    const { search } = props.history.location;
    let params;
    const filterOptions = search.split("?")[1];
    if (filterOptions !== undefined) {
      params = `{"${decodeURI(filterOptions)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`;
      dispatch(getDepartment(JSON.parse(params)));
    } else {
      dispatch(getDepartment(null));
    }
  }, [dispatch, props.history.location]);
  const columns = [
    { dataIndex: "title", title: "Department name", sorting: true, type: "left" },
    {
      dataIndex: "organizationTitle",
      title: "Organization Name",
      sorting: true,
      type: "left",
    },
    {
      dataIndex: "note",
      title: "Note",
      type: "left",
      render: (value) =>
        value?.length >= 20 ? (
          <Popover
            content={
              <>
                <div className="max-w-[250px] break-words">
                  <h1 className="border-b-2 text-xl font-semibold">Note </h1>
                  <p> {value}</p>
                </div>
              </>
            }
          >
            <span className="font-medium">
              {value.substring(0, 20) + " ..."}
            </span>
          </Popover>
        ) : (
          <span className="font-medium">{value}</span>
        ),
    },
  ];
  const onDelete = async (id) => {
    try {
      const resultAction = await dispatch(deleteDepartment(id));
      unwrapResult(resultAction);
      notification.success({ message: `Delete successfully!` });
    } catch (error) {
      notification.error({ message: `Error: ${error.message}` });
    }
  };
  const fecthSearch = (value) => {
    if (value) {
      props.history.push(ROUTER.DEPARTMENT.LIST.concat(`?keyword=${value}`));
    } else {
      props.history.push(ROUTER.DEPARTMENT.LIST);
      dispatch(getDepartment(null));
    }
  };
  const debounceSearch = useCallback(debounce(fecthSearch, 1000), []);
  const onSearch = (e) => {
    debounceSearch(e.target.value);
  };
  const onChangePage = (page, size) => {
    const { search } = props.history.location;
    if (search !== "") {
      let params;
      const filterOptions = search.split("?")[1];
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`;
      }
      const obj = JSON.parse(params);
      params = { ...obj, page, size };
      const urlParameters = [];
      Object.entries(params).forEach((e) => {
        if (e[1] !== undefined && e[1] !== "") {
          urlParameters.push(e.join("="));
        }
      });
      props.history.push(
        ROUTER.DEPARTMENT.LIST.concat(`?${urlParameters.join("&")}`)
      );
    } else {
      props.history.push(
        ROUTER.DEPARTMENT.LIST.concat(`?page=${page}&size=${size}`)
      );
    }
  };
  return (
    <>
      <div className="overflow-x-auto  mb-10 mt-4">
        <div className="flex justify-center mb-3 bg-[#262626] px-4 py-8 rounded-[10px]">
          <div className="w-full">
            <div className="w-full mb-3 md:flex md:justify-between">
              <div>
                <NavLink to={ROUTER.DEPARTMENT.ADD}>
                  <ButtonSubmit
                    iconButton={<i className="fas fa-plus pr-[15px]"></i>}
                    titleButton="Add new"
                  />
                </NavLink>
              </div>
              <div className="md:flex md:justify-end ">
                <InputSearch onChange={onSearch} />
              </div>
            </div>
            <div>
              <TableAdmin
                Url={ROUTER.DEPARTMENT.EDIT}
                onDelete={onDelete}
                columns={columns}
                spinning={isLoading}
                dataTable={listDepartment}
                meta={meta}
                onChangePage={onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentList;
