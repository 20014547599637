import React, { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ButtonCancel from "./../../../components/Button/ButtonCancel";
import ButtonSubmit from "./../../../components/Button/ButtonSubmit";
import {
  notification,
  Popover,
  DatePicker,
  Modal,
  Tooltip,
  ConfigProvider,
  Image,
  Button,
  Upload,
  Select,
  Switch,
} from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import ROUTER from "../../../routers/routerLink";
import Api from "../../../Utils/Api";
import { getAllLicenseKeyCategory } from "../../LicenseKeyCategory/LicenseKeyCategorySlice";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { updateLicenseKey } from "./../LicenseKeySlice";
import { validateEmail } from "../../../Utils/Validate/validateInput";
import generator from "generate-password";
import {
  CloseOutlined,
  InfoCircleOutlined,
  CalendarOutlined,
  DeleteOutlined,
  UploadOutlined,
  CloudDownloadOutlined,
  VerticalAlignBottomOutlined,
  DeleteTwoTone,
  FileTwoTone,
} from "@ant-design/icons";
import { API_URL } from "./../../../Utils/Config";
import { getUserInfo } from "./../../../Utils/localStorage/GetUserInfo";
import { beforeUploadFile } from "./../../../components/UploadFile/beforeUploadFile";
import { getAllOrganization } from "../../Organization/OrganizationSlice";
import { saveAs } from "file-saver";
import Creatable from "react-select/creatable";
import { addEdition } from "../../Edition/EditionSlice";
import { addProduct } from "./../../Product/ProductSlice";
import { addVersion } from "../../Version/VersionSlice";

const { Option } = Select;
const LicenseKeyEdit = () => {
  const token = getUserInfo("token");
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [copyToClipboard, setCopyToClipboard] = useState({
    value: "",
    copied: false,
  });
  const [copyToClipboardLastKey, setCopyToClipboardLastKey] = useState({
    value: "",
    copied: false,
  });
  const [downloadLastKeyNew, setDownloadLastKeyNew] = useState("");
  const [downloadLastKeyOld, setDownloadLastKeyOld] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [startDate, setStartDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [startDateNotHtsc, setStartDateNotHtsc] = useState("");
  const [expirationDateNotHtsc, setExpirationDateNotHtsc] = useState("");
  const [startDateVersionOff, setStartDateVersionOff] = useState("");
  const [expirationDateVersionOff, setExpirationDateVersionOff] = useState("");
  const [lastKeyOld, setLastKeyOld] = useState("");
  const [lastKey, setLastKey] = useState("");
  const [infoCustomer, setInfoCustomer] = useState(null);
  const [infoVersion, setInfoVersion] = useState(null);
  const [infoEdition, setInfoEdition] = useState(null);
  const [idCustomer, setIdCustomer] = useState(null);
  const [idVersion, setIdVersion] = useState(null);
  const [isHtsc, setIsHtsc] = useState(null);
  const [listFileUpload, setListFileUpload] = useState([]);
  const [urlFilesRemove, seturlFilesRemove] = useState([]);
  const [defaultUrlFiles, setDefaultUrlFiles] = useState([]);
  const [listProductNotHtsc, setListProductNotHtsc] = useState([]);
  const [listEditionByProductNotHtsc, setListEditionByProductNotHtsc] =
    useState([]);
  const [listVersionByEditionNotHtsc, setListVersionByEditionNotHtsc] =
    useState([]);
  const [idProductNotHtsc, setIdProductNotHtsc] = useState(null);
  const [idEditionNotHtsc, setIdEditionNotHtsc] = useState(null);
  const [idVersionNotHtsc, setIdVersionNotHtsc] = useState(null);
  const [idOrganization, setIdOrganization] = useState(null);
  const [idDepartment, setIdDepartment] = useState(null);
  const [listCustomer, setListCustomer] = useState([]);
  const [disableUpload, setDisableUpload] = useState(false);
  const [listDepartmentByOrganization, setListDepartmentByOrganization] =
    useState([]);
  const listAllLicenseKeyCategory = useSelector(
    (state) => state?.licenseKeyCategory?.items
  );
  const listAllOrganization = useSelector((state) => state.organization.items);
  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const getLicenseById = useMemo(() => async (id) => {
    try {
      let response = await Api.get(`api/licensekey/getbyid/${id}`);
      if (response.status === 200) {
        setPassword(response?.data?.data?.password);
        setDisableUpload(response?.data?.data?.disableUpload);
        reset(response?.data?.data);
        setLastKeyOld(response?.data?.data?.lastKey);
        setCopyToClipboardLastKey({
          value: response?.data?.data?.lastKey,
          copied: false,
        });
        setIdOrganization(response?.data?.data?.organizationId);
        setIdDepartment(response?.data?.data?.departmentId);
        setIdCustomer(response?.data?.data?.customerId);
        // setListFileUpload(response?.data?.data.urlFiles);
        setDefaultUrlFiles(response?.data?.data.urlFiles);
        if (response?.data?.data?.productId) {
          setIdProductNotHtsc({
            value: response?.data?.data?.productId,
            label: response?.data?.data?.productName,
          });
        } else {
          setIdProductNotHtsc(null);
        }
        if (response?.data?.data?.editionId) {
          setIdEditionNotHtsc({
            value: response?.data?.data?.editionId,
            label: response?.data?.data?.editionName,
          });
        } else {
          setIdEditionNotHtsc(null);
        }
        if (response?.data?.data?.versionId) {
          setIdVersionNotHtsc({
            value: response?.data?.data?.versionId,
            label: response?.data?.data?.versionName,
          });
        } else {
          setIdVersionNotHtsc(null);
        }
        setIdVersion(response?.data?.data.versionId);
        if (response?.data?.data?.licenseType !== 3) {
          setIsHtsc(true);
        } else {
          setIsHtsc(false);
        }
        // setIdVersion(response?.data?.data.versionId);
        if (response?.data?.data?.licenseType === 2) {
          setStartDateVersionOff(moment(response?.data?.data.startDate));
          setExpirationDateVersionOff(
            moment(response?.data?.data.expirationDate)
          );
        } else if (response?.data?.data?.licenseType === 1) {
          setStartDate(moment(response?.data?.data.startDate));
          setExpirationDate(moment(response?.data?.data.expirationDate));
        } else {
          setStartDateNotHtsc(moment(response?.data?.data.startDate));
          setExpirationDateNotHtsc(moment(response?.data?.data.expirationDate));
        }
      }
    } catch (error) {}
  });
  useEffect(() => {
    if (id) {
      getLicenseById(id);
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getAllOrganization());
    dispatch(getAllLicenseKeyCategory());
  }, [dispatch]);

  useEffect(async () => {
    if (idOrganization) {
      try {
        let response = await Api.get(
          `/api/department/${idOrganization}/organization`
        );
        if (response.status === 200) {
          setListDepartmentByOrganization(response?.data?.data);
        }
      } catch (error) {}
    }
  }, [idOrganization]);

  const getProductNotHtsc = async () => {
    try {
      let response = await Api.get(`/api/product/getbyishtsc/not`);
      const listOption = [];
      if (response.status === 200) {
        const dataRes = response?.data?.data;

        for (let i = 0; i < dataRes.length; i++) {
          listOption.push({
            label: `${dataRes[i]?.title}`,
            value: dataRes[i]?.id,
          });
        }
        setListProductNotHtsc(listOption);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getProductNotHtsc();
  }, []);

  const getEditionByProductNotHtsc = async (id) => {
    try {
      let response = await Api.get(`/api/edition/product/${id}`);
      const listOption = [];
      if (response.status === 200) {
        const dataRes = response?.data?.data;

        for (let i = 0; i < dataRes.length; i++) {
          listOption.push({
            label: `${dataRes[i]?.title}`,
            value: dataRes[i]?.id,
          });
        }
        setListEditionByProductNotHtsc(listOption);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (idProductNotHtsc !== null) {
      getEditionByProductNotHtsc(idProductNotHtsc?.value);
    }
  }, [idProductNotHtsc]);

  useEffect(async () => {
    if (idEditionNotHtsc !== null) {
      try {
        let response = await Api.get(
          `api/edition/getbyid/${idEditionNotHtsc?.value}`
        );
        if (response.status === 200) {
          setInfoEdition(response?.data?.data);
        }
      } catch (error) {}
    }
  }, [idEditionNotHtsc]);

  const getVersionByEditionNotHtsc = async (id) => {
    try {
      let response = await Api.get(`/api/version/edition/${id}`);
      const listOption = [];
      if (response.status === 200) {
        const dataRes = response?.data?.data;

        for (let i = 0; i < dataRes.length; i++) {
          listOption.push({
            label: `${dataRes[i]?.title}`,
            value: dataRes[i]?.id,
          });
        }
        setListVersionByEditionNotHtsc(listOption);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (idEditionNotHtsc !== null) {
      getVersionByEditionNotHtsc(idEditionNotHtsc?.value);
    }
  }, [idEditionNotHtsc]);

  const getCustomerByOrganization = async (idAccount, idOrganization) => {
    try {
      let response = await Api.get(
        `/api/customer/${idAccount}/organization/${idOrganization}`
      );
      if (response.status === 200) {
        setListCustomer(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (idOrganization && !idDepartment) {
      const idAccount = accountDetail?.id;
      getCustomerByOrganization(idAccount, idOrganization);
    }
  }, [idOrganization, idDepartment, accountDetail]);

  const getCustomerByDepartment = async (idAccount, idDepartment) => {
    try {
      let response = await Api.get(
        `/api/customer/${idAccount}/department/${idDepartment}`
      );
      if (response.status === 200) {
        setListCustomer(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (idDepartment) {
      const idAccount = accountDetail?.id;
      getCustomerByDepartment(idAccount, idDepartment);
    }
  }, [accountDetail, idDepartment]);

  useEffect(() => {
    if (idCustomer !== null) {
      getCustomerById(idCustomer);
    }
  }, [idCustomer]);

  useEffect(() => {
    if (idVersion !== null) {
      getVersionById(idVersion);
    }
  }, [idVersion]);

  //export lastKey new txt
  const makeLastKeyNew = () => {
    const data = new Blob([`LicenseKey:  ${lastKey}`], { type: "text/plain" });
    if (downloadLastKeyNew !== "")
      window.URL.revokeObjectURL(downloadLastKeyNew);
    setDownloadLastKeyNew(window.URL.createObjectURL(data));
  };
  useEffect(() => {
    makeLastKeyNew();
  }, [lastKey]);

  //export lastKey old txt "User: ađâsđá", "\n", "Password: đâsđâsád"
  const makeLastKeyOld = () => {
    const data = new Blob([`LicenseKey:  ${lastKeyOld}`], {
      type: "text/plain",
    });
    if (downloadLastKeyOld !== "")
      window.URL.revokeObjectURL(downloadLastKeyOld);
    setDownloadLastKeyOld(window.URL.createObjectURL(data));
  };
  useEffect(() => {
    makeLastKeyOld();
  }, [lastKeyOld]);

  const handleOk = () => {
    setIsModalVisible(false);
    history.push(ROUTER.LICENSEKEY.LIST);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    history.push(ROUTER.LICENSEKEY.LIST);
  };

  const getCustomerById = async (idCustomer) => {
    try {
      let response = await Api.get(`api/customer/getbyid/${idCustomer}`);
      if (response.status === 200) {
        setInfoCustomer(response?.data?.data);
        setValue("customerId", response?.data?.data?.id, { shouldDirty: true });
      }
    } catch (error) {}
  };

  const getVersionById = async (idVersion) => {
    try {
      let response = await Api.get(`api/version/getbyid/${idVersion}`);
      if (response.status === 200) {
        setInfoVersion(response?.data?.data);
      }
    } catch (error) {}
  };

  const onChangeExpirationDate = (e) => {
    setExpirationDate(moment(e));
  };

  const onChangeStartDate = (e) => {
    setStartDate(moment(e));
  };
  const onChangeExpirationDateNotHtsc = (e) => {
    setExpirationDateNotHtsc(moment(e));
  };

  const onChangeStartDateNotHtsc = (e) => {
    setStartDateNotHtsc(moment(e));
  };
  const onChangeStartDateOff = (e) => {
    setStartDateVersionOff(moment(e));
  };
  const onChangeExpirationDateOff = (e) => {
    setExpirationDateVersionOff(moment(e));
  };

  const onChangeProductNotHtsc = (value) => {
    setIdProductNotHtsc(value);
    setListVersionByEditionNotHtsc([]);
    setIdVersionNotHtsc(null);
    setIdEditionNotHtsc(null);
  };
  const onChangeEditionNotHtsc = (value) => {
    setIdEditionNotHtsc(value);
    setIdVersionNotHtsc(null);
  };
  const onChangeVersionNotHtsc = (value) => {
    setIdVersionNotHtsc(value);
  };
  const onChangeCustomer = (value) => {
    setIdCustomer(value);
    setInfoCustomer(null);
  };
  const onChangeDepartment = (value) => {
    setIdDepartment(value);
    setIdCustomer(null);
    setListCustomer([]);
  };

  const onChangeOrganization = (value) => {
    setIdOrganization(value);
    setIdDepartment(null);
    setIdCustomer(null);
    setListCustomer([]);
    setListDepartmentByOrganization([]);
  };
  const onSubmit = async (value) => {
    if (infoEdition && infoEdition?.type === true) {
      const data = {
        productId: value?.productId,
        customerId: value?.customerId,
        editionId: value?.editionId,
        licenseKeyCategoryId: value?.licenseKeyCategoryId,
        versionId: value?.versionId,
        firstKey: "",
        avalableTime: "",
        expirationDate: expirationDate
          ? moment(expirationDate).format("YYYY-MM-DD")
          : "",
        startDate: startDate
          ? moment(startDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        email: value?.email ? value?.email : "",
        password: password ? password : "",
        note: value?.note,
        disableUpload: disableUpload
      };
      try {
        const resulDispatch = await dispatch(updateLicenseKey({ id, data }));
        unwrapResult(resulDispatch);
        if (resulDispatch.payload.success === true) {
          notification.success({ message: `Edit success!!!` });
          history.push(ROUTER.LICENSEKEY.LIST);
        }
      } catch (error) {
        if (error.message === "Rejected") {
          notification.error({
            message: `Error: Please check your network connection again!`,
          });
        } else {
          notification.error({ message: `Error: ${error.message}` });
        }
      }
    } else if (infoEdition && infoEdition?.type === false) {
      const data = {
        productId: value?.productId,
        customerId: value?.customerId,
        licenseKeyCategoryId: value?.licenseKeyCategoryId,
        versionId: value?.versionId,
        editionId: value?.editionId,
        firstKey: value?.firstKey ? value?.firstKey : "",
        avalableTime: value?.avalableTime ? parseInt(value?.avalableTime) : 0,
        expirationDate: moment(expirationDateVersionOff).format("YYYY-MM-DD"),
        startDate: moment(startDateVersionOff).format("YYYY-MM-DD"),
        email: "",
        password: "",
        // isActive: true,
        note: value?.note,
      };
      try {
        const resulDispatch = await dispatch(updateLicenseKey({ id, data }));
        unwrapResult(resulDispatch);
        if (resulDispatch.payload.success === true) {
          notification.success({ message: `Edit success!!!` });
          if (resulDispatch?.payload?.data?.lastKey === null) {
            history.push(ROUTER.LICENSEKEY.LIST);
          } else {
            setCopyToClipboard({
              value: resulDispatch?.payload?.data?.lastKey,
              copied: false,
            });
            setLastKey(resulDispatch?.payload?.data?.lastKey);
            if (value?.lastKey !== resulDispatch?.payload?.data?.lastKey) {
              setIsModalVisible(true);
            } else {
              history.push(ROUTER.LICENSEKEY.LIST);
            }
          }
        }
      } catch (error) {
        if (error.message === "Rejected") {
          notification.error({
            message: `Error: Please check your network connection again!`,
          });
        } else {
          notification.error({ message: `Error: ${error.message}` });
        }
      }
    } else {
      let remainFile = listFileUpload.filter(
        (x) => urlFilesRemove.indexOf(x) === -1
      );
      const data = {
        productId: idProductNotHtsc?.value,
        editionId: idEditionNotHtsc?.value,
        versionId: idVersionNotHtsc?.value,
        customerId: idCustomer,
        organizationId: idOrganization,
        departmentId: idDepartment,
        licenseKeyCategoryId: value?.licenseKeyCategoryId,
        lastKey: value?.lastKey ? value?.lastKey : "",
        expirationDate: moment(expirationDateNotHtsc).format("YYYY-MM-DD"),
        startDate: moment(startDateNotHtsc).format("YYYY-MM-DD"),
        email: value?.email ? value?.email : "",
        password: password ? password : "",
        note: value?.note,
        urlFiles: Array.from(new Set([].concat(remainFile, defaultUrlFiles))),
      };
      try {
        const resulDispatch = await dispatch(updateLicenseKey({ id, data }));
        unwrapResult(resulDispatch);
        if (resulDispatch.payload.success === true) {
          notification.success({ message: `Edit success!!!` });
          if (resulDispatch?.payload?.data?.lastKey === null) {
            history.push(ROUTER.LICENSEKEY.LIST);
          } else {
            setCopyToClipboard({
              value: resulDispatch?.payload?.data?.lastKey,
              copied: false,
            });
            setLastKey(resulDispatch?.payload?.data?.lastKey);
            if (value?.lastKey !== resulDispatch?.payload?.data?.lastKey) {
              setIsModalVisible(true);
            } else {
              history.push(ROUTER.LICENSEKEY.LIST);
            }
          }
        }
      } catch (error) {
        if (error.message === "Rejected") {
          notification.error({
            message: `Error: Please check your network connection again!`,
          });
        } else {
          notification.error({ message: `Error: ${error.message}` });
        }
      }
    }
  };
  const content = (
    <div>
      <div>
        <span className="font-semibold">
          <i className="fas fa-phone  pr-1"></i>
          SĐT
        </span>
        : {infoCustomer?.phone || "Chưa có"}
      </div>
      <div className="pt-[5px]">
        <span className="font-semibold">
          <i className="fas fa-envelope pr-1"></i>
          Email
        </span>
        : {infoCustomer?.email || "Chưa có"}
      </div>
      <div className="pt-[5px]">
        <span className="font-semibold">
          <i className="far fa-gem pr-1"></i>
          Ưu tiên
        </span>
        : {infoCustomer?.priorityTitle || "Chưa có"}
      </div>
    </div>
  );
  const contentVersion = (
    <div>
      <div>
        <span className="font-semibold">
          <i className="fas fa-code-branch pl-[2px] pr-1"></i>Version
        </span>
        : {infoVersion?.title}
      </div>
      <div>
        <span NameName=" font-semibold">
          <i className="fas fa-star-half-alt pr-1"></i>
          Domain
        </span>
        : {infoVersion?.domain || "-"}
      </div>
      <div>
        <span NameName=" font-semibold">
          <i className="fas fa-star-half-alt pr-1"></i>
          Ip
        </span>
        : {infoVersion?.ipAddress || "-"}
      </div>
    </div>
  );
  const onGeneratePassWord = () => {
    setPassword(
      generator.generate({
        length: 10,
        numbers: true,
      })
    );
  };
  const onChangePassword = (e) => {
    setPassword(e?.target?.value.replace(/[#<>]/g, ""));
  };

  const uploadFile = {
    className: "custom-antd-upload",
    name: "files",
    action: `${API_URL}/api/licensekey/uploads`,
    multiple: true,
    listType: "picture",
    beforeUpload: beforeUploadFile,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    showUploadList: true,
    onChange(info) {
      for (let i = 0; i < info.fileList.length; i++) {
        if (info.file.status === "done") {
          setListFileUpload([...listFileUpload, info?.file?.response?.data[0]]);
        }
      }
    },
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: (
        <CloudDownloadOutlined className="text-white hover:scale-110" />
      ),
      showRemoveIcon: true,
      removeIcon: <DeleteOutlined className="text-white hover:scale-110" />,
    },
    onDownload(e) {
      const dataFile = e?.response?.data[0];
      saveAs(
        `${API_URL}/${dataFile?.url}`,
        dataFile?.name?.length >= 50
          ? dataFile?.name?.substring(0, 50) + ". . ."
          : dataFile?.name
      );
    },
    onRemove(e) {
      seturlFilesRemove([
        ...urlFilesRemove,
        listFileUpload.find(
          (item) => item?.name === e?.response?.data[0]?.name
        ),
      ]);
    },
    onPreview(e) {
      window.open(`${API_URL}/${e?.response?.data[0]?.name}`);
    },
  };

  const onCreateOptionProductNotHtsc = async (value) => {
    const data = {
      title: value.trim(),
      urlImage: "",
      description: "",
      isHTSC: false,
    };
    try {
      const resulDispatch = await dispatch(addProduct(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({
          message: `New product added successfully !!!`,
        });
        getProductNotHtsc();
        setIdProductNotHtsc({
          value: resulDispatch?.payload?.data?.id,
          label: resulDispatch?.payload?.data?.title,
        });
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };
  const onCreateOptionEditionNotHtsc = async (value) => {
    const data = {
      title: value.trim(),
      productId: idProductNotHtsc?.value,
      description: "",
      type: "",
    };
    try {
      const resulDispatch = await dispatch(addEdition(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({
          message: `Add new edition of "${idProductNotHtsc?.label}" success !!!`,
        });
        getEditionByProductNotHtsc(resulDispatch?.payload?.data?.productId);
        setIdEditionNotHtsc({
          value: resulDispatch?.payload?.data?.id,
          label: resulDispatch?.payload?.data?.title,
        });
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };
  const onCreateOptionVersionNotHtsc = async (value) => {
    const data = {
      title: value.trim(),
      productId: idProductNotHtsc?.value,
      editionId: idEditionNotHtsc?.value,
      domain: "",
      ipAddress: "",
      description: "",
      urlFiles: [],
    };
    try {
      const resulDispatch = await dispatch(addVersion(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({
          message: `Add new version of "${idEditionNotHtsc?.label}" success !!!`,
        });
        getVersionByEditionNotHtsc(resulDispatch?.payload?.data?.editionId);
        setIdVersionNotHtsc({
          value: resulDispatch?.payload?.data?.id,
          label: resulDispatch?.payload?.data?.title,
        });
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Error: Please check your network connection again!`,
        });
      } else {
        notification.error({ message: `Error: ${error.message}` });
      }
    }
  };

  const saveFile = (item) => {
    saveAs(
      `${API_URL}/${item?.url}`,
      item?.name.length >= 50
        ? item?.name.substring(0, 50) + ". . ."
        : item?.name
    );
  };

  return (
    <>
      <div className="flex justify-center ">
        <Modal
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: false }}
          cancelText="Exit"
          width={"90%"}
          className="modal-800"
          centered
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close">
              <CloseOutlined />
            </Tooltip>
          }
        >
          <div className="w-full lg:flex p-2 overflow-x-auto text-center">
            <span className="text-white text-[15px] pt-[2px]">{lastKey}</span>
            <div>
              <CopyToClipboard
                text={copyToClipboard.value}
                onCopy={() => setCopyToClipboard({ copied: true })}
              >
                <Tooltip placement="bottom" title="Nhấn để copy">
                  <button className="p-1 ml-[20px] text-white rounded-lg bg-gray-700 ">
                    Copy <i className="fas fa-copy"></i>
                  </button>
                </Tooltip>
              </CopyToClipboard>
              <a
                className="p-1 ml-[5px] text-white rounded-lg bg-blue-600"
                download={`licensekey${infoCustomer?.name}.txt`}
                href={downloadLastKeyNew}
              >
                Download <i className="fas fa-download"></i>
              </a>
            </div>
          </div>
        </Modal>
        <ConfigProvider
          renderEmpty={() => (
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <InfoCircleOutlined style={{ fontSize: 30, color: "white" }} />
              <p className="text-white mt-1">Trống</p>
            </div>
          )}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full max-w-[1600px] overflow-hidden shadow-lg bg-[#262626] rounded-[10px] mb-12"
          >
            <div className="md:flex mx-[40px] mt-[30px]">
              <div className="w-full md:w-1/3 mr-2 mb-4 md:mb-0 ">
                <div className="border-[1px] border-gray-700 rounded-lg">
                  <div className="col-span-2 pt-4 mb-4">
                    <h1 className="text-lg text-white ml-3 px-3 font-normal border-l-[6px] border-blue-300">
                    Customer information
                    </h1>
                  </div>
                  {isHtsc ? (
                    <div className="px-3 pb-4">
                      <div className="w-full">
                        <label className=" text-white text-sm font-bold mb-2">
                          Organization
                        </label>
                        <input
                          className="bg-[#616161]  border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                          type="text"
                          placeholder="Organization"
                          {...register("organizationName")}
                          disabled
                        />
                      </div>
                      <div className="w-full pt-4 mb-6 md:mb-0 ">
                        <label className=" text-white text-sm font-bold mb-2">
                          Department
                        </label>
                        <input
                          className="bg-[#616161] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                          type="text"
                          placeholder="Department"
                          {...register("departmentName")}
                          disabled
                        />
                      </div>
                      <div className="w-full pt-4 mb-6 md:mb-0 ">
                        <div className="flex">
                          <label className="text-white text-sm font-bold mb-2">
                            Customer
                          </label>
                          <div
                            className={`${
                              infoCustomer === null ? "hidden" : ""
                            } ml-auto cursor-pointer`}
                          >
                            <Popover content={content} title="Information">
                              <i className="far fa-eye text-white hover:text-purple-700"></i>
                            </Popover>
                          </div>
                        </div>
                        <input
                          className="bg-[#616161] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                          type="text"
                          placeholder="Customer"
                          {...register("customerName")}
                          disabled
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="px-3 pb-4">
                        <div className="w-full">
                          <label className=" text-white text-sm font-bold mb-2">
                            Organization
                          </label>
                          <Select
                            className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                            showSearch
                            allowClear
                            placeholder="Choose an organization"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={onChangeOrganization}
                            value={idOrganization}
                          >
                            {listAllOrganization?.map((item) => {
                              return (
                                <Option key={item.id} value={item.id}>
                                  {item.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                        <div className="w-full pt-4 mb-6 md:mb-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Department
                          </label>
                          <Select
                            className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                            showSearch
                            allowClear
                            placeholder="Choose a department"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            value={
                              idDepartment ? idDepartment : "Choose a department"
                            }
                            onChange={onChangeDepartment}
                          >
                            {listDepartmentByOrganization?.map((item) => {
                              return (
                                <Option key={item.id} value={item.id}>
                                  {item.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                        <div className="w-full pt-4 mb-6 md:mb-0 ">
                          <div className="flex">
                            <label className="text-white text-sm font-bold mb-2">
                              Customer
                            </label>
                            <div
                              className={`${
                                infoCustomer === null ? "hidden" : ""
                              } ml-auto cursor-pointer`}
                            >
                              <Popover content={content} title="Information">
                                <i className="far fa-eye text-white hover:text-purple-700"></i>
                              </Popover>
                            </div>
                          </div>
                          <Select
                            className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                            showSearch
                            allowClear
                            placeholder="Choose a customer"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={onChangeCustomer}
                            value={idCustomer}
                          >
                            {listCustomer?.map((item) => {
                              return (
                                <Option key={item.id} value={item.id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/*****/}
              <div className="md:ml-2 w-full md:w-2/3 grid grid-cols-2 md:gap-6 border-[1px] border-gray-700 rounded-lg">
                <div className="col-span-2 pt-4">
                  <h1 className="text-lg text-white ml-3 px-3 font-normal border-l-[6px] border-blue-300">
                    Product management information
                  </h1>
                </div>
                {isHtsc ? (
                  <>
                    <div className="col-span-2">
                      <div className="flex flex-wrap">
                        <div className="w-full px-3 md:w-1/2 md:px-3 mb-6 md:mb-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Product
                          </label>
                          <input
                            className=" bg-[#616161] text-white border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                            type="text"
                            placeholder="Product"
                            {...register("productName")}
                            disabled
                          />
                        </div>
                        <div className="w-full px-3 md:w-1/2 md:px-3 mb-6 md:mb-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Edition
                          </label>
                          <input
                            className=" bg-[#616161] text-white border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                            type="text"
                            placeholder="Edition"
                            {...register("editionName")}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 pl-3 px-3 mb-6 md:mb-0 ">
                          <div className="flex">
                            <label className=" text-white text-sm font-bold mb-2">
                              Version
                            </label>
                            <div
                              className={`${
                                infoVersion === null ? "hidden" : ""
                              } ml-auto cursor-pointer`}
                            >
                              <Popover
                                content={contentVersion}
                                title="Information"
                              >
                                <i className="far fa-eye text-white hover:text-purple-700"></i>
                              </Popover>
                            </div>
                          </div>
                          <input
                            className=" bg-[#616161] text-white border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                            type="text"
                            placeholder="Version"
                            {...register("versionName")}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {infoEdition && infoEdition?.type === false ? (
                      <>
                        <div className="col-span-2">
                          <div className="flex flex-wrap">
                            <div className="w-full md:w-1/2 pl-3  md:px-3 mb-6 md:mb-0 ">
                              <div className="flex">
                                <label className=" text-white text-sm font-bold mb-2">
                                  Key
                                </label>
                                <div className="ml-auto flex">
                                  <CopyToClipboard
                                    text={copyToClipboardLastKey.value}
                                    onCopy={() =>
                                      setCopyToClipboardLastKey({
                                        copied: true,
                                      })
                                    }
                                  >
                                    <Tooltip
                                      placement="bottom"
                                      title="Nhấn để copy"
                                    >
                                      <a className="p-1 text-white rounded-lg bg-gray-700 text-[13px] ">
                                        Copy <i className="fas fa-copy"></i>
                                      </a>
                                    </Tooltip>
                                  </CopyToClipboard>
                                  <a
                                    className="p-1 ml-[5px] text-white rounded-lg bg-blue-600"
                                    download={`licensekey${infoCustomer?.name}.txt`}
                                    href={downloadLastKeyOld}
                                  >
                                    Download{" "}
                                    <i className="fas fa-download"></i>
                                  </a>
                                </div>
                              </div>

                              <input
                                className={`border bg-[#616161] text-white pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                                  errors?.lastKey
                                    ? "focus:ring-red-500 focus:ring-1"
                                    : "focus:ring-purple-500 focus:ring-2"
                                } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                                type="text"
                                disabled
                                placeholder="Enter key"
                                {...register("lastKey")}
                              />
                            </div>
                            <div className="w-full md:w-1/2 pl-3  md:px-3 mb-6 md:mb-0 ">
                              <label className=" text-white text-sm font-bold mb-2">
                                MAC
                              </label>
                              <input
                                className="bg-[#616161] text-white border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                                type="text"
                                placeholder="Organization"
                                {...register("macAddress")}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="flex flex-wrap pr-6">
                            <div className="w-1/2 pl-3 mb-2 md:md-0 ">
                              <label className=" text-white text-sm font-bold mb-2">
                                Thời gian sử dụng (ngày)
                              </label>
                              <input
                                disabled
                                className={`border bg-[#616161] text-white pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                                  errors?.avalableTime
                                    ? "focus:ring-red-500 focus:ring-1"
                                    : "focus:ring-purple-500 focus:ring-2"
                                } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                                type="text"
                                placeholder="Enter time"
                                {...register("avalableTime")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="flex flex-wrap">
                            <div className="w-full md:w-1/2 pl-3  md:px-3 mb-6 md:mb-0">
                              <label className=" text-white text-sm font-bold mb-2">
                                Activation date
                                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                  *
                                </span>
                              </label>
                              <DatePicker
                                allowClear={false}
                                suffixIcon={
                                  <CalendarOutlined className="text-white" />
                                }
                                format="DD/MM/YYYY"
                                placeholder="Activation date"
                                value={startDateVersionOff}
                                onChange={onChangeStartDateOff}
                                style={{
                                  borderRadius: "6px",
                                  width: "100%",
                                  height: "40px",
                                  backgroundColor: "#414141",
                                  color: "#fff",
                                }}
                                className="custom-datepicker hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                              />
                            </div>
                            <div className="w-full md:w-1/2 pl-3  md:px-3 mb-6 md:mb-0 ">
                              <label className=" text-white text-sm font-bold mb-2">
                                Expiration date
                                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                  *
                                </span>
                              </label>
                              <DatePicker
                                allowClear={false}
                                suffixIcon={
                                  <CalendarOutlined className="text-white" />
                                }
                                format="DD/MM/YYYY"
                                placeholder="Expiration date"
                                value={expirationDateVersionOff}
                                onChange={onChangeExpirationDateOff}
                                style={{
                                  borderRadius: "6px",
                                  width: "100%",
                                  height: "40px",
                                  backgroundColor: "#414141",
                                  color: "#fff",
                                }}
                                className="custom-datepicker hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : infoEdition && infoEdition?.type === true ? (
                      <>
                        <div className="col-span-2">
                          <div className="flex flex-wrap">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 ">
                              <label className=" text-white text-sm font-bold mb-2">
                              Account
                              </label>
                              <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                *
                              </span>
                              <input
                                className={`border bg-[#414141] text-white pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                                  errors?.email
                                    ? "focus:ring-red-500 focus:ring-1"
                                    : "focus:ring-purple-500 focus:ring-2"
                                } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                                type="text"
                                placeholder="Account"
                                {...register("email", {
                                  required: true,
                                  pattern: validateEmail,
                                })}
                              />
                            </div>
                            <div className="w-full md:w-1/2 pl-3 px-3 mb-6 md:mb-0 ">
                              <div className="flex">
                                <label className=" text-white text-sm font-bold mb-2">
                                  Password
                                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                    *
                                  </span>
                                </label>
                              </div>
                              <div className="relative">
                                <input
                                  value={password}
                                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 focus:ring-purple-500 focus:ring-2
                             focus:outline-none focus:border-transparent`}
                                  placeholder="Password"
                                  onChange={onChangePassword}
                                />
                                <div className="absolute top-[49%] right-0 transform -translate-y-1/2 cursor-pointer">
                                  <a
                                    onClick={onGeneratePassWord}
                                    className=" text-white border p-[11px] rounded-lg bg-blue-500 text-xs hover:bg-blue-600 active:bg-blue-400"
                                  >
                                    Generate password
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-span-2">
                          <div className="flex flex-wrap ">
                            <div className="w-full md:w-1/2  md:px-3 px-3 mb-6 md:mb-0 ">
                              <label className=" text-white text-sm font-bold mb-2">
                                Activation date
                                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                  *
                                </span>
                              </label>
                              <DatePicker
                                allowClear={false}
                                suffixIcon={
                                  <CalendarOutlined className="text-white" />
                                }
                                placeholder="Activation date"
                                value={startDate}
                                format="DD/MM/YYYY"
                                onChange={onChangeStartDate}
                                style={{
                                  borderRadius: "6px",
                                  width: "100%",
                                  height: "40px",
                                  backgroundColor: "#414141",
                                }}
                                className="custom-datepicker hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                              />
                            </div>
                            <div className="w-full md:w-1/2 pl-3 md:px-3 px-3 mb-6 md:mb-0 ">
                              <label className=" text-white text-sm font-bold mb-2">
                                Expiration date
                                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                  *
                                </span>
                              </label>
                              <DatePicker
                                allowClear={false}
                                suffixIcon={
                                  <CalendarOutlined className="text-white" />
                                }
                                format="DD/MM/YYYY"
                                placeholder="Expiration date"
                                value={expirationDate}
                                onChange={onChangeExpirationDate}
                                style={{
                                  borderRadius: "6px",
                                  width: "100%",
                                  height: "40px",
                                  backgroundColor: "#414141",
                                }}
                                className="custom-datepicker hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="col-span-2 md:col-span-1">
                      <div className="flex flex-wrap">
                        <div className="w-full pl-3 px-3 mb-6 md:md-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Category
                            <span className="text-red-600 font-extrabold text-[17px] ml-1">
                              *
                            </span>
                          </label>
                          <select
                            {...register("licenseKeyCategoryId", {
                              required: true,
                            })}
                            className="bg-[#414141] border h-[40px] pl-[10px] pr-8 rounded-md w-full hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          >
                            {listAllLicenseKeyCategory &&
                              listAllLicenseKeyCategory.map((item) => {
                                return (
                                  <>
                                    <option value="" disabled selected hidden>
                                      Select a category
                                    </option>
                                    <option key={item.id} value={item.id}>
                                      {item.title}
                                    </option>
                                    ;
                                  </>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <div className="flex flex-wrap">
                        <div className="w-full pl-3 px-3 mb-6 md:md-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Disable upload
                          </label>
                          <div className="w-full h-[40px] focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent">
                              <Switch
                                checked={disableUpload}
                                onChange={(value) => {
                                  setDisableUpload(value)
                                }}
                              />
                            </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-span-2">
                      <div className="flex flex-wrap">
                        <div className="w-full px-3 md:w-1/2 md:px-3 mb-6 md:mb-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Product
                          </label>
                          <Creatable
                            formatCreateLabel={(inputText) =>
                              `Add new "${inputText}"`
                            }
                            isSearchable={true}
                            className="custom-react-select"
                            placeholder="Select product"
                            onChange={onChangeProductNotHtsc}
                            onCreateOption={onCreateOptionProductNotHtsc}
                            options={listProductNotHtsc}
                            value={idProductNotHtsc}
                          />
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Edition
                          </label>
                          <Creatable
                            formatCreateLabel={(inputText) =>
                              `Add new "${inputText}"`
                            }
                            isClearable
                            isDisabled={idProductNotHtsc ? false : true}
                            placeholder={
                              !idProductNotHtsc
                                ? "Select product first"
                                : "Select edition"
                            }
                            isSearchable={true}
                            className="custom-react-select"
                            onChange={onChangeEditionNotHtsc}
                            onCreateOption={onCreateOptionEditionNotHtsc}
                            options={listEditionByProductNotHtsc}
                            value={idEditionNotHtsc}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 pl-3 px-3 mb-6 md:mb-0 ">
                          <div className="flex">
                            <label className=" text-white text-sm font-bold mb-2">
                              Version
                            </label>
                          </div>
                          <Creatable
                            formatCreateLabel={(inputText) =>
                              `Add new "${inputText}"`
                            }
                            isClearable
                            isDisabled={idEditionNotHtsc ? false : true}
                            placeholder={
                              !idEditionNotHtsc
                                ? "Select the editon first"
                                : "Select version"
                            }
                            isSearchable={true}
                            className="custom-react-select"
                            onChange={onChangeVersionNotHtsc}
                            onCreateOption={onCreateOptionVersionNotHtsc}
                            options={listVersionByEditionNotHtsc}
                            value={idVersionNotHtsc}
                          />
                        </div>
                        <div className="w-full md:w-1/2 pl-3  md:px-3 mb-6 md:mb-0 ">
                          <div className="flex">
                            <label className=" text-white text-sm font-bold mb-2">
                              Key
                            </label>
                            <div className="ml-auto flex">
                              <CopyToClipboard
                                text={copyToClipboardLastKey.value}
                                onCopy={() =>
                                  setCopyToClipboardLastKey({
                                    copied: true,
                                  })
                                }
                              >
                                <Tooltip
                                  placement="bottom"
                                  title="Nhấn để copy"
                                >
                                  <a className="p-1 text-white rounded-lg bg-gray-700 text-[13px] ">
                                    Copy <i className="fas fa-copy"></i>
                                  </a>
                                </Tooltip>
                              </CopyToClipboard>
                              <a
                                className="p-1 ml-[5px] text-white rounded-lg bg-blue-600"
                                download={`licensekey${infoCustomer?.name}.txt`}
                                href={downloadLastKeyOld}
                              >
                                Download <i className="fas fa-download"></i>
                              </a>
                            </div>
                          </div>

                          <input
                            className={`border bg-[#414141] text-white pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                              errors?.lastKey
                                ? "focus:ring-red-500 focus:ring-1"
                                : "focus:ring-purple-500 focus:ring-2"
                            } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                            type="text"
                            placeholder="Enter key"
                            {...register("lastKey")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-2">
                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Account
                          </label>
                          <input
                            className={`border bg-[#414141] text-white pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                              errors?.email
                                ? "focus:ring-red-500 focus:ring-1"
                                : "focus:ring-purple-500 focus:ring-2"
                            } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                            type="text"
                            placeholder="Account"
                            {...register("email")}
                          />
                          {/* <div className="mt-1 h-[10px]">
                            <span className="text-red-500">
                              {errors?.email && errors.email.type === "pattern"
                                ? "Tài khoản có định dạng email"
                                : ""}
                            </span>
                          </div> */}
                        </div>
                        <div className="w-full md:w-1/2 pl-3 px-3 mb-6 md:mb-0 ">
                          <div className="flex">
                            <label className=" text-white text-sm font-bold mb-2">
                              Password
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              value={password}
                              className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 focus:ring-purple-500 focus:ring-2
                             focus:outline-none focus:border-transparent`}
                              placeholder="Password"
                              onChange={onChangePassword}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex flex-wrap ">
                        <div className="w-full md:w-1/2  md:px-3 px-3 mb-6 md:mb-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Activation date
                            <span className="text-red-600 font-extrabold text-[17px] ml-1">
                              *
                            </span>
                          </label>
                          <DatePicker
                            allowClear={false}
                            suffixIcon={
                              <CalendarOutlined className="text-white" />
                            }
                            placeholder="Activation date"
                            value={startDateNotHtsc}
                            format="DD/MM/YYYY"
                            onChange={onChangeStartDateNotHtsc}
                            style={{
                              borderRadius: "6px",
                              width: "100%",
                              height: "40px",
                              backgroundColor: "#414141",
                            }}
                            className="custom-datepicker hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          />
                        </div>
                        <div className="w-full md:w-1/2 pl-3 md:px-3 px-3 mb-6 md:mb-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Expiration date
                            <span className="text-red-600 font-extrabold text-[17px] ml-1">
                              *
                            </span>
                          </label>
                          <DatePicker
                            allowClear={false}
                            suffixIcon={
                              <CalendarOutlined className="text-white" />
                            }
                            format="DD/MM/YYYY"
                            placeholder="Expiration date"
                            value={expirationDateNotHtsc}
                            onChange={onChangeExpirationDateNotHtsc}
                            style={{
                              borderRadius: "6px",
                              width: "100%",
                              height: "40px",
                              backgroundColor: "#414141",
                            }}
                            className="custom-datepicker hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <div className="flex flex-wrap">
                        <div className="w-full pl-3 px-3 md:md-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Category 2
                            <span className="text-red-600 font-extrabold text-[17px] ml-1">
                              *
                            </span>
                          </label>
                          <select
                            {...register("licenseKeyCategoryId", {
                              required: true,
                            })}
                            className="bg-[#414141] border h-[40px] pl-[10px] pr-8 rounded-md w-full hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          >
                            {listAllLicenseKeyCategory &&
                              listAllLicenseKeyCategory.map((item) => {
                                return (
                                  <>
                                    <option value="" disabled selected hidden>
                                      Select a category
                                    </option>
                                    <option key={item.id} value={item.id}>
                                      {item.title}
                                    </option>
                                    ;
                                  </>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 mt-2">
                      <div className="w-full md:w-1/2 px-3">
                        <Upload {...uploadFile}>
                          <Button>
                            <UploadOutlined /> Upload attachment
                          </Button>
                        </Upload>
                      </div>
                      <div className="w-full md:w-1/2 px-3 mb-2">
                        <div className="flex flex-wrap ">
                          {defaultUrlFiles &&
                            defaultUrlFiles.map((item, index) => {
                              return (
                                <div className="w-full border mt-2" key={index}>
                                  <div className="flex items-center py-[10px] mb-1 mx-3 mt-1">
                                    {item?.name?.split(".")[
                                      item?.name?.split(".")?.length - 1
                                    ] === "jpg" ||
                                    item?.name?.split(".")[
                                      item?.name?.split(".")?.length - 1
                                    ] === "png" ? (
                                      <Image
                                        preview={false}
                                        style={{
                                          height: 40,
                                          width: 40,
                                          objectFit: "cover",
                                        }}
                                        src={`${API_URL}/${item?.url}`}
                                      />
                                    ) : (
                                      <FileTwoTone className="text-2xl" />
                                    )}
                                    <div className="ml-3">
                                      <span className="text-white">
                                        {item?.name?.length >= 40
                                          ? item?.name?.substring(0, 40) + "..."
                                          : item?.name}
                                      </span>
                                    </div>
                                    <div className="ml-auto">
                                      <Tooltip title="Download">
                                        <CloudDownloadOutlined
                                          onClick={() => saveFile(item)}
                                          className="text-white hover:scale-110 mx-2 mb-2"
                                        />
                                      </Tooltip>
                                      <Tooltip title="Delete">
                                        <DeleteOutlined
                                          className="text-white hover:scale-110"
                                          onClick={() =>
                                            setDefaultUrlFiles(
                                              defaultUrlFiles.filter(
                                                (e) => e?.name !== item?.name
                                              )
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="mx-[25px]">
              <div className="flex flex-wrap">
                <div className="w-full pl-3 px-3 md:px-3 ">
                  <label className=" text-white text-sm font-bold mb-2">
                    Note
                  </label>
                  <textarea
                    {...register("note")}
                    className="bg-[#414141] pl-[10px] pt-[10px] w-full border  rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    rows={5}
                    placeholder="Note"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-3 mb-[30px]">
              <div className="mx-2">
                <ButtonCancel onClick={() => history.goBack()} />
              </div>
              <div className="mx-2">
                <ButtonSubmit titleButton="Confirm" />
              </div>
            </div>
          </form>
        </ConfigProvider>
      </div>
    </>
  );
};

export default LicenseKeyEdit;
