import { configureStore } from "@reduxjs/toolkit";
import AccountSlice from "../pages/Account/AccountSlice";
import AccountDetailSlice from "../pages/Auth/AccountDetail/AccountDetailSlice";
import LoginUserSlice from "../pages/Auth/Login/LoginSlice";

import ChangeState from "./changeState";
import CustomerSlice from "./../pages/Customer/CustomerSlice";
import OrganizationSlice from "../pages/Organization/OrganizationSlice";
import DeparmentSlice from "../pages/Department/DeparmentSlice";
import PrioritySlice from "../pages/Priority/PrioritySlice";
import ProductSlice from "../pages/Product/ProductSlice";
import VersionSlice from "../pages/Version/VersionSlice";
import LicenseKeySlice from "../pages/LicenseKey/LicenseKeySlice";
import LicenseKeyCategorySlice from "../pages/LicenseKeyCategory/LicenseKeyCategorySlice";
import RoleSilce from "../pages/Role/RoleSilce";
import LicenseKeyInChartSlice from "./../pages/LicenseKeyInChart/LicenseKeyInChartSlice";
import LicenseKeyCategoryChartSlice from "../pages/LicenseKeyCategoryChart/LicenseKeyCategoryChartSlice";
import LicenseKeyExpirationDateSlice from "./../pages/LicenseKeyExpirationDate/LicenseKeyExpirationDateSlice";
import LicenseKeySendMailExpirationDateSlice from "../pages/LicenseKeySendMailExpirationDate/LicenseKeySendMailExpirationDateSlice";
import ConfigSendMailSlice from "./../components/MainLayout/ConfigSendMailLicenseKey/ConfigSendMailLicenseKeySlice";
import SendMailIntroSlice from "./../pages/LicenseKeySendMailIntro/LicenseKeySendMailIntroSlice";
import ForgotPasswordSlice from "../pages/Auth/ForgotPassword/ForgotPasswordSlice";
import EditionSlice from "./../pages/Edition/EditionSlice";
import LicenseKeyHistorySendMailExpiresSlice from "../pages/LicenseKeyHistorySendMailExpires/LicenseKeyHistorySendMailExpiresSlice";
import CustomerGroupSlice from "./../pages/GroupCustomer/GroupCustomerSlice";
import MailActiveSlice from "../pages/MailActive/MailActiveSlice";
const store = configureStore({
  reducer: {
    changeState: ChangeState,
    loginUser: LoginUserSlice,
    forgotPassword: ForgotPasswordSlice,
    account: AccountSlice,
    accountDetail: AccountDetailSlice,
    customer: CustomerSlice,
    organization: OrganizationSlice,
    department: DeparmentSlice,
    priority: PrioritySlice,
    product: ProductSlice,
    edition: EditionSlice,
    version: VersionSlice,
    licenseKey: LicenseKeySlice,
    licenseKeyCategory: LicenseKeyCategorySlice,
    role: RoleSilce,
    licensekeyInChart: LicenseKeyInChartSlice,
    licensekeyCategoryChart: LicenseKeyCategoryChartSlice,
    licenseKeyExpirationDate: LicenseKeyExpirationDateSlice,
    licenseKeySendMail: LicenseKeySendMailExpirationDateSlice,
    configSendMail: ConfigSendMailSlice,
    sendMailIntro: SendMailIntroSlice,
    historySendMailExpires: LicenseKeyHistorySendMailExpiresSlice,
    customerGroup: CustomerGroupSlice,
    mailActive: MailActiveSlice,
  },
});
export default store;
