import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getAllRole = createAsyncThunk("LKM/getAllRole", async () => {
  try {
    const response = await Api.get(`/api/role/getall`);
    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
});
export const getRoleByIdAccount = createAsyncThunk(
  "LKM/getAllRole",
  async (idAccount) => {
    try {
      const response = await Api.get(`/api/role/${idAccount}/get`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {}
  }
);
const RoleSlice = createSlice({
  name: "Role",
  initialState: {
    items: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  extraReducers: {
    [getAllRole.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllRole.fulfilled]: (state, action) => {
      const { data } = action?.payload;
      state.items = data;
      state.isLoading = false;
    },
    [getAllRole.rejected]: (state) => {
      state.isLoading = false;
    },
    [getRoleByIdAccount.pending]: (state) => {
      state.isLoading = true;
    },
    [getRoleByIdAccount.fulfilled]: (state, action) => {
      state.items = action?.payload?.data;
      state.isLoading = false;
    },
    [getRoleByIdAccount.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});
export default RoleSlice.reducer;
