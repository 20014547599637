import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, ConfigProvider, Form } from "antd";
import FormModal from "./../../../components/FormModal/FormModal";
import { getAllProduct } from "../../Product/ProductSlice";
import Api from "../../../Utils/Api";
import { InfoCircleOutlined } from "@ant-design/icons";
import { setFilters } from "../LicenseKeyHistorySendMailExpiresSlice";

const { Option } = Select;
const LicenseKeyHistorySendMailExpiresFilter = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedEditionId, setSelectedEditionId] = useState("");
  const [selectedVersionId, setSelectedVersionId] = useState("");
  const [listEditionByProduct, setListEditionByProduct] = useState([]);
  const [listVersionByEdition, setListVersionByEdition] = useState([]);
  const [listAllAccount, setListAllAccount] = useState([]);
  const listAllProduct = useSelector((state) => state?.product?.items);
  const { openModal, handleCancel } = props;

  useEffect(() => {
    dispatch(getAllProduct());
  }, [dispatch]);

  const getAllCustomer = async () => {
    try {
      let response = await Api.get(`/api/account/getall`);
      if (response.status === 200) {
        setListAllAccount([
          {
            id: "auto",
            name: "Email tự động",
          },
          ...response?.data?.data,
        ]);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAllCustomer();
  }, []);

  useEffect(async () => {
    if (selectedProductId) {
      const { data } = await Api.get(
        `/api/edition/product/${selectedProductId}`
      );
      if (data) {
        setListEditionByProduct(data?.data);
      }
    }
  }, [selectedProductId]);
  useEffect(async () => {
    if (selectedEditionId) {
      const { data } = await Api.get(
        `/api/version/edition/${selectedEditionId}`
      );
      if (data) {
        setListVersionByEdition(data?.data);
      }
    }
  }, [selectedEditionId]);

  const onFinish = async (value) => {
    console.log(value);
    if (
      value?.sendByAccountId ||
      selectedProductId ||
      selectedEditionId ||
      selectedVersionId
    ) {
      dispatch(
        setFilters({
          sendByAccountId: value?.sendByAccountId,
          productId: selectedProductId,
          versionId: selectedVersionId,
          editionId: selectedEditionId,
        })
      );
      await handleCancel();
    } else {
      dispatch(
        setFilters({
          sendByAccountId: "",
          productId: "",
          versionId: "",
          editionId: "",
        })
      );
      await handleCancel();
    }
  };

  const onHandleCancel = async () => {
    await handleCancel();
  };
  const onHandleReset = () => {
    form.resetFields();
    setSelectedProductId("");
    setSelectedEditionId("");
    setSelectedVersionId("");
  };

  const onChangeSendByAccount = (e) => {};
  const onChangeProduct = (e) => {
    setSelectedProductId(e);
    setSelectedEditionId("");
    setSelectedVersionId("");
    form.setFieldsValue({
      editionId: "Select product first",
      versionId: "Select the editon first",
    });
    setListEditionByProduct([]);
  };
  const onChangeEditon = (e) => {
    setSelectedEditionId(e);
    setSelectedVersionId("");
    form.setFieldsValue({
      versionId: "Select the editon first",
    });
    setListVersionByEdition([]);
  };
  const onChangeVersion = (e) => {
    setSelectedVersionId(e);
  };
  return (
    <div>
      <FormModal
        titleModal={"Filter the data"}
        titleForm={"licenseKeyHistorySendMailExpiresFilter"}
        visible={openModal}
        okText="Apply"
        handleCancel={onHandleCancel}
        handleReset={onHandleReset}
        width={"90%"}
        className="modal-800"
      >
        <ConfigProvider
          renderEmpty={() => (
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <InfoCircleOutlined style={{ fontSize: 30, color: "white" }} />
              <p className="text-white mt-1">Trống</p>
            </div>
          )}
        >
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            id={`licenseKeyHistorySendMailExpiresFilter`}
          >
            <div className="flex flex-wrap">
              <div className="w-1/2 px-3 ">
                <Form.Item
                  name="sendByAccountId"
                  className="w-full"
                  label="Người gửi"
                >
                  <Select
                    className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    showSearch
                    allowClear
                    placeholder="Choose sender"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={onChangeSendByAccount}
                  >
                    {listAllAccount &&
                      listAllAccount.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
              <div className="w-1/2 px-3 ">
                <Form.Item name="productId" className="w-full" label="Product">
                  <Select
                    className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    showSearch
                    allowClear
                    placeholder="Select product"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={onChangeProduct}
                  >
                    {listAllProduct &&
                      listAllProduct.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.title}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="flex flex-wrap mb-3">
              <div className="w-1/2 px-3 ">
                <Form.Item name="editionId" className="w-full" label="Edition">
                  <Select
                    className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    showSearch
                    allowClear
                    placeholder={
                      selectedProductId ? "Select edition" : "Select product first"
                    }
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={selectedProductId ? false : true}
                    onChange={onChangeEditon}
                  >
                    {listEditionByProduct &&
                      listEditionByProduct.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.title}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
              <div className="w-1/2 px-3 ">
                <Form.Item name="versionId" className="w-full" label="Version">
                  <Select
                    className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    showSearch
                    allowClear
                    placeholder={
                      selectedEditionId ? "Select version" : "Select edition first"
                    }
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={
                      selectedProductId && selectedEditionId ? false : true
                    }
                    onChange={onChangeVersion}
                  >
                    {listVersionByEdition &&
                      listVersionByEdition.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.title}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Form>
        </ConfigProvider>
      </FormModal>
    </div>
  );
};

export default LicenseKeyHistorySendMailExpiresFilter;
