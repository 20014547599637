import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getLicenseKeyExpirationDate = createAsyncThunk(
  "LKM/getLicenseKeyExpirationDate",
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get(`api/licensekey/getlessthan14day`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const deleteLicenseKeyExpirationDate = createAsyncThunk(
  "LKM/deleteLicenseKeyExpirationDate",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/licensekey/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const LicenseKeyExpirationDateSlice = createSlice({
  name: "LicenseKeyExpirationDateSlice",
  initialState: {
    items: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  extraReducers: {
    [getLicenseKeyExpirationDate.pending]: (state) => {
      state.isLoading = true;
    },
    [getLicenseKeyExpirationDate.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.isLoading = false;
      state.items = data;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getLicenseKeyExpirationDate.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteLicenseKeyExpirationDate.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteLicenseKeyExpirationDate.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deleteLicenseKeyExpirationDate.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default LicenseKeyExpirationDateSlice.reducer;
